import React, { useState, useEffect, useContext } from "react";
import "./SuperAuction.css";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { motion } from "framer-motion";
import Superside from "../superadmin/Superside";
import axios from "axios";
import Select from "react-select";
import { SuperadminContext } from "../context/superadmincontext";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Swal from 'sweetalert2';  // Import SweetAlert2
import userimage from "./images/userimage.png"
 
 
const initialState = {
  group_id: "",
  group_name: "",
  chit_amount: "",
  commission_k: "",
  auctionDate: "",
  amountdueDate: "",
  InstallmentNumber: "",
  nextauctionDate: "",
  bidAmount: "",
  bidderName: "",
  noofusers: "",
};
 
const SupAdmAuction = () => {
  const usernamedashboard = localStorage.getItem("username");
 
  const [state, setState] = useState(initialState);
  const {
    group_id,
    group_name,
    chit_amount,
    commission_k,
    auctionDate,
    amountdueDate,
    InstallmentNumber,
    nextauctionDate,
    bidAmount,
    bidderName,
    noofusers,
  } = state;
 
  const [data, setData] = useState([]);
  const [user, setuser] = useState({});
  const { superadminname } = useContext(SuperadminContext);
 
  const { groupId, groupName, chitAmount, commission, noOfUsers } = useParams();
  console.log(groupId);
 
  const navigate = useNavigate();
 
  const { auctionId } = useParams();
 
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");
 
  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;
 
    if (token) {
      if (location.pathname !== "/update/superadminautiondata/:auctionId") {
        navigate("/update/superadminautiondata/:auctionId");
      }
      return;
    }
 
    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/superadmin") {
        navigate("/");
      }
      return;
    }
 
    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);
 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);
 
  useEffect(() => {
    axios
      .get(`https://api.chitgenius.com/api/getauctionid/${auctionId}`)
      .then((resp) =>
        setState((prevState) => ({ ...prevState, ...resp.data[0] }))
      );
  }, [auctionId]);
 
  const loadData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/api/getall/biddername"
    );
    setData(response.data);
  };
 
  useEffect(() => {
    setState({
      ...state,
      group_id: groupId,
      group_name: groupName,
      chit_amount: chitAmount,
      commission_k: commission,
      noofusers: noOfUsers,
    });
    loadData();
  }, [groupId, groupName, chitAmount, commission, noOfUsers]);
 
 
 
 
  const isValidDate = (date) => {
    const today = new Date();
    const inputDate = new Date(date);
    return (
      inputDate.getFullYear() > today.getFullYear() ||
      (inputDate.getFullYear() === today.getFullYear() && inputDate.getMonth() >= today.getMonth())
    );
  };
 
 
  const isNextAuctionDateValid = (nextDate, auctionDate) => {
    const next = new Date(nextDate);
    const auction = new Date(auctionDate);
 
    return (
      next.getFullYear() > auction.getFullYear() ||
      (next.getFullYear() === auction.getFullYear() && next.getMonth() > auction.getMonth())
    );
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!auctionDate || !amountdueDate || !InstallmentNumber || !nextauctionDate || !bidAmount) {
      Swal.fire({
        title: 'Error',
        text: 'Please fill all the fields',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
 
    if (!isValidDate(auctionDate)) {
      Swal.fire({
        title: 'Error',
        text: '"Auction date" should be the current month or an upcoming month.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
 
    if (!isValidDate(amountdueDate)) {
      Swal.fire({
        title: 'Error',
        text: '"Amount due date" should be the current month or an upcoming month.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
 
    if (!isNextAuctionDateValid(nextauctionDate, auctionDate)) {
      Swal.fire({
        title: 'Error',
        text: '"Next auction date" should be in the month after the auction date or in an upcoming month.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
 
 
    if (!auctionId) {
      axios
        .post("https://api.chitgenius.com/api/add/auction/post", {
          group_id,
          group_name,
          chit_amount,
          commission_k,
          auctionDate,
          amountdueDate,
          InstallmentNumber,
          nextauctionDate,
          bidAmount,
          bidderName:iduser,
          noofusers,
        })
        .then(() => {
          setState({
            group_id: "",
            group_name: "",
            chit_amount: "",
            commission_k: "",
            auctionDate: "",
            amountdueDate: "",
            InstallmentNumber: "",
            nextauctionDate: "",
            bidAmount: "",
            bidderName: "",
            noofusers: "",
          });
          Swal.fire({
            title: 'Success',
            text: '"Auction data" saved successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          });
        })
        .catch((err) => Swal.fire({
          title: 'Error',
          text: err.response.data,
          icon: 'error',
          confirmButtonText: 'OK',
        }));
        // .catch((err) => alert.error(err.response.data));
 
    } else {
      axios
        .put(`https://api.chitgenius.com/update/auctiondata/api/${auctionId}`, {
          group_id,
          group_name,
          chit_amount,
          commission_k,
          auctionDate,
          amountdueDate,
          InstallmentNumber,
          nextauctionDate,
          bidAmount,
          bidderName:iduser,
          noofusers,
        })
        .then(() => {
          setState({
            group_id: "",
            group_name: "",
            chit_amount: "",
            commission_k: "",
            auctionDate: "",
            amountdueDate: "",
            InstallmentNumber: "",
            nextauctionDate: "",
            bidAmount: "",
            bidderName: "",
            noofusers: "",
          });
          Swal.fire({
            title: 'Success',
            text: '"Auction data" updated successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          });
        })
        .catch((err) => Swal.fire({
          title: 'Error',
          text: err.response.data,
          icon: 'error',
          confirmButtonText: 'OK',
        }));
        navigate("/superadmin/auction/details");
 
        // .catch((err) => alert.error(err.response.data));
    }
 
    
  };
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
 
  const iduser = user.UserId;

  return (
    <div  className="super-auction-main" style={{ display: "flex" }}>
      <div>
        <Superside />
      </div>
      <div className="super-auction-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
        >
          <div className="super-auction-head">
            <div className="Ad-h">
              <div className="">
                <NavLink className="" to={"/superadmin/auction/details"}>
                  <FaArrowLeftLong className="Ad-ume" />
                </NavLink>
              </div>
              <div className="super-auction-heading">
                <h2>Auction</h2>
              </div>
            </div>
            <div className="image">
              <div>
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
              </div>
            </div>
          </div>
 
          <hr style={{ margin: "10px 0px" }}></hr>
          <div className="super-auction-info-container">
            <form onSubmit={handleSubmit}>
              
              <div className="super-auction-display-full-3">
                <div className="super-auction-getdata super-auction-mse">
                  <div className="super-auction-tr-1 super-auction-mse">
                    <div className="super-auction-display-33 super-auction-mse">
                      <div>
                        <label className="super-auction-mag">GroupId</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="group_id"
                          class="super-auction-input-33 super-auction-mse"
                          value={group_id || ""}
                          placeholder="Group Id:"
                          onChange={handleInputChange}
                          disabled={auctionId ? true : false} // Disable if editing
                        />
                      </div>
                    </div>
                    <div className="super-auction-display-33 super-auction-mse">
                      <div>
                        <label className="super-auction-mag">No of Users</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="noofusers"
                          class="super-auction-input-33 super-auction-mse"
                          value={noofusers || ""}
                          placeholder="Group Id:"
                          onChange={handleInputChange}
                          disabled={auctionId ? true : false} // Disable if editing
                        />
                      </div>
                    </div>
                    <div className="super-auction-display-33 super-auction-mse">
                      <div>
                        <label className="super-auction-mag">GroupName</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="group_name"
                          class="super-auction-input-33 super-auction-mse"
                          value={group_name || ""}
                          placeholder="Group Name:"
                          onChange={handleInputChange}
                          disabled={auctionId ? true : false} // Disable if editing
                        />
                      </div>
                    </div>
                  </div>
                  <div className="super-auction-trs super-auction-mse">
                    <div className="super-auction-display-33 super-auction-mse">
                      <div>
                        <label className="SuperA-mag">ChitAmount</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="chit_amount"
                          class="super-auction-input-33 super-auction-mse"
                          value={chit_amount || ""}
                          placeholder="Chit Amount:"
                          onChange={handleInputChange}
                          disabled={auctionId ? true : false} // Disable if editing
                        />
                      </div>
                    </div>
                    <div className="super-auction-display-33 super-auction-mse">
                      <div>
                        <label className="SuperA-mag">Commission</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="commission_k"
                          class="super-auction-input-33 super-auction-mse"
                          value={commission_k || ""}
                          placeholder="Chit Amount:"
                          onChange={handleInputChange}
                          disabled={auctionId ? true : false} // Disable if editing
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="super-auction-tr">
                  <div className="super-auction-display-33">
                    <div>
                      <label className="super-auction-mag">AuctionDate</label>
                    </div>
                    <div>
                      <input
                        type="date"
                        name="auctionDate"
                        class="super-auction-input-33"
                        value={auctionDate || ""}
                        placeholder="Auction Date:"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="super-auction-display-33">
                    <div>
                      <label className="super-auction-mag">AmountDueDate</label>
                    </div>
                    <div>
                      <input
                        type="date"
                        name="amountdueDate"
                        class="super-auction-input-33"
                        value={amountdueDate || ""}
                        placeholder="AmountDueDate:"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="super-auction-display-33">
                    <div>
                      <label className="super-auction-mag">Installment Number</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="installmentNumber"
                        class="super-auction-input-33"
                        value={InstallmentNumber || ""}
                        placeholder="ex:1st"
                        onChange={handleInputChange}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="super-auction-tr">
                  <div className="super-auction-display-33">
                    <div>
                      <label className="super-auction-mag">Next Auction Date</label>
                    </div>
                    <div>
                      <input
                        type="date"
                        name="nextauctionDate"
                        class="super-auction-input-33"
                        value={nextauctionDate || ""}
                        placeholder="Next Auction Date:"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="super-auction-display-33">
                    <div>
                      <label className="super-auction-mag">Bid Amount</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="bidAmount"
                        class="super-auction-input-33"
                        value={bidAmount || ""}
                        placeholder="ex:10,000"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="super-auction-display-33">
                    <div>
                      <label className="super-auction-mag">Bider Name</label>
                    </div>
                    <div>
                    <input
                          name="bidderName"
                          className="super-auction-input-33"
                          value={iduser || ""}
                          onChange={handleInputChange}
                        ></input>
                    </div>
                  </div>
 
                 
                  
                </div>
              </div>
              <div className="super-auction-hus">
                <input
                  type="submit"
                  value={auctionId ? "update" : "save"}
                  className="super-auction-cdf"
                ></input>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
 
export default SupAdmAuction;
 
