import React, { useState, useEffect, useContext } from "react";
import "../pages/memberpassbook.css";
import SideBar from "../components/Sidebar/SideBar";
import axios from "axios";
import Select from "react-select";
import { UserContext } from "../context/usecontext";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import '../pages/transactionbyuseris.css';
import userimage from "./images/userimage.png"
// import Userside from "../usercompo/Userside";

const TransactionByUseris = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [state, setState] = useState("");
  const [duration, setDuration] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState("");
  const [userdata, setUserData] = useState({});
  const [mobileNo, setmobileNo] = useState([]);
  const [groupNameOptions, setGroupNameOptions] = useState([]); // Options for group name dropdown
  const [selectedGroupName, setSelectedGroupName] = useState(""); //
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/user/pc") {
        navigate("/user/pc");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
      return;
    }

    setUsersname(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!selectedGroupName || !duration || !mobileNo) {
      alert("Please fill in all input fields.");
      return;
    }
    try {
      const response = await axios.post(
        "https://api.chitgenius.com/transactions/groupname/user",
        {
          groupName: selectedGroupName,
          duration: duration.trim(),
          mobileNo,
        }
      );
      if (response.data.length === 0) {
        alert("No data available.");
      } else {
        setTransactions(response.data);
      }
      // setTransactions(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      setError("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const gu_id = user.UserId;

 
  const loadUserData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/api/groupuser/chituser/mobileno/${gu_id}`
    );
    setData(response.data);
  };

  useEffect(() => {
    loadUserData();
  }, [gu_id]); //

  useEffect(() => {
    if (mobileNo) {
      fetchUserData();
    }
  }, [mobileNo]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `https://api.chitgenius.com/api/user/memberpassbook/${mobileNo}`
      );
      // setGroupName(response.data.group_Name);

      const options = response.data.map((group) => ({
        label: group,
        value: group,
      }));
      // Update dropdown options with group names
      setGroupNameOptions(options);
    } catch (error) {
      console.error("Error fetching group names:", error);
      setGroupNameOptions([]); // Reset dropdown options if an error occurs
    }
    
  };

  return (
    <div className="Ad-byuser-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
        {/* <Userside /> */}
      </div>
      <div className="Ad-byuser-mm" >
        <div className="Ad-byuser-full">
          <div className="Ad-byuser-head">
            <div className="Ad-byuser-heading">
              <h2>Member passbook</h2>
            </div>
            <div className="image">
              <div>
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
              </div>
            </div>
          </div>

          <hr></hr>
          <div className="Ad-book">
            <div className="Ad-bord">
              {/* <div>
                <Link to="/transhistory">
                  <button className="dos">MontlyByGroupID</button>
                </Link>
                <Link to="/user/pc">
                  <button className="doos">BYUSERID</button>
                </Link>
              </div> */}
              <div>
              <Link to="/transhistory">
                <button className="Ad-dos">MontlyByGroupID</button>
              </Link>
              <Link to="/user/pc">
                <button className="Ad-doos">BYUSERID</button>
              </Link>
            </div>
            </div>

            <form onSubmit={handleSearch}>
              <div
                className=""
                style={{ width: "100vh", marginTop: "12px" }}
              >
                <div className="Ad-byuser-hk">
                  <div className="Ad-byuser-display-44">
                    <label>
                      <strong>MobileNo</strong>
                    </label>
                    <Select
                      value={{ label: mobileNo, value: mobileNo }}
                      onChange={(selectedOption) =>
                        setmobileNo(selectedOption.value)
                      }
                      options={data.map((opts) => ({
                        label: opts.user_mobileNo,
                        value: opts.user_mobileNo,
                      }))}
                      className="Ad-history-vg"
                      isSearchable={true}
                    />
                  </div>

                  <div className="Ad-byuser-display-44">
                    <label>
                      <strong>Group Name</strong>
                    </label>
                    <Select
                      value={{
                        label: selectedGroupName,
                        value: selectedGroupName,
                      }}
                      onChange={(selectedOption) =>
                        setSelectedGroupName(selectedOption.value)
                      }
                      options={groupNameOptions}
                      className="Ad-history-vg"
                      isSearchable={true}
                    />
                    {/* <input
                  type="text"
                  name="groupName"
                  value={groupName}
                  ></input> */}
                    {/* <Select
                    value={{ label: groupName, value: groupName }}
                    onChange={(selectedOption) =>
                      setGroupName(selectedOption.value)
                    }
                    options={data.map((opts) => ({
                      label: opts.groupName,
                      value: opts.groupName,
                    }))}
                    className="input-44"
                    isSearchable={true}
                  /> */}
                  </div>

                  <div className="Ad-byuser-display-44">
                    <label>
                      <strong>Duration</strong>
                    </label>
                    <select
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      className="Ad-history-vg"
                      required
                    >
                      <option value="">Select</option>
                      <option value="3 months">3 months</option>
                      <option value="6 months">6 months</option>
                      <option value="12 months">12 months</option>
                    </select>
                  </div>

                  {/* <div className="display-44">
                <label>
                  <strong>User ID</strong>
                </label>
                <Select
                  value={{ label: userId, value: userId }}
                  onChange={(selectedOption) =>
                    setUserId(selectedOption.value)
                  }
                  options={userdata.map((opts) => ({
                    label: opts.userId,
                    value: opts.userId,
                  }))}
                  className="input-44"
                  isSearchable={true}
                />
              </div> */}

                  {/* <div className="display-008">
                <label>
                  <strong>Select MobileNo</strong>
                </label>
                <Select
                  options={userdata.map((opts) => ({
                    label: opts.mobileNo,
                    value: opts.mobileNo,
                  }))}
                  value={{ label: mobileNo, value: mobileNo }}
                  className="input-008"
                  onChange={(selectedOption) =>
                    setState({ ...state, mobileNo: selectedOption.value })
                  }
                  isSearchable={true}
                />
              </div> */}
                </div>

                <div className="">
                  <button type="submit" className="Ad-byuser-src">
                    Search
                  </button>
                </div>
              </div>
            </form>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="Ad-up-bgfs">
              {transactions.length > 0 ? (
                <div className="Ad-history-table-groupid">
                  <table className="Ad-table-style-Gu">
                    <thead>
                      <tr className="Ad-sasq">
                        <th colSpan="14" style={{ textAlign: "center" }}>
                          Transactions
                        </th>
                      </tr>
                      <tr>
                        <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>
                          No.
                        </th>
                        <th className="tahe" style={{ textAlign: "center" }}>Payment ID</th>
                        <th className="tahe" style={{ textAlign: "center" }}>Group ID</th>
                        <th className="tahe" style={{ textAlign: "center" }}>Group Name</th>
                        <th className="tahe" style={{ textAlign: "center" }}>Month</th>
                        <th className="tahe" style={{ textAlign: "center" }}>
                          Installment Number
                        </th>
                        {/* <th style={{ textAlign: "center" }}>Bid Winner</th> */}
                        <th className="tahe" style={{ textAlign: "center" }}>
                          Total Amount to pay
                        </th>
                        <th className="tahe" style={{ textAlign: "center" }}>Amount Paid</th>
                        <th className="tahe" style={{ textAlign: "center" }}>Payment Mode</th>
                        <th className="tahe" style={{ textAlign: "center" }}>Balance</th>
                        <th className="tahe" style={{ textAlign: "center" }}>UserId</th>
                        {/* <th className="tahe" style={{ textAlign: "center" }}>UserName</th> */}
                        <th className="tahe" style={{ textAlign: "center" }}>MobileNo</th>
                        <th className="tahe" style={{ textAlign: "center" }}>Payment Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{transaction.paymentcollect_Id}</td>
                          <td>{transaction.payment_idgroup}</td>
                          <td>{transaction.payment_namegroup}</td>
                          <td>{transaction.payment_month}</td>
                          <td>{transaction.payment_insNum}</td>
                          {/* <td>{transaction.bidwinner}</td> */}
                          <td>{transaction.payment_tap}</td>
                          <td>{transaction.payment_hap}</td>
                          <td>{transaction.payment_Mode}</td>
                          <td>{transaction.payment_Balance}</td>
                          <td>{transaction.payment_userId}</td>
                          {/* <td>{transaction.payment_username}</td> */}
                          <td>{transaction.payment_mobilenum}</td>
                          <td>{transaction.payment_Date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
              {/* (
               <div>
              <p className="vwq">No Data Available</p>
             
                </div> 
            )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionByUseris;
