import React, { createContext, useState } from "react";

export const SuperadminContext = createContext();

export const SuperadminProvider = ({ children }) => {
  const [superadminname, setSuperadminname] = useState("");

  return (
    <SuperadminContext.Provider value={{ superadminname, setSuperadminname }}>
      {children}
    </SuperadminContext.Provider>
  );
};