

import React, { useState, useEffect, useContext } from "react";
import Userside from "../usercompo/Userside";
import axios from "axios";
import "./userdash.css";
import { CustomerContext } from "../context/customercontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import userimage from "./images/userimage.png"


const Userdash = () => {
  const usernamedashboard = localStorage.getItem("username");
  const { customername } = useContext(CustomerContext);
  const [user, setuser] = useState({});
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/user/dashboard") {
        navigate("/user/dashboard");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/user") {
        navigate("/");
      }
      return;
    }

    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const user_id = user.UserId;
  console.log(user_id);

  const loadUserData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/api/getall/userlist/chit/${user_id}`
    );
    console.log("success");
    setData(response.data);
  };

  useEffect(() => {
    loadUserData();
  }, [user_id]);

  return (
    <div className="User-conatinaer" style={{ display: "flex" }}>
      <div>
        <Userside />
      </div>
      <div className="mm">
        <div className="User-courses-head">
          <div className="dash-heading">
            <h2>Dashboard</h2>
          </div>
          <div className="image" style={{ display: "flex", gap: "10px" ,marginRight:"30px" }}>
            <div>
              <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="">
          {data.length === 0 ? (
            <div className="no-data">
              <p>You are not in any chit</p>
            </div>
          ) : (
            <div className="User-table-auction">
              <table className="User-tables-style">
                <thead>
                  <tr>
                    <th className="tahe"  style={{ textAlign: "center" }}>no</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>group ID</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>group Name</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>Auction Type</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>Start Date</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>No Of Installments</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>End Date</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>No Of Users</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>Commission</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>Auction Date</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>Date Of Creation</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>Chit Amount</th>
                    <th className="tahe"  style={{ textAlign: "center" }}>Chit Status</th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{item.groupId}</td>
                      <td style={{ textAlign: "center" }}>{item.groupName}</td>
                      <td style={{ textAlign: "center" }}>
                        {item.auctionType}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.startDate}</td>
                      <td style={{ textAlign: "center" }}>
                        {item.noOfInstallments}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.endDate}</td>
                      <td style={{ textAlign: "center" }}>{item.noOfUsers}</td>
                      <td style={{ textAlign: "center" }}>{item.commission}</td>
                      <td style={{ textAlign: "center" }}>
                        {item.auctionDate}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.dateOfCreation}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.chitAmount}</td>
                      <td style={{ textAlign: "center" }}>{item.chitstatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Userdash;
