import React, { useState, useEffect, useContext } from "react";
import "./Userpayreport.css";
import axios from "axios";
import { Link } from "react-router-dom";
import SideBar from "../components/Sidebar/SideBar";
import Select from "react-select";
import { UserContext } from "../context/usecontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import userimage from "./images/userimage.png"

const Userpayreport = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [groupName, setGroupName] = useState("");
  const [selectedOption, setSelectedOption] = useState(null); // New state for selected option

  const [transactions, setTransactions] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/userpay/paymentreport") {
        navigate("/userpay/paymentreport");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
      return;
    }

    setUsersname(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!groupName) {
      alert("Please fill in all input fields.");
      return;
    }
    try {
      const response = await axios.post("https://api.chitgenius.com/searchbiding", {
        groupName,
      });
      if (Array.isArray(response.data) && response.data.length > 0) {
        setTransactions(response.data);
      } else {
        alert("No data available.");
      }
      setError(null);
    } catch (error) {
      // console.error(error);
      setError("An error occurred. please try again.");
    }
  };

 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const loginuserid = user.UserId;

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chit/data/get/${loginuserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [loginuserid]);

  return (
    <div className="Ad-userpayment-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-userpayment-full">
        <div className="">
          <div className="Ad-userpayment-head">
            <div className="Ad-userpayment-heading">
              <h2>Payment Collection </h2>
            </div>
            <div className="image">
              <div>
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  // style={{ height: "50px",borderRadius:'30px',width:"50px" }}
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
              </div>
            </div>
          </div>

          <hr />
          <div>
            <form onSubmit={handleSearch}>
              <div className="Ad-display-002">
                <label>
                  <strong> Group Name</strong>
                </label>
                
                <Select
                  value={selectedOption}
                  onChange={(selectedOption) => {
                    setSelectedOption(selectedOption);
                    setGroupName(selectedOption ? selectedOption.value : null); // Update groupName with selected value
                  }}
                  options={data.map((opts, i) => ({
                    value: opts.groupName,
                    label: opts.groupName,
                  }))}
                  isSearchable={true}
                  placeholder="Select Group"
                />
              </div>
              <div>
                <button type="Submit" className="Ad-up-button-10">
                  View Details
                </button>
              </div>
            </form>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="Ad-up-bgfs">
              {transactions.length > 0 ? (
                <div className="Ad-table-userpayreport">
                  <table className="Ad-table-style-Gu">
                    <thead>
                      <tr className="Ad-sasq">
                        <th colSpan="14" style={{ textAlign: "center" }}>
                          Transactions
                        </th>
                      </tr>
                      <tr>
                        <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>
                          No.
                        </th>
                        <th className="tahe" style={{ textAlign: "center" }}>Group ID</th>
                        <th className="tahe" style={{ textAlign: "center" }}>group Name</th>
                        <th className="tahe" style={{ textAlign: "center" }}>chit Amount</th>
                        <th className="tahe" style={{ textAlign: "center" }}>No.of users</th>
                        <th className="tahe" style={{ textAlign: "center" }}>
                          installment Number
                        </th>
                        <th className="tahe" style={{ textAlign: "center" }}>commission</th>
                        <th className="tahe" style={{ textAlign: "center" }}>bid Amount</th>
                        <th className="tahe" style={{ textAlign: "center" }}>bid Winner</th>
                        <th className="tahe" style={{ textAlign: "center" }}>
                          collecting from subcriber
                        </th>
                        <th className="tahe" style={{ textAlign: "center" }}>pay to Winner</th>
                        <th className="tahe" style={{ textAlign: "center" }}>AuctionId</th>
                        <th className="tahe" style={{ textAlign: "center" }}>date</th>
                        <th className="tahe" style={{ textAlign: "center" }}>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{transaction.id_group}</td>
                          <td>{transaction.name_grp}</td>
                          <td>{transaction.amount_chit}</td>
                          <td>{transaction.user_num}</td>
                          <td>{transaction.install_numb}</td>
                          <td>{transaction.comision}</td>
                          <td>{transaction.amount_bid}</td>
                          <td>{transaction.bid_winner}</td>
                          <td>{transaction.payoutsub}</td>
                          <td>{transaction.payinsub}</td>
                          <td>{transaction.id_auction}</td>
                          <td>{transaction.date}</td>
                          <td>
                            <Link to={`/userpayout/${transaction.bidingId}`}>
                              <button
                                className="Ad-up-home-edit"
                              >
                                Payin
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userpayreport;
