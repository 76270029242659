import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import './loginpage.css';
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import AlertMessage from "./AlertMessage"; // Import the AlertMessage component
 
export const Loginpage = ({ handleSignUpMode }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("error");
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();
 
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
 
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent form submission

    if (!username || !password) {
      setAlertSeverity("warning");
      setAlertMessage("Please fill in all fields.");
      setAlertOpen(true);
      return;
    }
 
    setLoading(true);
    try {
      const response = await axios.post("https://api.chitgenius.com/login", {
        username,
        password,
      });
      if (response.data.success) {
        const role = response.data.role;
        setAlertSeverity("success");
        setAlertMessage(`Welcome, ${role}!`);
        setAlertOpen(true);
        localStorage.setItem('username', username);
        setTimeout(() => {
          Cookies.set("token", response.data.token);
          navigate(`/${role.toLowerCase()}`, { state: { username, role } });
        }, 1000);
      } else {
        setAlertSeverity("error");
        setAlertMessage("Invalid credentials");
        setAlertOpen(true);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setAlertSeverity("error");
      setAlertMessage(
        "An error occurred during login. Please try again later."
      );
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };
 
  return (
    <div className="signinheading">
      <h2 className="title">Sign in</h2>
 
      <div className="ss-container">
        <form action="#" className="sign-in-form" onSubmit={handleLogin} >
          <div className="ss-card">
            <div className="login__field">
              <i class="login__icon fa FaUser"><FaUser/></i>
              <input
                type="text"
                placeholder="Mobile Number"
                className="login_input"
                required="required"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {/* <span className="ss-user"><strong>Mobile Number</strong></span> */}
            </div>
 
            <div className="login__field">
              <i class="login__icon fa FaLock"><FaLock/></i>
              <input
                type="password"
                placeholder="Password"
                className="login_input"
                required="required"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <span>Password</span> */}
            </div>
 
            <button
            type="submit"
              className="button login__submit"
              // className="btn solid"
              >
              {loading ? "Logging in..." : "login"}
              <i class="button__icon fas fa-chevron-right"><FaChevronRight/></i>
            </button>
          </div>
          <p className="social-text">If you don't have account ? <button className="btn-signup" style={{ background:"none" }} onClick={handleSignUpMode}>
            Sign up
          </button></p>
          <div>{/* Social media icons */}
          <Link to="/for" style={{textDecoration:'none'}}>
            <p className="forget-pass">Forget Password?</p>
            </Link>
          </div>
          <div>
            <p>Go to <Link to="/">Homepage</Link></p>
          </div>
 
         
 
          <AlertMessage
            open={alertOpen}
            severity={alertSeverity}
            message={alertMessage}
            handleClose={handleAlertClose}
          />
        </form>
      </div>
    </div>
  );
};
 