import React, { useEffect,useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useState } from 'react';
import Dashboard from './Dashboard';
import axios from 'axios';
import { UserContext } from "../context/usecontext";



export const Admin = () => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  // const [username, setUsername] = useState("");
  const [user,setuser]=useState({});
  //  const { username } = useContext(UserContext); 
  const {username ,setUsername } = useContext(UserContext);



  useEffect(() => {
    const token = Cookies.get('token');
    const { state } = location;

    if (!token || !state || !state.username || !state.role) {
      navigate('/');
    } else {

      setUsername(state.username);
      // setRole(state.role);
      console.log(state.username, state.role); // Log username and role
    }
  }, [location, navigate]);
  console.log("Username in Admin:", username); // Log username to check if it's set correctly


  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
  };

  useEffect(()=>{
    const fetchData= async ()=>{
        try{
            const response= await axios.get(`https://api.chitgenius.com/api/getmemberdetails/afterlogin/${username}`);
            setuser({ ...response.data[0]});
        }catch(error){
            console.error("Error fetching assessment data",error);
        }
    };
    fetchData();
}, [username]);



  return (
    <div>
      

      <div> 
      {/* <h1>Welcome, {username}</h1> */}
      {/* {user.mobileNo}
           {user.UserName} */}
    <Dashboard/>
      </div>

    </div>

  );
};
