import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { SuperadminContext } from "../context/superadmincontext";
import SuperDash from '../superpages/SuperDash';


export const Superadmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const {superadminname ,setSuperadminname } = useContext(SuperadminContext);



  useEffect(() => {
    const token = Cookies.get('token');
    const { state } = location;

    if (!token || !state || !state.username || !state.role) {
      navigate('/');
    } else {
      setSuperadminname(state.username);
      setRole(state.role);
      console.log(state.username, state.role); // Log username and role
    }
  }, [location, navigate]);

  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
  };

  return (
    <div>
      {/* <h2>Welcome to the Parent Page!</h2> */}
      {/* <p>Username: {superadminname}</p> */}
      {/* <p>Role: {role}</p> */}
      {/* <button onClick={handleLogout}>Logout</button> */}
      <SuperDash/>
    </div>
  );
};
