import React, { useState, useEffect, useContext } from "react";
import "./biding.css";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { motion } from "framer-motion";
import SideBar from "../components/Sidebar/SideBar";
import Select from "react-select";
import { UserContext } from "../context/usecontext";
import Swal from 'sweetalert2';  // Import SweetAlert2
import userimage from "./images/userimage.png"
 
 
const initialState = {
  id_group: "",
  amount_chit: "",
  user_num: "",
  install_numb: "",
  comision: "",
  amount_bid: "",
  bid_winner: "",
  payoutsub: "",
  payinsub: "",
  id_auction: "",
  name_grp: "",
  winner_id: "",
  mis_1: "",
  mis_2: "",
};
 
const Biding = () => {
  const usernamedashboard = localStorage.getItem("username");
 
  const [state, setState] = useState(initialState);
  const [userData, setUserData] = useState({});
  const { username } = useContext(UserContext);
  const [loguser, setLoguser] = useState({});
 
  // const [winner_idOptions, setWinner_idOptions] = useState([]); // Options for group name dropdown
  // const [selectedwinner_id, setSelectedwinner_id] = useState(""); //
 
  // const [winner_idOptions, setWinner_idOptions] = useState([]);
  // const [selectedwinner_id, setSelectedwinner_id] = useState("");
 
  const [winner_idOptions, setWinner_idOptions] = useState([]);
  const [selectedWinnerId, setSelectedWinnerId] = useState("");
 
  const {
    id_group,
    amount_chit,
    user_num,
    install_numb,
    comision,
    amount_bid,
    bid_winner,
    payoutsub,
    payinsub,
    id_auction,
    name_grp,
    mis_1,
    mis_2,
  } = state;
 
  const [user, setUser] = useState({});
  const { auctionId } = useParams();
  const [collectingFromEachSubscriber, setCollectingFromEachSubscriber] =
    useState(0);
  const [payToSubscriber, setPayToSubscriber] = useState(0); // New state
 
  const [data, setData] = useState([]);
 
  const [alldata, setAllData] = useState([]);
 
  const { group_id } = useParams();
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/data/new/bid/get/${group_id}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
    fetchData();
  }, [group_id]);
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
 
  const loadData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/api/getall/usernames"
    );
    setAllData(response.data);
  };
  useEffect(() => {
    loadData();
  }, []);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getauctionid/${auctionId}`
        );
        setUser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
 
    fetchData();
  }, [auctionId]);
 
  useEffect(() => {
    if (
      user.chit_amount &&
      user.commission_k &&
      user.bidAmount &&
      user.noofusers
    ) {
      const chitAmount = parseFloat(user.chit_amount.replace(/,/g, ""));
      const commissionPercentage = parseFloat(user.commission_k);
      const commission = (chitAmount * commissionPercentage) / 100;
      const bidAmount = parseFloat(user.bidAmount.replace(/,/g, ""));
      const answer = chitAmount - bidAmount;
      const answer1 = answer - commission;
      const usersNum = parseFloat(user.noofusers);
      const result = answer / usersNum;
      setCollectingFromEachSubscriber(result);
      setPayToSubscriber(answer1);
    }
  }, [user]);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state.bid_winner || !selectedWinnerId) {
      Swal.fire({
        title: 'Error',
        text: 'Please fill all the fields',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
    try {
      await axios.post("https://api.chitgenius.com/api/add/biding/post", {
        id_group: gid,
        amount_chit: camount,
        user_num: nou,
        install_numb: isn,
        comision: cmn,
        amount_bid: ba,
        bid_winner: state.bid_winner, // You need to include the selected bid winner from your state
        payoutsub: collectingFromEachSubscriber,
        payinsub: payToSubscriber,
        id_auction: idauction,
        name_grp: gname,
        winner_id: selectedWinnerId,
        mis_1: state.mis_1,
        mis_2: state.mis_2,
      });
      setState(initialState); // Clear input fields after successful submission
      console.log("Biding data added successfully");
      alert("Biding data added successfully");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.sqlMessage
      ) {
        if (error.response.data.sqlMessage.includes("Duplicate entry")) {
          alert("Duplicate entry detected. Please check your input.");
        } else {
          alert(error.response.data.sqlMessage);
        }
      } else {
        alert("biding already done for this auctionid");
      }
    }
  };
  const idauction = user.auctionId;
  console.log(idauction);
  const camount = user.chit_amount;
  console.log(camount);
  const gid = user.group_id;
  const nou = user.noofusers;
  const isn = user.InstallmentNumber;
  const cmn = user.commission_k;
  const ba = user.bidAmount;
  const gname = user.group_name;
 
  
 
  useEffect(() => {
    console.log(gid);
    const groupvalue = gid;
    console.log("groupvalue", groupvalue);
    console.log("hlo");
    if (state.bid_winner) {
      fetchWinnerIdOptions(state.bid_winner, groupvalue);
    }
  }, [state.bid_winner]);
 
  const fetchWinnerIdOptions = async (bid_winner, groupvalue) => {
    try {
      console.log("insidetry");
 
      // const id_group="YTYT313301";
 
      const response = await axios.get(
        `https://api.chitgenius.com/api/get/bidnew/notold/auto/${bid_winner}/${groupvalue}`
      );
      console.log("outsidetry");
      const options = response.data.map((groupId) => ({
        label: groupId,
        value: groupId,
      }));
      setWinner_idOptions(options);
    } catch (error) {
      console.error("Error fetching winner ID data:", error);
      setWinner_idOptions([]);
    }
  };
 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setLoguser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);
 
  return (
    <div className="Admin-biding-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-biding-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
        >
          <div className="Ad-biding-head">
            <div className="Ad-h">
              <NavLink
                style={{ color: "black" }}
                to={"/chitgroup"}
              >
                <FaArrowLeftLong className="Ad-ume"/>
              </NavLink>
              <div className="Ad-biding-heading">
              <h2>Biding</h2>
              </div>
             
            </div>
            <div className="image">
              <div>
                <img
                // src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  src={`https://api.chitgenius.com/uploads/${loguser.uploadPhoto}`}
                  alt=""
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{loguser.UserName}</p>
                <p style={{ margin: "0px" }}>({loguser.role})</p>
              </div>
            </div>
          </div>
          <hr style={{ margin: "10px 0px" }}></hr>
          <form
            className="Ad-info-container"
            // style={{ width: "75%", marginTop: "25px" }}
            onSubmit={handleSubmit}
          >
            <div className="Ad-biding-display-full-44">
              <div className="Ad-biding-hk">
                <div className="Ad-biding-display-44">
                  <label>
                    <strong>Group Id</strong>
                  </label>
                  <input
                    type="text"
                    name="id_group"
                    class="Ad-biding-input-55"
                    value={gid}
                    placeholder="Select Group:"
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="Ad-biding-display-44">
                  <label>
                    <strong>Group Name</strong>
                  </label>
                  <input
                    type="text"
                    name="name_grp"
                    class="Ad-biding-input-55"
                    value={gname}
                    placeholder="Select Group:"
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="Ad-biding-display-44 ">
                  <label>
                    <strong>Auction Id</strong>
                  </label>
                  <input
                    type="text"
                    name="id_auction"
                    class="Ad-biding-input-55"
                    value={idauction}
                    placeholder="Select Group:"
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="Ad-biding-display-44">
                  <label>
                    <strong>NoOf Users</strong>
                  </label>
                  <input
                    type="text"
                    name="user_num"
                    class="Ad-biding-input-55"
                    value={nou}
                    placeholder="Select Group:"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
 
              <div className="Ad-biding-hk">
                <div className="Ad-biding-display-44">
                  <label>
                    <strong>InstallmentNumber</strong>
                  </label>
 
                  <input
                    type="text"
                    name="install_numb"
                    class="Ad-biding-input-55"
                    value={isn}
                    placeholder="Installement Number:"
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="Ad-biding-display-44">
                  <label>
                    <strong>Winner MobileNo</strong>
                  </label>
                  <Select
                    options={data.map((opts) => ({
                      label: opts.user_mobileNo,
                      value: opts.user_mobileNo,
                    }))}
                    className="Ad-biding-input-55"
                    value={{ label: bid_winner, value: bid_winner }}
                    onChange={(selectedOption) =>
                      setState({ ...state, bid_winner: selectedOption.value })
                    }
                    isSearchable={true}
                  />
                </div>
 
                <div className="Ad-biding-display-44">
                  <label>
                    {" "}
                    <strong>WinnerId</strong>
                  </label>
                 
                  <Select
                    value={{ label: selectedWinnerId, value: selectedWinnerId }}
                    onChange={(selectedOption) =>
                      setSelectedWinnerId(selectedOption.value)
                    }
                    options={winner_idOptions}
                    className="Ad-biding-input-55"
                    isSearchable={true}
                  />
                 
                </div>
 
                <div className="Ad-biding-class" >
                  <div className="Ad-biding-cal-p">
                    <div className="Ad-biding-display-44">
                      <label>
                        <strong>Chit Amount</strong>
                      </label>
 
                      <input
                        type="text"
                        name="amount_chit"
                        class="Ad-biding-input-55"
                        value={camount}
                        placeholder="Chit Amount:"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="Ad-biding-mis-cd">
                      <label className="Ad-biding-mis-label">
                        <strong>Commission </strong>
                      </label>
 
                      <input
                        type="text"
                        name="comision"
                        class="Ad-biding-input-440"
                        value={cmn}
                        placeholder="Commission:"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="Ad-biding-mis-cd-1">
                      <label className="Ad-biding-mis-label">
                        <strong>Bid Amount </strong>
                      </label>
 
                      <input
                        type="text"
                        name="amount_bid"
                        class="Ad-biding-input-440"
                        value={ba}
                        placeholder="Bid Amount:"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="Ad-biding-mis-cd">
                      <label className="Ad-biding-mis-label">
                        <strong>Miscellous </strong>
                      </label>
                      <input
                        type="text"
                        name="mis_1"
                        class="Ad-biding-input-444"
                        value={0}
                      ></input>
                    </div>
                    <div className="Ad-biding-mis-cd">
                      <label className="Ad-biding-mis-label">
                        <strong>Miscellous </strong>
                      </label>
                      <input
                        type="text"
                        name="mis_2"
                        class="Ad-biding-input-444"
                        value={0}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
 
              <div className="Ad-biding-hk">
                <div className="Ad-biding-display-44">
                  <label className="Ad-biding-rg">
                    <strong>Pay to winner</strong>
                  </label>
                  <input
                    type="text"
                    name="payinsub"
                    className="Ad-biding-input-55"
                    value={payToSubscriber}
                    placeholder="Pay to Subscriber"
                    onChange={handleInputChange}
                    readOnly // Make it read-only to prevent user input
                  />
                </div>
 
                <div className="Ad-biding-display-440">
                  <label>
                    <strong>Collecting form each subcriber</strong>
                  </label>
                  <input
                    type="text"
                    name="payoutsub"
                    class="Ad-biding-input-55"
                    value={collectingFromEachSubscriber}
                    placeholder="Collecting from each subcriber:"
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="Ad-biding-hum">
                  <button className="Ad-biding-cdf ew">Save</button>
                </div>
              </div>
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
};
 
export default Biding;