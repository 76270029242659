import React, { useState } from "react";
import "./paymentreport.css";
import SideBar from "../components/Sidebar/SideBar";
import axios from "axios";


const Paymentreport = () => {
  const [duration, setDuration] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://api.chitgenius.com/transactions", {
        duration: duration.trim(),
      });
      setTransactions(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="info-23">
        <div>
          <h3>Payment Report</h3>
        </div>
        <hr></hr>
        <div className="fixed-duration">
          <form onSubmit={handleSearch}>
            <div className="vgt-durat">
              <label><strong>Duration</strong></label>
              <select
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                className="i-2"
                required
              >
                <option value="">Select duration</option>
                <option value="3 months">3 months</option>
                <option value="6 months">6 months</option>
                <option value="12 months">12 months</option>
              </select>
            <div className="cft">
              <button type="submit" className="cdf">Search</button>
            </div>
            </div>

          </form>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="bgfs">
            {/* <h3 className="bcx">Transactions</h3> */}
            <div className={transactions.length === 0 ? "cfsxs" : "cfsx"}>

            {transactions.length === 0 ? (
                <p className="vwq">No Data Available</p>
              ) : (
          <div className="table-wrap">
            <table className="tables-style">
              <thead>
                 <tr className="sasq">
                 <th colSpan="11" style={{ textAlign: "center" }}>Transactions</th>
                 </tr>
                <tr>
                  <th style={{ textAlign: "center", margin: "0px" }}>No.</th>
                   <th style={{ textAlign: "center" }}>paymentId</th>
                  <th style={{ textAlign: "center" }}>GroupId</th>
                  <th style={{ textAlign: "center" }}>GroupName</th>
                  <th style={{ textAlign: "center" }}>Month</th>
                  <th style={{ textAlign: "center" }}>Installment Number</th>
                  <th style={{ textAlign: "center" }}>Bidwinner</th>
                  <th style={{ textAlign: "center" }}>Total Amount to pay</th>
                  <th style={{ textAlign: "center" }}>Amount Paid</th>
                  <th style={{ textAlign: "center" }}>PaymentMode</th>
                  <th style={{ textAlign: "center" }}>Payment Date</th>



                 </tr>
              </thead>
              <tbody>
              {transactions.map((transaction,index) => {
                return (
                    <tr style={{ margin: "0px" }}>
                      <td style={{ margin: "0px" }}>{index + 1}</td>
                       <td>{transaction.paymentId}</td>
                       <td>{transaction.idgroup}</td>
                      <td>{transaction.namegroup}</td>
                      <td>{transaction.month}</td>
                      <td>{transaction.insNum}</td>
                      <td>{transaction.bidwinner}</td>
                      <td>{transaction.tap}</td>
                      <td>{transaction.hap}</td>
                      <td>{transaction.paymentMode}</td>
                      <td>{transaction.paymentDate}</td>
                      
                    </tr>
                  );
                })}

              </tbody>
              </table>
              </div>
              )}
              </div>
            
            {/* <ul>
              {transactions.map((transaction) => (
                <li key={transaction.paymentId}>
                  {transaction.paymentDate}: {transaction.hap}
                </li>
              ))}
            </ul>  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paymentreport;

