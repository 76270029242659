import React, { useState, useEffect, useContext } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import "./ChitEdit.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import { toast } from "react-toastify";
import Superside from "../superadmin/Superside";
import { SuperadminContext } from "../context/superadmincontext";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userimage from "./images/userimage.png"
 
const initialState = {
  groupName: "",
  auctionType: "",
  startDate: "",
  noOfInstallments: "",
  endDate: "",
  noOfUsers: "",
  commission: "",
  auctionDate: "",
  chitAmount: "",
  createdby: "",
  chitstatus: "",
};
 
export const SupAdmChitEdit = () => {
  const usernamedashboard = localStorage.getItem("username");
 
  const { superadminname } = useContext(SuperadminContext);
 
  const [user, setuser] = useState({});
  const [state, setState] = useState(initialState);
  const {
    groupName,
    auctionType,
    startDate,
    noOfInstallments,
    endDate,
    noOfUsers,
    commission,
    auctionDate,
    chitAmount,
    createdby,
    chitstatus,
  } = state;
 
  const navigate = useNavigate();
 
  const { groupId } = useParams();
 
  useEffect(() => {
    axios
      .get(`https://api.chitgenius.com/api/getchitid/${groupId}`)
      .then((resp) => setState({ ...resp.data[0] }));
  }, [groupId]);
 
  
 
  const calculateEndDate = () => {
    if (state.startDate && state.noOfInstallments) {
      const [startYear, startMonth, startDay] = state.startDate
        .split("-")
        .map(Number);
      const installments = parseInt(state.noOfInstallments);
 
      const newStartDate = new Date(startYear, startMonth - 1, startDay);
      newStartDate.setMonth(newStartDate.getMonth() + installments);
 
      const newEndDate = `${newStartDate.getFullYear()}-${(
        newStartDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${newStartDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;
 
      // Update the state with the new end date
      setState((prevState) => ({ ...prevState, endDate: newEndDate }));
    }
  };
  useEffect(() => {
    const endDate = calculateEndDate();
    if (endDate) {
      console.log("End Date:", endDate);
    }
  }, [state.startDate, state.noOfInstallments]);
 
  // useEffect(() => {
  //   calculateEndDate();
  // }, [state.startDate, state.noOfInstallments]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !groupName ||
      !auctionType ||
      !startDate ||
      !noOfInstallments ||
      !endDate ||
      !noOfUsers ||
      !commission ||
      !auctionDate ||
      !chitAmount ||
      !createdby
    ) {
      alert("Please fill all the fields");
    } else {
      if (parseInt(chitAmount) < 50000) {
        alert("Chit amount must be at least 50,000");
        return;
      }
      if (noOfUsers < 5) {
        alert("Number of Users must be at least 5");
        return;
      }
      if (noOfInstallments < 5) {
        alert("Number of Installments must be at least 5");
        return;
      }
      if (commission > 20) {
        alert("Commission must be at most 20%");
        return;
      }
      
      if (!groupId) {
        axios
          .post("https://api.chitgenius.com/api/chitcreate/post", {
            groupName,
            auctionType,
            startDate,
            noOfInstallments,
            endDate,
            noOfUsers,
            commission,
            auctionDate,
            chitAmount,
            createdby,
            chitstatus,
          })
          .then(() => {
            setState({
              groupName: "",
              autiontype: "",
              startDate: "",
              noOfInstallments: "",
              endDate: "",
              noOfUsers: "",
              commission: "",
              auctionDate: "",
              chitAmount: "",
              createdby: "",
              chitstatus: "",
            });
          })
          .catch((err) => alert.error(err.response.data));
      } else {
        axios
          .put(`https://api.chitgenius.com/api/chitcreate/update/${groupId}`, {
            groupName,
            auctionType,
            startDate,
            noOfInstallments,
            endDate,
            noOfUsers,
            commission,
            auctionDate,
            chitAmount,
            createdby,
            chitstatus,
          })
          .then(() => {
            setState({
              groupName: "",
              autiontype: "",
              startDate: "",
              noOfInstallments: "",
              endDate: "",
              noOfUsers: "",
              commission: "",
              auctionDate: "",
              chitAmount: "",
              createdby: "",
              chitstatus: "",
            });
            toast.success("chit  updated successfully");
            setTimeout(() => {
              navigate("/superadminchittable");
            }, 500);
          })
          // .catch((err) => alert.error(err.response.data));
          .catch((err) => toast.error(err.response.data));
 
      }
      
    }
  };
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
        setState((prevState) => ({
          ...prevState,
          createdby: response.data[0].UserId,
        }));
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);
 
  const creatorname = user.UserId;
  console.log(creatorname);
 
  return (
    <div style={{ display: "flex" }}>
      <div>
        <Superside />
      </div>
      <div className="Super-kk">
        <div className="courses-head">
          <div className="h">
            <div className="">
              <NavLink className="" to={"/superadminchittable"}>
                <FaArrowLeftLong className="sm-ume"/>
              </NavLink>
            </div>
            <div className="super-dash-heading">
              <h2>Create Chit</h2>
            </div>
          </div>
 
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
 
        <hr style={{ marginTop: "0px" }}></hr>
        <div className="Super-bd">
          <form className="fd" onSubmit={handleSubmit}>
            <div className="Super-ngo">
              <div className="Super-display-11">
                <label className="Super-mug">Group Name</label>
                <input
                  type="text"
                  name="groupName"
                  class="Super-input-11"
                  value={groupName || ""}
                  placeholder="Group Name"
                  onChange={handleInputChange}
                  readOnly={!!groupId}
                />
              </div>
 
              <div className="Super-display-11">
                <label className="Super-mug">AuctionType</label>
                <select
                  name="auctionType"
                  className="Super-input-11"
                  value={auctionType || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select Auction Type</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
              <div className="Super-display-11">
                <label className="Super-mug">StartDate</label>
 
                <input
                  type="date"
                  name="startDate"
                  class="Super-input-11"
                  value={startDate || ""}
                  placeholder="startDate"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="Super-ngo">
              <div className="Super-display-11">
                <label className="Super-mug">No.of Installments</label>
 
                <input
                  type="number"
                  name="noOfInstallments"
                  value={noOfInstallments || ""}
                  class="Super-input-11"
                  placeholder="ex:10"
                  min="0"
                  max="99"
                  onChange={handleInputChange}
                />
              </div>
              <div className="Super-display-11">
                <label className="Super-mug">EndDate</label>
 
                <input
                  type="date"
                  name="endDate"
                  class="Super-input-11"
                  value={endDate || ""}
                  placeholder="endDate"
                  onChange={handleInputChange}
                />
              </div>
              <div className="Super-display-11">
                <label className="Super-mug">No.of Users</label>
 
                <input
                  type="int"
                  name="noOfUsers"
                  class="Super-input-11"
                  value={noOfUsers || ""}
                  placeholder="ex: 10"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="Super-ngo">
              <div className="Super-display-11">
                <label className="Super-mug">Commission(in Percentage)</label>
 
                <input
                  type="int"
                  name="commission"
                  class="Super-input-11"
                  value={commission || ""}
                  placeholder="Ex:-4"
                  onChange={handleInputChange}
                />
              </div>
              {/* <div className="display-11">
                <label className="mug">Every Month AuctionDate</label>
 
                <input
                  type="text"
                  name="auctionDate"
                  class="input-11"
                  value={auctionDate || ""}
                  placeholder="ex:- 2nd"
                  onChange={handleInputChange}
                />
              </div> */}
 
              <div className="Super-display-11">
                <label className="Super-mug">Every Month Auction Date</label>
                <select
                  name="auctionDate"
                  className="Super-input-11"
                  value={auctionDate || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {Array.from({ length: 31 }, (_, index) => index + 1).map(
                    (day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    )
                  )}
                </select>
              </div>
 
              <div className="Super-display-11">
                <label className="Super-mug">ChitAmount</label>
 
                <input
                  type="text"
                  name="chitAmount"
                  class="Super-input-11"
                  value={chitAmount || ""}
                  placeholder="ex: (1,00,000) or(10,000,00)"
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>
 
            <div className="Super-display-11">
              <label className="Super-mug">Createdby</label>
 
              {/* <input
                type="text"
                name="createdby"
                class="input-11"
                value={createdby || ""}
                placeholder="ex: (1,00,000) or(10,000,00)"
                onChange={handleInputChange}
              ></input> */}
              <input
                type="text"
                name="createdby"
                class="Super-input-11"
                value={createdby || ""}
                placeholder="ex: (1,00,000) or(10,000,00)"
                onChange={handleInputChange}
              >
                {/* <option value="">Select</option>
                  <option value="Agent">Agent</option> */}
              </input>
            </div>
            <div className="Super-display-01">
              <label className="Super-jug">chit status</label>
              <select
                name="chitstatus"
                className="Super-input-01"
                value={chitstatus || ""}
                onChange={handleInputChange}
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
            <div className="Super-hus">
              <input
                type="Submit"
                value={groupId ? "Update" : "save"}
                className="Super-cdf"
              />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};
 