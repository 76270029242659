import React, { useState, useEffect, useContext } from "react";

// import SideBar from "../components/Sidebar/SideBar";
import axios from "axios";
import Select from "react-select";
import Userside from "../usercompo/Userside";
import { CustomerContext } from "../context/customercontext";
import "./usermemberpassbook.css";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import userimage from "./images/userimage.png";

const Usermemberpassbook = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [state, setState] = useState("");
  const [duration, setDuration] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState("");
  const [userdata, setUserData] = useState({});
  const [groupNameOptions, setGroupNameOptions] = useState([]); // Options for group name dropdown
  const [selectedGroupName, setSelectedGroupName] = useState(""); //
  const { customername } = useContext(CustomerContext);
  const [mobileNo, setmobileNo] = useState("");

  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/user/new") {
        navigate("/user/new");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/user") {
        navigate("/");
      }
      return;
    }

    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!selectedGroupName|| !duration || !mobileNo) {
      alert("Please fill in all input fields.");
      return;
    }
    try {
      const response = await axios.post(
        "https://api.chitgenius.com/transactions/groupname/user",
        {
          groupName: selectedGroupName,
          duration: duration.trim(),
          mobileNo,
        }
      );
      if (response.data.length === 0) {
        alert("No data available.");
      } else {
        setTransactions(response.data);
      }
      // setTransactions(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      setError("An error occurred. Please try again.");
    }
  };

  const loadUserData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/api/getusermobile/mb/${usernamedashboard}`
    );
    setData(response.data);
  };

  useEffect(() => {
    loadUserData();
  }, [usernamedashboard]); // Empty dependency array to ensure useEffect runs only once

  useEffect(() => {
    if (mobileNo) {
      fetchUserData();
    }
  }, [mobileNo]);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `https://api.chitgenius.com/api/user/memberpassbook/${mobileNo}`
      );
      // setGroupName(response.data.group_Name);

      const options = response.data.map((group) => ({
        label: group,
        value: group,
      }));
      // Update dropdown options with group names
      setGroupNameOptions(options);
    } catch (error) {
      console.error("Error fetching group names:", error);
      setGroupNameOptions([]); // Reset dropdown options if an error occurs
    }
    // } catch (error) {
    //   console.error("Error fetching user data:", error);
    //   setGroupName(""); // Reset groupName if error occurs
    // }
  };

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${customername}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [customername]);

  return (
    <div className="User-conatinaer" style={{ display: "flex" }}>
      <div>
        <Userside />
      </div>
      <div className="mm">
        <div className="User-courses-head">
          <div className="dash-heading">
            <h2>Member passbook</h2>
          </div>
          <div className="image" style={{ display: "flex", gap: "10px" ,marginRight:"30px" }}>
            <div>
              <img
                // src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>

        <hr></hr>
        <div>
          <form onSubmit={handleSearch}>
            <div
              className="user-display-full-4"
              style={{ width: "100vh", marginTop: "12px" }}
            >
              <div className="user-hk">
                <div className="user-display-44">
                  <label>
                    <strong>MobileNo</strong>
                  </label>
                  <Select
                    value={{ label: mobileNo, value: mobileNo }}
                    onChange={(selectedOption) =>
                      setmobileNo(selectedOption.value)
                    }
                    options={data.map((opts) => ({
                      label: opts.mobileNo,
                      value: opts.mobileNo,
                    }))}
                    className="user-input-44"
                    isSearchable={true}
                  />
                </div>

                <div className="user-display-44">
                  <label>
                    <strong>Group Name</strong>
                  </label>
                  <Select
                    value={{
                      label: selectedGroupName,
                      value: selectedGroupName,
                    }}
                    onChange={(selectedOption) =>
                      setSelectedGroupName(selectedOption.value)
                    }
                    options={groupNameOptions}
                    className="user-input-44"
                    isSearchable={true}
                  />
                </div>

                <div className="user-display-44">
                  <label>
                    <strong>Duration</strong>
                  </label>
                  <select
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    className="user-input-44"
                    required
                  >
                    <option value="">Select</option>
                    <option value="3 months">3 months</option>
                    <option value="6 months">6 months</option>
                    <option value="12 months">12 months</option>
                  </select>
                </div>

               

               
              </div>

              <div className="umpbutton">
                <button type="submit" className="ump-button">
                  Search
                </button>
              </div>
            </div>
          </form>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="bgfs">
            {transactions.length > 0 ? (
              <div className="table-member">
                <table className="tables-style">
                  <thead>
                    <tr className="sasq">
                      <th colSpan="14" style={{ textAlign: "center" }}>
                        Transactions
                      </th>
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center", margin: "0px" }}>
                        No.
                      </th>
                      <th style={{ textAlign: "center" }}>Payment ID</th>
                      {/* <th style={{ textAlign: "center" }}>Group ID</th> */}
                      <th style={{ textAlign: "center" }}>Group Name</th>
                      <th style={{ textAlign: "center" }}>Month</th>
                      <th style={{ textAlign: "center" }}>
                        Installment Number
                      </th>
                      {/* <th style={{ textAlign: "center" }}>Bid Winner</th> */}
                      <th style={{ textAlign: "center" }}>
                        Total Amount to pay
                      </th>
                      <th style={{ textAlign: "center" }}>Amount Paid</th>
                      <th style={{ textAlign: "center" }}>Payment Mode</th>
                      <th style={{ textAlign: "center" }}>Balance</th>
                      {/* <th style={{ textAlign: "center" }}>UserId</th> */}
                      {/* <th style={{ textAlign: "center" }}>UserName</th> */}
                      <th style={{ textAlign: "center" }}>MobileNo</th>
                      <th style={{ textAlign: "center" }}>Payment Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{transaction.paymentcollect_Id}</td>
                        {/* <td>{transaction.payment_idgroup}</td> */}
                        <td>{transaction.payment_namegroup}</td>
                        <td>{transaction.payment_month}</td>
                        <td>{transaction.payment_insNum}</td>
                        {/* <td>{transaction.bidwinner}</td> */}
                        <td>{transaction.payment_tap}</td>
                        <td>{transaction.payment_hap}</td>
                        <td>{transaction.payment_Mode}</td>
                        <td>{transaction.payment_Balance}</td>
                        {/* <td>{transaction.payment_userId}</td> */}
                        {/* <td>{transaction.payment_username}</td> */}
                        <td>{transaction.payment_mobilenum}</td>
                        <td>{transaction.payment_Date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
            {/* (
               <div>
              <p className="vwq">No Data Available</p>
             
                </div> 
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usermemberpassbook;
