import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "./information.css";
import { NavLink } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { motion } from "framer-motion";
import SideBar from "../components/Sidebar/SideBar";
import { UserContext } from "../context/usecontext";
import userimage from "./images/userimage.png"

const Information = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [user, setuser] = useState({});
  const { groupId } = useParams();
  const history = useNavigate();
  const { username } = useContext(UserContext);
  const [loguser, setLoguser] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const dltchit = async (groupId) => {
    const confirmation = window.confirm(
      "Deleting this chit group will also delete all related auction, bidding, and payment data. Do you want to proceed?"
    );
    if (!confirmation) {
      return;
    }

    console.log(groupId);
    try {
      const res = await axios.delete(
        `https://api.chitgenius.com/api/delete/chitgroup/${groupId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status == 201) {
        history("/chitgroup");
      }
    } catch (error) {
      console.error("Error deleting chit group:", error);
      // setErrorMessage("Cannot delete chit group. Please ensure all related auction details are removed first.");
      setErrorMessage(
        error.response.data.error ||
          "Cannot delete chit group. Please ensure all related auction details are removed first."
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getchitid/${groupId}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchData();
  }, [groupId]);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setLoguser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const closeErrorMessage = () => {
    setErrorMessage("");
  };

  return (
    <div className="admin-container-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-information-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className="Ad-information"
        >
          <div className="Ad-courses-head-1">
            <div className="Ad-h">
              <NavLink style={{ color: "black" }} to={"/chitgroup"}>
                <FaArrowLeftLong className="Ad-ume" />
              </NavLink>
              <div className="Ad-information-heading">
                <h2>Information</h2>
              </div>
            </div>

            <div className="image">
              <div>
                <img
                // src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  src={`https://api.chitgenius.com/uploads/${loguser.uploadPhoto}`}
                  alt=""
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{loguser.UserName}</p>
                <p style={{ margin: "0px" }}>({loguser.role})</p>
              </div>
            </div>
          </div>
          <hr style={{ margin: "10px 0px" }}></hr>

          <div className="Ad-delete">
            <button onClick={() => dltchit(user.groupId)} className="Ad-delete-1">
              Delete
            </button>
          </div>
          <div className="Ad-info-container">
            <div className="Ad-display-full-2">
              <div className="Ad-info">
                <div className="Ad-display-01">
                  <label>
                    <strong>GroupId</strong>
                  </label>
                  <input
                    type="text"
                    name="text"
                    class="Ad-input-01"
                    value={user.groupId}
                    placeholder="Group Id:"
                  />
                </div>
                <div className="Ad-display-01">
                  <label>
                    <strong>GroupName</strong>
                  </label>

                  <input
                    type="text"
                    name="text"
                    class="Ad-input-01"
                    value={user.groupName}
                    placeholder="Group Name:"
                  />
                </div>
                <div className="Ad-display-01">
                  <label>
                    <strong>Auction Type</strong>
                  </label>

                  <input
                    type="text"
                    name="text"
                    class="Ad-input-01"
                    value={user.auctionType}
                    placeholder="Auction Type:"
                  />
                </div>
              </div>
              <div className="Ad-info">
                <div className="Ad-display-01">
                  <label>
                    <strong>startDate</strong>
                  </label>

                  <input
                    type="text"
                    name="text"
                    class="Ad-input-01"
                    value={user.startDate}
                    placeholder="Start Date:"
                  />
                </div>
                <div className="Ad-display-01">
                  <label>
                    <strong>Chit Amount</strong>
                  </label>

                  <input
                    type="text"
                    name="text"
                    class="Ad-input-01"
                    value={user.chitAmount}
                    placeholder="Chit Amount:"
                  />
                </div>
                <div className="Ad-display-01">
                  <label>
                    <strong>No.of Installments</strong>
                  </label>

                  <input
                    type="int"
                    name="text"
                    class="Ad-input-01"
                    value={user.noOfInstallments}
                    placeholder="No of Installments:"
                  />
                </div>
              </div>
              <div className="Ad-info">
                <div className="Ad-display-01">
                  <label>
                    <strong>EndDate</strong>
                  </label>

                  <input
                    type="text"
                    name="text"
                    class="Ad-input-01"
                    value={user.endDate}
                    placeholder="End Date:"
                  />
                </div>
                <div className="Ad-display-01">
                  <label>
                    <strong>No.of users</strong>
                  </label>

                  <input
                    type="int"
                    name="text"
                    class="Ad-input-01"
                    value={user.noOfUsers}
                    placeholder="No of users:"
                  />
                </div>
                <div className="Ad-display-01">
                  <label>
                    <strong>Commission</strong>
                  </label>

                  <input
                    type="text"
                    name="text"
                    class="Ad-input-01"
                    value={user.commission}
                    placeholder="Commission:"
                  />
                </div>
              </div>
              <div className="Ad-info">
                <div className="Ad-display-01">
                  <label>
                    <strong>AuctionDate</strong>
                  </label>
                  <input
                    type="text"
                    name="text"
                    class="Ad-input-01"
                    value={user.auctionDate}
                    placeholder="Auction Date:"
                  />
                </div>
                <div className="Ad-display-01">
                  <label>
                    <strong>CreatedBy</strong>
                  </label>
                  <input
                    type="text"
                    name="text"
                    class="Ad-input-01"
                    value={user.createdby}
                    placeholder="Auction Date:"
                  />
                </div>
                <div className="Ad-display-01">
                  <label>
                    <strong>Chit creation date</strong>
                  </label>

                  <input
                    type="text"
                    name="text"
                    class="Ad-input-01"
                    value={moment(user.dateOfCreation).format("DD-MM-YYYY")}
                    placeholder="chit creation Date:"
                  />
                </div>
              </div>
              <div className="Ad-display-01">
                <label>
                  <strong>Chit status</strong>
                </label>
                <input
                  type="text"
                  name="text"
                  class="Ad-input-01"
                  value={user.chitstatus}
                  placeholder="Auction Date:"
                />
              </div>
            </div>
            {/* <button onClick={()=>dltchit(user.groupId)}>Delete</button> */}
          </div>
          {errorMessage && (
            <div className="popup">
              <div className="popup-inner">
                {/* <span className="close" onClick={closeErrorMessage}>
                &times;
              </span> */}
                <h3 className="popupheading">Warning!</h3>
                <p className="popupmessage">{errorMessage}</p>
                <button className="popup-close" onClick={closeErrorMessage}>
                  Close
                </button>
              </div>
            </div>
          )}
        </motion.div>
      </div>
    </div>
    // </div>
    // </div>
  );
};

export default Information;
