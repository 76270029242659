import React, { useState, useEffect, useContext } from "react";
import CollectingAgentSide from "./CollectingAgentSide";
import axios from "axios";
import { motion } from "framer-motion";
import { CollectionagentContext } from "../context/collectionagentcontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import "../collectAgent/collectingagentprofile.css";
import userimage from "./images/userimage.png"

const CollectingAgentProfile = () => {
  const usernamedashboard = localStorage.getItem("username");
  const { collectionagentname } = useContext(CollectionagentContext);
  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/collectingagentprofile") {
        navigate("/collectingagentprofile");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/collectionagent") {
        navigate("/");
      }
      return;
    }

    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  return (
    <div className="collectingagent-profile" style={{ display: "flex" }}>
      <div>
        <CollectingAgentSide />
      </div>
      <div className="CA-userprofile-f">
        <div className="">
          <div className="CA-infoshead">
            <div className="CA-profile-heading">
              <h2>Profile</h2>
            </div>
          </div>
          <hr />
          <div>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.4 }}
              className=""
            >
              <div className="AD-TEXT">
                <p>
                  Complete Your Profile Here{" "}
                  <Link
                    to={`/collectionagentedit/profilememberentry/${user.UserId}`}
                    className="AD-edit"
                  >
                    Edit
                  </Link>
                </p>
              </div>
              <div className="CA-profile-full">
                <div className="CA-profile-001">
                  <img
                  src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                    // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                    alt=""
                    // style={{ height: "50px",borderRadius:'30px',width:"50px" }}
                    className="images-1"
                  ></img>
                  {/* <div className='mtr'><label><strong>User Profile</strong></label></div> */}
                </div>

                <div className="CA-pro">
                  <div className="CA-display-pro">
                    <label>
                      <strong>User Id</strong>
                    </label>
                    <p className="CA-input-pro">{user.UserId}</p>
                  </div>

                  <div className="CA-display-pro">
                    <label>
                      <strong>User Name</strong>
                    </label>
                    <p className="CA-input-pro">{user.UserName}</p>
                  </div>

                  <div className="CA-display-pro">
                    <label>
                      <strong>User Password</strong>
                    </label>
                    <p className="CA-input-pro">{user.userpassword}</p>
                  </div>
                </div>

                <div className="CA-pro">
                  <div className="CA-display-pro">
                    <label>
                      <strong>Date Of Birth</strong>
                    </label>
                    <p className="CA-input-pro">{user.UserDob}</p>
                  </div>

                  <div className="CA-display-pro">
                    <label>
                      <strong>Mobile No</strong>
                    </label>
                    <p className="CA-input-pro">{user.mobileNo}</p>
                  </div>

                  <div className="CA-display-pro">
                    <label>
                      <strong>Email</strong>
                    </label>
                    <p className="CA-input-pro">{user.email}</p>
                  </div>
                </div>

                <div className="CA-pro">
                  <div className="CA-display-pro">
                    <label>
                      <strong>Gender</strong>
                    </label>
                    <p className="CA-input-pro">{user.gender}</p>
                  </div>

                  <div className="CA-display-pro">
                    <label>
                      <strong>Occupation</strong>
                    </label>
                    <p className="CA-input-pro">{user.occupation}</p>
                  </div>

                  <div className="CA-display-pro">
                    <label>
                      <strong>Role</strong>
                    </label>
                    <p className="CA-input-pro">{user.role}</p>
                  </div>
                </div>

                <div className="CA-pro">
                  <div className="CA-display-pro">
                    <label>
                      <strong>Adhaar No</strong>
                    </label>
                    <p className="CA-input-pro">{user.adhaarNo}</p>
                  </div>

                  <div className="CA-display-pro">
                    <label>
                      <strong>Pan No</strong>
                    </label>
                    <p className="CA-input-pro">{user.panNo}</p>
                  </div>

                  <div className="CA-display-pro">
                    <label>
                      <strong>Address</strong>
                    </label>
                    <p className="CA-input-pro">{user.Address}</p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>

          {/* <p>{user.UserName}</p>
         <p> {user.role}</p>
         <p>{user.mobileNo}</p>
         <p><img
                src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                // style={{ height: "50px",borderRadius:'30px',width:"50px" }}
                className="images"
              ></img>
            </p>   */}

          {/* <p>{user.userpassword}</p>
            <p>{user.UserDob}</p>
            <p>{user.email}</p>
            <p>{user.gender}</p>
            <p>{user.occupation}</p>
            <p>{user.adhaarNo}</p>
            <p>{user.panNo}</p>
            <p>{user.Address}</p> */}
        </div>
      </div>
    </div>
  );
};

export default CollectingAgentProfile;
