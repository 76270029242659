import React, { useState } from "react";
import axios from "axios";

const Searchtest = () => {
    const [groupId, setGroupId] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    const [searchResult, setSearchResult] = useState([]);
  
    const handleSearch = async (e) => {
      e.preventDefault();
  
      try {
        const response = await axios.post("https://api.chitgenius.com/search", {
          groupId,
          paymentMode
        });
        setSearchResult(response.data);
      } catch (error) {
        console.error(error);
      }
    };
  
  return (
    <div>
     <form onSubmit={handleSearch}>
      <div>
        <label>groupId</label>
        <select value={groupId} onChange={(e) => setGroupId(e.target.value)}>
          <option value="1002">1002</option>
          <option value="1001">1001</option>
          <option value="1003">1003</option>
        </select>
      </div>
      <div>
        <label>paymentMode</label>
        <select value={paymentMode} onChange={(e) => setPaymentMode(e.target.value)}>
          <option value="Gpay">Gpay</option>
          <option value="phone pay">phone pay</option>
          <option value="paytm">paytm</option>
        </select>
      </div>
      <input type="submit" value="search"></input>
      </form>
      <div>
        {searchResult.map((result) => (
          <div key={result.paymentId}>
            {/* Display the search results here */}
            <p>{result.namegroup}</p>
            {/* Add more fields as needed */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Searchtest;
