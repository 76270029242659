import React from "react";
import { FcConferenceCall } from "react-icons/fc";
import { FcDebt } from "react-icons/fc";
import { FcDepartment } from "react-icons/fc";
import { FaUsers, FaUsersCog, FaUserTie } from "react-icons/fa";
import "./Abt.css"; // Import the CSS file for styling
import { FcSalesPerformance } from "react-icons/fc";
import { FcManager } from "react-icons/fc";
import { FcCollaboration } from "react-icons/fc";
function Abt() {
  return (
    <div>
      <div>
        <h2 className="ab-about">About Us</h2>
      </div>
      <div className="lg-card-container">
        <div className="lg-card">
          <div className="lg-card__icon">
            {/* Using FiUser icon */}
            <FcManager className="lg-card-lgicon" />
          </div>
          <div className="lg-card__content">
            <p className="lg-card__title">Chit Genius Private Limited</p>
            <p className="lg-card__description">
              Chit Genius offers an innovative approach to managing 
              your finances and combining the traditional model of chit 
              funds. Our platform enables users to participate in and 
              manage chit funds effortlessly, ensuring transparency, 
              security, and higher returns. 
              {/* Join Chit Genius to experience a modern twist on a time-tested savings mechanism, tailored to meet the needs of the digital age. */}
            </p>
          </div>
        </div>

        <div className="lg-card">
          <div className="lg-card__icon">
            {/* Using FiClock icon */}
            <FcDepartment className="lg-card-lgicon" />
          </div>
          <div className="lg-card__content">
            <p className="lg-card__title">History of Chit Genius</p>
            <p className="lg-card__description">
              Chit Genius company aimed to make saving and borrowing 
              more accessible and efficient. By integrating digital 
              platforms and advanced security measures, Chit Genius 
              revolutionized how individuals and small businesses 
              approach microfinance.
            </p>
          </div>
        </div>

        <div className="lg-card">
          <div className="lg-card__icon">
            {/* Using FiHome icon */}
            <FcDebt className="lg-card-lgicon" />
          </div>
          <div className="lg-card__content">
            <p className="lg-card__title">How we Work</p>
            <p className="lg-card__description">
              Chit Genius operates on a digital platform 
              where members join a chit group and contribute 
              a fixed amount monthly. Once part of a group, 
              members can bid for the pool of money collected 
              each month, often at a discount, allowing 
              immediate access to larger sums than their 
              individual contributions.
            </p>
          </div>
        </div>
      </div>
      <div className="lg-card-container">
        <div className="lg-card">
          <div className="lg-card__icon">
            {/* Using FiUser icon */}
            <FcSalesPerformance className="card-lgicon" />
          </div>
          <div className="lg-card__content">
            <p className="lg-card__title">The Organizer</p>
            <p className="lg-card__description">
              Simply put, the chit fund begins on a specified date and continues
              for the number of months equal to the number of subscribers.
              Members contribute their monthly instalments to the pot, and a
              reverse auction is conducted each month allowing members to bid
              for the Prize Money.
            </p>{" "}
          </div>
        </div>

        <div className="lg-card">
          <div className="lg-card__icon">
            {/* Using FiClock icon */}
            <FcCollaboration className="lg-card-lgicon" />
          </div>
          <div className="lg-card__content">
            <p className="lg-card__title">The Winning Bidder</p>
            <p className="lg-card__description">
              The winning bidder receives the money, while the discount 
              amount is distributed among the other members as dividends, 
              effectively lowering their investment cost. This cycle repeats 
              until every member has had a chance to receive the pool. It 
              ensures transparency and security through 
              user-friendly app, allowing members to track contributions, 
              bids, and payouts effortlessly.
            </p>
          </div>
        </div>

        <div className="lg-card">
          <div className="lg-card__icon">
            {/* Using FiHome icon */}
            <FcConferenceCall className="lg-card-lgicon" />
          </div>
          <div className="lg-card__content">
            <p className="lg-card__title">The Members</p>
            <p className="lg-card__description">
              Members of Chit Genius Funds are a diverse community of 
              individuals and small businesses who join to manage their 
              savings and access funds efficiently. Each member contributes 
              to the monthly pool and has the opportunity to bid for the 
              lump sum.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Abt;
