import React from "react";
import { NavLink, Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import AgentSide from "../agentcompo/AgentSide";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useEffect, useState, useContext } from "react";
import "./Agentbinding.css";
import { useNavigate } from "react-router-dom";
import { AgentContext } from "../context/agentcontext";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import userimage from "./images/userimage.png"

const Agentbidingdetails = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const { groupId } = useParams(); // Extract the groupId from the URL path
  const history = useNavigate();
  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message


  
  const dltbid = async (bidingId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const res = await axios.delete(
          `https://api.chitgenius.com/api/delete/bid/${bidingId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.status === 201) {
          toast.success("Successfully deleted!");
          fetchData();
          // navigate("/moreinfo");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    }
  };
 



  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.chitgenius.com/agent/api/bidingdetailsbyGroupId/${groupId}`
      ); // Use the groupId from the URL path
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

useEffect(() => {
  fetchData();
}, [groupId]);

 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  return (
    <div style={{ display: "flex" }}>
      <div>
        <AgentSide />
      </div>
      <div className="C-ttd">
        <div className="courses1-head">
          <div className="h">
            <div className="">
              <NavLink className="" to={"/agentchits"}>
                <FaArrowLeftLong className="Agent-Arrow" />
              </NavLink>
            </div>
            <div className="dash-heading">
              <h2>Biding Details</h2>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" ,marginRight:"30px" }}>
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
</div>
        <hr></hr>
        <div className="">
          <div className="bin-table-auction">
            <table className="bin-tables-style">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>No.</th>
                  <th className="tahe" style={{ textAlign: "center" }}>groupId</th>
                  <th className="tahe" style={{ textAlign: "center" }}>BidingId</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Chit Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>No.of users</th>
                  <th className="tahe" style={{ textAlign: "center" }}>installment Number</th>
                  <th className="tahe" style={{ textAlign: "center" }}>commission</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Bid Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Bid Winner</th>
                  <th className="tahe" style={{ textAlign: "center" }}>
                    collecting from subcriber
                  </th>
                  <th className="tahe" style={{ textAlign: "center" }}>payment to winner</th>
                  <th className="tahe" style={{ textAlign: "center" }}>auctionId</th>
                  <th className="tahe" style={{ textAlign: "center" }}>DateOfCreation</th>

                  <th className="tahe" style={{ textAlign: "center" }}>ACTION</th>
                </tr>
              </thead>

              {data.length > 0 ? (
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{item.id_group}</td>
                      <td style={{ textAlign: "center" }}>{item.bidingId}</td>
                      <td style={{ textAlign: "center" }}>{item.amount_chit}</td>
                      <td style={{ textAlign: "center" }}>{item.user_num}</td>
                      <td style={{ textAlign: "center" }}>{item.install_numb}</td>
                      <td style={{ textAlign: "center" }}>{item.comision}</td>
                      <td style={{ textAlign: "center" }}>{item.amount_bid}</td>
                      <td style={{ textAlign: "center" }}>{item.bid_winner}</td>
                      <td style={{ textAlign: "center" }}>{item.payoutsub}</td>
                      <td style={{ textAlign: "center" }}>{item.payinsub}</td>
                      <td style={{ textAlign: "center" }}>{item.id_auction}</td>
                      <td style={{ textAlign: "center" }}>{moment(item.date).format("DD-MM-YYYY")}</td>
                      <td style={{ textAlign: "center" }}>
                        <div className="gtf">
                          <button
                            onClick={() => dltbid(item.bidingId)}
                            className="ch" style={{ marginLeft:"10px" }}
                          >
                            Delete
                          </button>

                          <Link to={`/agent/Payout/${item.bidingId}`}>
                            <button className="ch" style={{ backgroundColor:"#212188"}}>Payout</button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      Biding not yet created for this GroupId:{groupId}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {errorMessage && (
          <div className="popup">;
            <div className="popup-inner">
              <h3>Warning!</h3>
              <p>{errorMessage}</p>
              <button className="popup-close" onClick={() => setErrorMessage("")}>Close</button>
           </div>
          </div>
        )}
      </div>
      <ToastContainer/>
    </div>
  );
};

export default Agentbidingdetails;
