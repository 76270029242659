import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import UsersideMenu from "./UsersideMenu";
import { PiUsersThreeBold } from "react-icons/pi";
import { FaCircleUser } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import { RiLogoutCircleLine } from "react-icons/ri";
import chigeniuslogo from  './images/chigenius-logo.png'
import { ImCross } from "react-icons/im";

const Userside = ({ children }) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth >= 768);
  const navigate = useNavigate();
  const [lastActivity, setLastActivity] = useState(Date.now());
  const idleTimePeriod = 5* 60 * 1000;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const logoutTimer = setTimeout(() => {
      const currentTime = Date.now();
      const idleTime = currentTime - lastActivity;
      // Idle time of 1 minute (60000 milliseconds)
      //(1 minutes * 60 seconds * 1000 milliseconds/second).
      if (idleTime >= idleTimePeriod) {
        handleLogout();
      }
    }, idleTimePeriod); // Check for inactivity every minute


    const resetActivity = () => {
      setLastActivity(Date.now());
    };

    const handleMouseMove = () => {
      resetActivity();
  };

  const handleKeyDown = () => {
      resetActivity();
  };

  const handleCursorMove = () => {
      resetActivity();
  };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("cursorchange", handleCursorMove); // You need to create this event


    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("cursorchange",handleCursorMove);

    };
    
  }, [lastActivity]);
 

  const handleLogout = () => {
    Cookies.remove('token');
    localStorage.removeItem('username');
    navigate('/land');
  };

  

  const routes = [
    {
      path: "/user/dashboard",
      name: "Dashboard",
      icon: <FaHome />
    },
    {
      path: "/user/new",
      name: "Memberpassbook",
      icon: <FaCircleUser />
    },

    {
      path: "/uprofile",
      name: "Profile",
      icon: <BsFillPersonFill />
    },

    {
      name: "Logout",
      icon: <RiLogoutCircleLine />,
      handleAction: handleLogout // Call handleLogout function on logout
    }
  ];

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar ${isOpen ? "open" : "closed"} `}
        >
        
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >

                </motion.h1>
              )}
            </AnimatePresence>
            <div>
              <motion.img src={chigeniuslogo} alt="Logo" className="chit-logo-side" variants={showAnimation} initial="hidden" animate="show" exit="hidden"/>
            </div>

           
            <div className="bars">
            {isOpen ? (
                <ImCross style={{ cursor: "pointer" }} className="imcross" onClick={() => setIsOpen(!isOpen)} />
              ) : (
                <FaBars style={{ cursor: "pointer" }} className="fabars" onClick={() => setIsOpen(!isOpen)} />
              )}
              <FaBars style={{cursor:'pointer'}} className="fabars1" onClick={() => setIsOpen(!isOpen)} />
            </div>
          </div>

          <section className="routes">
            {routes.map((route, index) => {
              if (route.handleAction) {
                return (
                  <div className="link" key={index} onClick={route.handleAction}>
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                 
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>

        <main className={isOpen ? 'open' : 'closed'}>{children}</main>
      </div>

    </>
  );
};

export default Userside;
