import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { motion } from "framer-motion";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import AgentSide from "../agentcompo/AgentSide";
import "./Agentalluction.css";
import userimage from "./images/userimage.png"
import { useNavigate } from "react-router-dom";
import { AgentContext } from "../context/agentcontext";

const AgentAllAuctions = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const { groupId } = useParams(); // Extract the groupId from the URL path
  const { auctionId } = useParams();
  const history = useNavigate();
  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message


  const dltgroupuser = async (auctionId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const res = await axios.delete(
          `https://api.chitgenius.com/api/delete/auctiondataid/${auctionId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status === 201) {
          toast.success("Successfully deleted!");
          fetchData();
          // navigate("/moreinfo");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    }
  };
 
 
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/auctionsByGroupId/${groupId}`
        ); // Use the groupId from the URL path
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
  useEffect(() => {
    fetchData();
  }, [groupId]);
 

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  return (
    <div style={{ display: "flex" }}>
      <div>
        <AgentSide />
      </div>
      <div className="C-ttd">
        <div className="courses1-head">
          <div className="h">
            <div className="">
              <NavLink className="" to={"/agentchits"}>
                <FaArrowLeftLong className="Agent-Arrow " />
              </NavLink>
            </div>
            <div className="dash-heading">
              <h2>Auction Details</h2>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" ,marginRight:"30px" }}>
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
</div>
        <hr></hr>
        <div className="">
          <div className="All-table-auction">
            <table className="All-tables-style">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>No.</th>
                  {/* <th style={{ textAlign: "center" }}>groupId</th> */}
                  <th className="tahe" style={{ textAlign: "center" }}>Auction Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Amount Due Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Installment Number</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Next Auction Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Bid Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Bider Name</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Group Id</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Group Name</th>
                  <th className="tahe" style={{ textAlign: "center" }}>chit Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>commission</th>
                  <th className="tahe" style={{ textAlign: "center" }}>DateOfCreation</th>

                  <th className="tahe" style={{ textAlign: "center" }}>ACTION</th>
                </tr>
              </thead>

              {data.length > 0 ? (
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{item.auctionDate}</td>
                      <td style={{ textAlign: "center" }}>{item.amountdueDate}</td>
                      <td style={{ textAlign: "center" }}>{item.InstallmentNumber}</td>
                      <td style={{ textAlign: "center" }} >{item.nextauctionDate}</td>
                      <td style={{ textAlign: "center" }}>{item.bidAmount}</td>
                      <td style={{ textAlign: "center" }}>{item.bidderName}</td>
                      <td style={{ textAlign: "center" }}>{item.group_id}</td>
                      <td style={{ textAlign: "center" }}>{item.group_name}</td>
                      <td style={{ textAlign: "center" }}>{item.chit_amount}</td>
                      <td style={{ textAlign: "center" }}>{item.commission_k}</td>
                      <td style={{ textAlign: "center" }}>
                        {moment(item.auctionCreationDate).format("DD-MM-YYYY")}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <div className="Au-gtf">
                          <div>
                            <Link
                              to={`/agent/update/autiondata/${item.auctionId}`}
                            >
                              <button className="home-edit">Edit</button>
                            </Link>
                          </div>
                          <div>
                            <button
                              onClick={() => dltgroupuser(item.auctionId)}
                              className="ch" 
                            >
                              Delete
                            </button>
                          </div>
                          <div>
                            <Link
                              to={`/agent/biding/${item.auctionId}/${item.group_id}`}
                            >
                              <button className="Au-home-edit" style={{ marginLeft:"-30px" }} >Biding</button>
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      Auctions not yet created for this GroupId:{groupId}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        {errorMessage && (
          <div className="Au-popup">;
            <div className="Au-popup-inner">
              <h3>Warning!</h3>
              <p>{errorMessage}</p>
              <button className="Au-popup-close" onClick={() => setErrorMessage("")}>Close</button>
           </div>
          </div>
        )}
      </div>
      <ToastContainer/>
    </div>
  );
};

export default AgentAllAuctions;
