import React, { useState, useEffect, useContext } from "react";
import "./allusers.css";
import { NavLink } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { motion } from "framer-motion";
import SideBar from "../components/Sidebar/SideBar";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../context/usecontext";
import moment from "moment";
import userimage from "./images/userimage.png"

const Allusers = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const { groupId } = useParams(); // Extract the groupId from the URL path

  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/groupsUsersByGroupId/${groupId}`
        ); // Use the groupId from the URL path
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [groupId]);

  return (
    <div className="Admin-allusers-main" style={{ display: "flex"}}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-allusers-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className=""
        >
          <div className="">
            <div className="Ad-allusers-head">
              <div className="Ad-h">
                <div>
                  <NavLink
                    style={{
                      color: "black"
                    }}
                    to={"/chitgroup"}
                  >
                    <FaArrowLeftLong className="Ad-ume" />
                  </NavLink>
                </div>
                <div className="Ad-allusers-heading">
                  <h2>All users</h2>
                </div>
              </div>
              <div className="image">
                <div>
                  <img
                  src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                    // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                    alt=""
                    style={{
                      height: "50px",
                      borderRadius: "30px",
                      width: "50px",
                    }}
                    className="images"
                  ></img>
                </div>
                <div>
                  <p style={{ margin: "0px" }}>{user.UserName}</p>
                  <p style={{ margin: "0px" }}>({user.role})</p>
                </div>
              </div>
            </div>
            <hr style={{ margin: "10px 0px" }}></hr>

            <div className="">
              <div className="Ad-table-allusers">
                <table className="Ad-table-style-Gu">
                  <thead>
                    <tr>
                      <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>
                        No.
                      </th>
                      <th className="tahe" style={{ textAlign: "center" }}>Group ID</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Group Name</th>
                      <th className="tahe" style={{ textAlign: "center" }}>User ID</th>
                      <th className="tahe" style={{ textAlign: "center" }}>User Name</th>
                      <th className="tahe" style={{ textAlign: "center" }}>mobileNo</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Email</th>

                      <th className="tahe" style={{ textAlign: "center" }}>UserAdded Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.group_Id}</td>
                          <td>{item.group_Name}</td>
                          <td>{item.user_Id}</td>
                          <td>{item.user_Name}</td>
                          <td>{item.user_mobileNo}</td>
                          <td>{item.email}</td>

                          <td>
                            {moment(item.userAddedDate).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Allusers;
