import React, { useState, useEffect, useContext } from "react";
import "./Chitgroup.css";
import {
  AiOutlineShareAlt,
  AiOutlineInfoCircle,
  AiOutlineEye,
} from "react-icons/ai";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import { TbEditCircle } from "react-icons/tb";
import AgentSide from "../agentcompo/AgentSide";
import { RiAuctionLine } from "react-icons/ri";
import { IoCreateOutline } from "react-icons/io5";
import { RiSecurePaymentLine } from "react-icons/ri";
import { AgentContext } from "../context/agentcontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { FaRegMoneyBill1 } from "react-icons/fa6";
import { FaHandHoldingDollar } from "react-icons/fa6";
import userimage from "./images/userimage.png";

const formatChitAmount = (amount) => {
  const numericAmount = parseFloat(amount.replace(/,/g, ""));
  let suffix = "";
  if (numericAmount >= 10000000) {
    suffix = "Cr";
  } else if (numericAmount >= 100000) {
    suffix = "L";
  }
  let formattedAmount = (
    numericAmount / (suffix === "Cr" ? 10000000 : 100000)
  ).toFixed(2);
  formattedAmount = formattedAmount.replace(/\.00$/, "") + suffix;
  return formattedAmount;
};

const AgentChitgroup = () => {
  const [data, setData] = useState([]);
  const { agentname } = useContext(AgentContext);
  const usernamedashboard = localStorage.getItem("username");
  const [user, setuser] = useState({});
  // const loginuserid= user.UserId;
  // console.log("Agent chits page",loginuserid);

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/agentchits") {
        navigate("/agentchits");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/agent") {
        navigate("/");
      }
      return;
    }

    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const loginuserid = user.UserId;

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chit/data/get/${loginuserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [loginuserid]);

  return (
    <div style={{ display: "flex" }}>
      <div>
        <AgentSide />
      </div>
      <div className="C-ttd">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className="C-courses1"
        >
          <div className="C-courses1-head">
            <div className="dash-heading">
              <h2>Groups</h2>
            </div>
            <div className="image">
              <div>
                <img
                  src={
                    user.uploadPhoto
                      ? `https://api.chitgenius.com/uploads/${user.uploadPhoto}`
                      : userimage
                  }
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
              </div>
            </div>
          </div>
          <hr />
          <div className="Ad-chitgroup-AC-1">
            <NavLink className="create-course" to={"/agentcreatechit"}>
              + Create
            </NavLink>
          </div>

          <div className="C-course-card">
            {data.map((item, index) => {
              return (
                <div className="full-card" key={index}>
                  <div>
                    <Link
                      to={`/agent/addusersintochit/${item.groupId}/${item.groupName}/${item.noOfUsers}`}
                      style={{ textDecorationLine: "none" }}
                    >
                      <div className="card-chitgroup" title={`Add users`}>
                        <div className="logo-cont">
                          <AiOutlineShareAlt
                            className="share"
                            style={{ color: "black" }}
                          />
                        </div>
                        <Link
                          to={`/agent/addusersintochit/${item.groupId}/${item.groupName}/${item.noOfUsers}`}
                          className="batch"
                          title={`Add users`}
                        >
                          <h4 className="vg-1">{`${item.groupId}/${
                            item.groupName
                          }/${formatChitAmount(item.chitAmount)}`}</h4>
                        </Link>
                      </div>
                    </Link>
                  </div>
                  <div className="batch-details">
                    <p className="id">
                      <strong>GroupID:</strong> {item.groupId}
                    </p>
                    <p className="id">
                      <strong>GroupName:</strong> {item.groupName}
                    </p>
                    <p className="id">
                      <strong>Group Members:</strong> {item.noOfUsers}
                    </p>
                    <p className="id">
                      <strong>Group Members:</strong> {item.createdby}
                    </p>
                  </div>
                  <hr />
                  <div className="bottom-icons">
                    <NavLink
                      style={{ color: "black" }}
                      to={`/agentchitinfo/${item.groupId}`}
                    >
                      <AiOutlineInfoCircle
                        className="card-icon"
                        title={`Information`}
                      />
                    </NavLink>

                    <NavLink
                      style={{ color: "black" }}
                      to={`/agent/auction/${item.groupId}/${item.groupName}/${item.chitAmount}/${item.commission}/${item.noOfUsers}`}
                    >
                      <IoCreateOutline
                        className="card-icon"
                        title={`Auction`}
                      />
                    </NavLink>

                    <NavLink
                      style={{ color: "black" }}
                      to={`/agent/allauctionsbygroupid/${item.groupId}`}
                    >
                      <RiAuctionLine className="card-icon" title={`Biding`} />
                    </NavLink>

                    <NavLink
                      style={{ color: "black" }}
                      to={`/agent/allbidingbygroupid/${item.groupId}`}
                    >
                      <RiSecurePaymentLine
                        className="card-icon"
                        title={`payout`}
                      />
                    </NavLink>

                    <NavLink
                      style={{ color: "black" }}
                      to={`/agent/allusers/${item.groupId}`}
                    >
                      <AiOutlineEye className="card-icon" title={`Allusers`} />
                    </NavLink>

                    <NavLink
                      style={{ color: "black" }}
                      to={`/aallpaymentsout/${item.groupId}`}
                    >
                      <FaRegMoneyBill1
                        className="card-icon"
                        title={`payoutDetails`}
                      />
                    </NavLink>

                    <NavLink
                      style={{ color: "black" }}
                      to={`/aallpaymentcollectiondetails/${item.groupName}`}
                    >
                      <FaHandHoldingDollar
                        className="card-icon"
                        title={`paymentcollectionDetails`}
                      />
                    </NavLink>
                    <NavLink
                      style={{ color: "black" }}
                      to={`/agent/edit/chit/${item.groupId}`}
                    >
                      <TbEditCircle className="card-icon" title={`Edit`} />
                    </NavLink>
                  </div>
                </div>
              );
            })}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AgentChitgroup;
