import React, { createContext, useState } from "react";

export const AgentContext = createContext();

export const AgentProvider = ({ children }) => {
  const [agentname, setAgentname] = useState("");

  return (
    <AgentContext.Provider value={{ agentname, setAgentname }}>
      {children}
    </AgentContext.Provider>
  );
};