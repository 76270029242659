import React, { useState, useEffect, useContext } from "react";
import Superside from "../superadmin/Superside";
import { motion } from "framer-motion";
import { SuperadminContext } from "../context/superadmincontext";
import axios from "axios";
import "../superpages/Spro.css";
import {useNavigate,useLocation} from "react-router-dom"
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import userimage from "./images/userimage.png"
 
const Superpro = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [user, setuser] = useState({});
  const { superadminname } = useContext(SuperadminContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
      const token = Cookies.get('token');
      const { state } = location;

      if (token) {
         
          if (location.pathname !== '/superprofile') {
              navigate('/superprofile');
          }
          return;
      }

      if ( !state || !state.username || !state.role) {
        if (location.pathname !== '/superadmin') {
          navigate('/');
        }
        return;
      }
     
          setUsername(state.username);
          setRole(state.role);
          console.log(state.username, state.role);
         
    }, [location, navigate]);
 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);
 
  return (
    <div className="supro-profile" style={{ display: "flex" }}>
    <div>
      <Superside />
    </div>
    <div className="supro-profile-content">
      <div className="supro-profile-info">
        <div className="infoshead">
          <div className="supro-profile-heading">
            <h2>Profile</h2>
          </div>
        </div>
        <hr />
        <div>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
            className="courses1"
          >
            <div className="AD-TEXT">
              <p>Complete Your Profile Here <Link to={`/superadminedit/profilememberentry/${user.UserId}`} className='Ad-edit'>Edit</Link></p>
            </div>
            <div className="supro-profile-avatar-container">
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="supro-profile-avatar"
              />
            </div>
            <div className="supro-profile-main">
              <div className="supro-profile-column">
                <div className="supro-profile-details">
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>User Id</strong>
                    </label>
                    <p className="supro-profile-input">{user.UserId}</p>
                  </div>
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>User Name</strong>
                    </label>
                    <p className="supro-profile-input">{user.UserName}</p>
                  </div>
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>User Password</strong>
                    </label>
                    <p className="supro-profile-input">{user.userpassword}</p>
                  </div>
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>Date Of Birth</strong>
                    </label>
                    <p className="supro-profile-input">{user.UserDob}</p>
                  </div>
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>Mobile No</strong>
                    </label>
                    <p className="supro-profile-input">{user.mobileNo}</p>
                  </div>
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>Email</strong>
                    </label>
                    <p className="supro-profile-input">{user.email}</p>
                  </div>
                </div>
              </div>
              <div className="supro-profile-column">
                <div className="supro-profile-details">
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>Gender</strong>
                    </label>
                    <p className="supro-profile-input">{user.gender}</p>
                  </div>
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>Occupation</strong>
                    </label>
                    <p className="supro-profile-input">{user.occupation}</p>
                  </div>
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>Role</strong>
                    </label>
                    <p className="supro-profile-input">{user.role}</p>
                  </div>
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>Adhaar No</strong>
                    </label>
                    <p className="supro-profile-input">{user.adhaarNo}</p>
                  </div>
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>Pan No</strong>
                    </label>
                    <p className="supro-profile-input">{user.panNo}</p>
                  </div>
                  <div className="supro-profile-row">
                    <label className="supro-profile-label">
                      <strong>Address</strong>
                    </label>
                    <p className="supro-profile-input">{user.Address}</p>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  </div>
  

  );
};
 
export default Superpro;