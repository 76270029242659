import React, { useState, useEffect, useContext } from "react";
import Superside from "../superadmin/Superside";
import { Link, Navigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { motion } from "framer-motion";
import { SuperadminContext } from "../context/superadmincontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import "./SupAdmChitInfo.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import userimage from "./images/userimage.png"
 
 
 
const SupAdmChitInfo = () => {
  const usernamedashboard = localStorage.getItem("username");
 
  const [data, setData] = useState([]);
  const { groupId } = useParams();
  // const history = useNavigate();
  const { superadminname } = useContext(SuperadminContext);
  const [user, setuser] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
 
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");
 
  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;
 
    if (token) {
      if (location.pathname !== "/superadminchittable") {
        navigate("/superadminchittable");
      }
      return;
    }
 
    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/superadmin") {
        navigate("/");
      }
      return;
    }
 
    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);
 
 
 
  const dltchit = async (groupId) => {
    const confirmation = window.confirm(
      "Deleting this chit group will also delete all related auction, bidding, and payment data. Do you want to proceed?"
    );
    if (!confirmation) {
      return;
    }
 
    console.log(groupId);
    try {
      const res = await axios.delete(
        `https://api.chitgenius.com/api/delete/chitgroup/${groupId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.status == 201) {
        toast.success("Successfully deleted!");
        loadData();
 
      }
    } catch (error) {
      console.error("Error deleting chit group:", error);
      // setErrorMessage("Cannot delete chit group. Please ensure all related auction details are removed first.");
      setErrorMessage(
        error.response.data.error ||
          "Cannot delete chit group. Please ensure all related auction details are removed first."
      );
    }
  };
 

 
  const loadData = async () => {
    const response = await axios.get("https://api.chitgenius.com/chit/data/gets");
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, []);
 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);
 
  const closeErrorMessage = () => {
    setErrorMessage("");
  };
 
  return (
    <div style={{ display: "flex" }}>
      <div>
        <Superside />
      </div>
      <div className="super-Admin-chit-info">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className="courses1"
        >
          <div className="super-Chit-head">
            <div className="courses-head-nav-1">
              <div className="dash-heading">
                <h2>Chit Groups</h2>
              </div>
              <div className="Action-profile-set-1">
                <div>
                  <img
                  src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                    // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                    alt=""
                    className="images"
                  ></img>
                </div>
                <div>
                  <p style={{ margin: "0px" }}>{user.UserName}</p>
                  <p style={{ margin: "0px" }}>({user.role})</p>
                </div>
              </div>
            </div>
          </div>
          <hr />
 
          <div className="">
            <div className="super-Chit-table-auction">
              <table className="super-Chit-tables-style">
                <thead>
                  <tr>
                    <th
                    className="tahe"
                     
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      No
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      Group ID
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      Group Name
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      Auction Type
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      Start Date
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      Chit Amount
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      No Of Installments
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      End Date
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      No Of Users
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      Commission
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      Auction Date
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      Created By
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      Chit Creation Date
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center", margin: "0" }}
                    >
                      Chit Status
                    </th>
                    <th
                      className="tahe"
                      style={{ textAlign: "center"}}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
 
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.groupId}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.groupName}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.auctionType}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.startDate}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.chitAmount}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.noOfInstallments}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.endDate}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.noOfUsers}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.commission}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.auctionDate}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.createdby}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.dateOfCreation}
                        </td>
                        <td
                          className="alluser-td"
                          style={{ textAlign: "center" }}
                        >
                          {item.chitstatus}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div className="">
                            <NavLink
                              style={{ color: "black" }}
                              to={`/super/admin/edit/chit/${item.groupId}`}
                            >
                              <button className="home-edit" >Edit</button>
                            </NavLink>
                            <button
                              onClick={() => dltchit(item.groupId)}
                              className="ch-1"
                               
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {errorMessage && (
            <div className="popup">
              <div className="popup-inner">
                {/* <span className="close" onClick={closeErrorMessage}>
                &times;
              </span> */}
                <h3>Warning!</h3>
                <p>{errorMessage}</p>
                <button className="popup-close" onClick={closeErrorMessage}>
                  Close
                </button>
              </div>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};
 
export default SupAdmChitInfo;
 