// import React, { useState } from "react";
// import "./paymentreport.css";
// import SideBar from "../components/Sidebar/SideBar";
// import axios from "axios";

// const Newtrans = () => {
//   const [durationType, setDurationType] = useState("");
//   const [duration, setDuration] = useState("");
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [transactions, setTransactions] = useState([]);
//   const [error, setError] = useState(null);

//   const handleSearch = async (e) => {
//     e.preventDefault();
//     let requestData = {};

//     if (duration === "custom") {
//         // Handle custom duration
//         requestData = {
//             startDate: startDate,
//             endDate: endDate
//         };
//     } else {
//         // Handle fixed duration
//         requestData = {
//             duration: duration
//         };
//     }

//     try {
      
//       const response = await axios.post("https://api.chitgenius.com/transactions/new", requestData);
//       setTransactions(response.data);
//       setError(null);
//     } catch (error) {
//       console.error(error);
//       setError("An error occurred. Please try again.");
//     }
//   };

//   return (
//     <div style={{ display: "flex" }}>
//       <div>
//         <SideBar />
//       </div>
//       <div className="info-23">
//         <div>
//           <h3>Payment Report</h3>
//         </div>
//         <hr></hr>
//         <div className="fixed-duration">
//           <form onSubmit={handleSearch}>
//             <div className="vgt">
//               <label><strong>Duration</strong></label>
//               <select
//                 value={durationType}
//                 onChange={(e) => setDurationType(e.target.value)}
//                 className="i-2"
//                 required
//               >
//                 <option value="">Select duration</option>
//                 <option value="fixed">Fixed</option>
//                 <option value="custom">Custom</option>
//               </select>
//               {durationType === "fixed" && (
//                 <select
//                   value={duration}
//                   onChange={(e) => setDuration(e.target.value)}
//                   className="i-2"
//                   required
//                 >
//                   <option value="">Select fixed duration</option>
//                   <option value="3months">3 months</option>
//                   <option value="6months">6 months</option>
//                 </select>
//               )}
//               {durationType === "custom" && (
//                 <div>
//                   <label>Start Date:</label>
//                   <input
//                     type="date"
//                     value={startDate}
//                     onChange={(e) => setStartDate(e.target.value)}
//                   />
//                   <label>End Date:</label>
//                   <input
//                     type="date"
//                     value={endDate}
//                     onChange={(e) => setEndDate(e.target.value)}
//                   />
//                 </div>
//               )}
//               <div className="cft">
//                 <button type="submit" className="cdf">Search</button>
//               </div>
//             </div>
//           </form>
//           {error && <p style={{ color: "red" }}>{error}</p>}
//           <div className="bgfs">
//             {transactions.length === 0 ? (
//               <p className="vwq stx">No Data Available</p>
//             ) : (
//               <div className="table-wrap">
//                 <table className="tables-style">
//                   <thead>
//                     <tr className="sasq">
//                       <th colSpan="11" style={{ textAlign: "center" }}>Transactions</th>
//                     </tr>
//                     <tr>
//                       <th style={{ textAlign: "center", margin: "0px" }}>No.</th>
//                       <th style={{ textAlign: "center" }}>paymentId</th>
//                       <th style={{ textAlign: "center" }}>GroupId</th>
//                       <th style={{ textAlign: "center" }}>GroupName</th>
//                       <th style={{ textAlign: "center" }}>Month</th>
//                       <th style={{ textAlign: "center" }}>Installment Number</th>
//                       <th style={{ textAlign: "center" }}>Bidwinner</th>
//                       <th style={{ textAlign: "center" }}>Total Amount to pay</th>
//                       <th style={{ textAlign: "center" }}>Amount Paid</th>
//                       <th style={{ textAlign: "center" }}>PaymentMode</th>
//                       <th style={{ textAlign: "center" }}>Payment Date</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {transactions.map((transaction, index) => (
//                       <tr key={index}>
//                         <td>{index + 1}</td>
//                         <td>{transaction.paymentId}</td>
//                         <td>{transaction.idgroup}</td>
//                         <td>{transaction.namegroup}</td>
//                         <td>{transaction.month}</td>
//                         <td>{transaction.insNum}</td>
//                         <td>{transaction.bidwinner}</td>
//                         <td>{transaction.tap}</td>
//                         <td>{transaction.hap}</td>
//                         <td>{transaction.paymentMode}</td>
//                         <td>{transaction.paymentDate}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Newtrans;
import React, { useState } from "react";
import "./paymentreport.css";
import SideBar from "../components/Sidebar/SideBar";
import axios from "axios";

const Newtrans = () => {
  const [durationType, setDurationType] = useState("");
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);

 

//   const formatDate = (dateString) => {
//     const [day, month, year] = dateString.split('-');
//     return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
//   };

//   const formatDate = (dateString) => {
//     if (!dateString) return ''; // Return an empty string if dateString is undefined or empty
  
//     const [day, month, year] = dateString.split('-');
//     return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
//   };
  

  const handleSearch = async (e) => {
    e.preventDefault();

//     const formattedStartDate = startDate.split('-').reverse().join('-');
// const formattedEndDate = endDate.split('-').reverse().join('-');


// const formattedStartDate = formatDate(startDate);
// const formattedEndDate = formatDate(endDate);
console.log(startDate);
console.log(endDate);

    let requestData = {};

    if (duration === "custom") {
        // Handle custom duration
        requestData = {
            // startDate: startDate,
            // endDate: endDate
            startDate: startDate,
            endDate: endDate
        };
    } else {
        // Handle fixed duration
        requestData = {
            duration: duration
        };
    }

    try {
        console.log("Entering try block...");

      const response = await axios.post("https://api.chitgenius.com/transactions/new", requestData);
      setTransactions(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      //setError("An error occurred. Please try again.");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(error.response.data.error);
      } else if (error.request) {
        // The request was made but no response was received
        setError("No response received from the server");
      } else {
        // Something happened in setting up the request that triggered an Error
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="info-23">
        <div>
          <h3>Payment Report</h3>
        </div>
        <hr></hr>
        <div className="fixed-duration">
          <form onSubmit={handleSearch}>
            <div className="vgt">
              <label><strong>Duration</strong></label>
              <select
                value={durationType}
                onChange={(e) => setDurationType(e.target.value)}
                className="i-2"
                required
              >
                <option value="">Select duration</option>
                <option value="fixed">Fixed</option>
                <option value="custom">Custom</option>
              </select>
              {durationType === "fixed" && (
                <select
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="i-2"
                  required
                >
                  <option value="">Select fixed duration</option>
                  <option value="3months">3 months</option>
                  <option value="6months">6 months</option>
                </select>
              )}
              {durationType === "custom" && (
                <div>
                  <label>Start Date:</label>
                  <input
                    name="startDate"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <label>End Date:</label>
                  <input
                    name="endDate"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              )}
              <div className="cft">
                <button type="submit" className="cdf">Search</button>
              </div>
            </div>
          </form>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="bgfs">
            {transactions.length === 0 ? (
              <p className="vwq stx">No Data Available</p>
            ) : (
              <div className="table-wrap">
                <table className="tables-style">
                  <thead>
                    <tr className="sasq">
                      <th colSpan="11" style={{ textAlign: "center" }}>Transactions</th>
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center", margin: "0px" }}>No.</th>
                      <th style={{ textAlign: "center" }}>paymentId</th>
                      <th style={{ textAlign: "center" }}>GroupId</th>
                      <th style={{ textAlign: "center" }}>GroupName</th>
                      <th style={{ textAlign: "center" }}>Month</th>
                      <th style={{ textAlign: "center" }}>Installment Number</th>
                      <th style={{ textAlign: "center" }}>Bidwinner</th>
                      <th style={{ textAlign: "center" }}>Total Amount to pay</th>
                      <th style={{ textAlign: "center" }}>Amount Paid</th>
                      <th style={{ textAlign: "center" }}>PaymentMode</th>
                      <th style={{ textAlign: "center" }}>Payment Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{transaction.paymentId}</td>
                        <td>{transaction.idgroup}</td>
                        <td>{transaction.namegroup}</td>
                        <td>{transaction.month}</td>
                        <td>{transaction.insNum}</td>
                        <td>{transaction.bidwinner}</td>
                        <td>{transaction.tap}</td>
                        <td>{transaction.hap}</td>
                        <td>{transaction.paymentMode}</td>
                        <td>{transaction.paymentDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newtrans;
