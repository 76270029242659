import React, { useState, useEffect, useContext } from "react";
import "./memberpassbook.css";
import SideBar from "../components/Sidebar/SideBar";
import axios from "axios";
import Select from "react-select";
import { UserContext } from "../context/usecontext";
import {useNavigate,useLocation} from "react-router-dom";
import Cookies from 'js-cookie';
import userimage from "./images/userimage.png"

const Memberpassbook = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [duration, setDuration] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
      const token = Cookies.get('token');
      const { state } = location;

      if (token) {
         
          if (location.pathname !== '/memberpassbook') {
              navigate('/memberpassbook');
          }
          return;
      }

      if ( !state || !state.username || !state.role) {
        if (location.pathname !== '/admin') {
          navigate('/');
        }
        return;
      }
     
          setUsersname(state.username);
          setRole(state.role);
          console.log(state.username, state.role);
         
    }, [location, navigate]);

  

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!groupName || !duration) {
      alert("Please fill in all input fields.");
      return;
    }
    try {
      const response = await axios.post(
        "https://api.chitgenius.com/transactions/groupname",
        {
          groupName,
          duration: duration.trim(),
        }
      );
      if (response.data.length === 0) {
        alert("No data available.");
      } else {
        setTransactions(response.data);
      }
      // setTransactions(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      setError("An error occurred. Please try again.");
    }
  };

 

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const loginuserid = user.UserId;

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chit/data/get/${loginuserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [loginuserid]);

  return (
    <div className="Ad-memberpassbook-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-memberpassbook-full">
        <div className="Ad-memberpassbook-head">
          <div className="Ad-memberpassbook-heading">
            <h2>Member passbook</h2>
          </div>

          <div className="image">
          <div >
            <img
            src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
              // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
              alt=""
              // style={{ height: "50px", borderRadius: "30px", width: "50px" }}
              className="images"
            ></img>
          </div>
          <div>
            <p style={{ margin: "0px" }}>{user.UserName}</p>
            <p style={{ margin: "0px" }}>({user.role})</p>
          </div>
        </div>
        </div>

       
        <hr></hr>
        <div>
          <form onSubmit={handleSearch}>
            <div className="Ad-MP-pure">
              <div className="Ad-memberpassbook-vgtdf">
                <label>
                  <strong>Group Name</strong>
                </label>
                <Select
                  className="Ad-Mp-i-2"
                  value={{ label: groupName, value: groupName }}
                  onChange={(selectedOption) =>
                    setGroupName(selectedOption.value)
                  }
                  options={data.map((opts) => ({
                    label: opts.groupName,
                    value: opts.groupName,
                  }))}
                  isSearchable={true}
                />
              </div>
              <div className="Ad-memberpassbook-vgtdf">
                <label>
                  <strong>Duration</strong>
                </label>
                <select
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="Ad-Mp-i-2"
                  required
                >
                  <option value="">Select</option>
                  <option value="3 months">3 months</option>
                  <option value="6 months">6 months</option>
                  <option value="12 months">12 months</option>
                </select>
              </div>
              <div className="Ad-mp-cft">
                <button type="submit" className="Ad-mp-cdf">
                  Search
                </button>
              </div>
            </div>
          </form>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="Ad-up-bgfs">
            {transactions.length > 0 ? (
              <div className="Ad-table-userpayreport">
                <table className="Ad-table-style-Gu">
                  <thead>
                    <tr className="Ad-sasq">
                      <th colSpan="11" style={{ textAlign: "center" }}>
                        Transactions
                      </th>
                    </tr>
                    <tr>
                      <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>
                        No.
                      </th>
                      <th className="tahe" style={{ textAlign: "center" }}>Payment ID</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Group ID</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Group Name</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Month</th>
                      <th className="tahe" style={{ textAlign: "center" }}>
                        Installment Number
                      </th>
                      <th className="tahe" style={{ textAlign: "center" }}>Bid Winner</th>
                      <th className="tahe" style={{ textAlign: "center" }}>
                        Total Amount to pay
                      </th>
                      <th className="tahe" style={{ textAlign: "center" }}>Amount Paid</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Payment Mode</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Payment Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{transaction.paymentId}</td>
                        <td>{transaction.idgroup}</td>
                        <td>{transaction.namegroup}</td>
                        <td>{transaction.month}</td>
                        <td>{transaction.insNum}</td>
                        <td>{transaction.bidwinner}</td>
                        <td>{transaction.tap}</td>
                        <td>{transaction.hap}</td>
                        <td>{transaction.paymentMode}</td>
                        <td>{transaction.paymentDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
            {/* (
               <div>
              <p className="vwq">No Data Available</p>
             
                </div> 
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Memberpassbook;
