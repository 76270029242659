import React, { useState, useEffect, useContext } from "react";
import { NavLink, useParams, Link, useNavigate } from "react-router-dom";
import "./Usermember.css";
import axios from "axios";
import { FaArrowLeftLong } from "react-icons/fa6";
import Userside from "../usercompo/Userside";
import { CustomerContext } from "../context/customercontext";
import userimage from "./images/userimage.png";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
 
const initialState = {
  UserName: "",
  userpassword: "",
  UserDob: "",
  mobileNo: "",
  email: "",
  gender: "",
  occupation: "",
  role: "",
  createdbyid: "",
  createdbyrole: "",
  adhaarnumber: "",
  uploadadhaarcard1: {},
  pannumber: "",
  uploadpancard1: {},
  uploadphoto1: {},
  Address: "",
};
 
const Usermember = () => {
  const usernamedashboard = localStorage.getItem("username");
 
  const [state, setState] = useState(initialState);
 
  const { customername } = useContext(CustomerContext);
  const navigate = useNavigate();
 
  const [user, setuser] = useState({});
 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);
 
  const {
    UserName,
    userpassword,
    UserDob,
    mobileNo,
    email,
    gender,
    occupation,
    createdbyid,
    createdbyrole,
    role,
    adhaarNo,
    uploadadhaarcard1,
    panNo,
    uploadpancard1,
    Address,
    uploadphoto1,
  } = state;
 
  const { UserId } = useParams();
 
  useEffect(() => {
    axios
      .get(`https://api.chitgenius.com/api/get/${UserId}`)
      .then((resp) => setState({ ...resp.data[0] }));
  }, [UserId]);
 
 
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !UserName ||
      !userpassword ||
      !UserDob ||
      !mobileNo ||
      !email
     
    ) {
      // debugger;
      // alert.error("Please provide a value for each input field");
      toast.error('Please provide a value for each input field');
 
    } else {
      if (!UserId) {
        const formData = new FormData();
        formData.append("UserName", UserName);
        formData.append("userpassword", userpassword);
        formData.append("UserDob", UserDob);
        formData.append("mobileNo", mobileNo);
        formData.append("email", email);
        formData.append("gender", gender);
        formData.append("occupation", occupation);
        formData.append("createdbyid", user.UserId);
        formData.append("createdbyrole", user.role);
        formData.append("role", role);
        formData.append("adhaarNo", adhaarNo);
        formData.append("uploadadhaarcard1", uploadadhaarcard1);
        formData.append("panNo", panNo);
        formData.append("uploadpancard1", uploadpancard1);
        formData.append("Address", Address);
        formData.append("uploadphoto1", uploadphoto1);
 
        axios
          .post("https://api.chitgenius.com/api/myupload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            setState({
              UserName: "",
              userpassword: "",
              UserDob: "",
              mobileNo: "",
              email: "",
              gender: "",
              occupation: "",
              createdbyid: "",
              createdbyrole: "",
              role: "",
              adhaarNo: "",
              uploadadhaarcard1: {},
              panNo: "",
              uploadpancard1: {},
              Address: "",
              uploadphoto1: {},
            });
            toast.success('Member Added Successfully');
 
          })
          .catch((err) => toast.error(err.response.data));
        // alert.success("Member Added Successfully");
      } else {
        console.log(UserId);
        const formData = new FormData();
        formData.append("UserName", UserName);
        formData.append("userpassword", userpassword);
        formData.append("UserDob", UserDob);
        formData.append("mobileNo", mobileNo);
        formData.append("email", email);
        formData.append("gender", gender);
        formData.append("occupation", occupation);
        formData.append("createdbyid", user.UserId);
        formData.append("createdbyrole", user.role);
        formData.append("role", role);
        formData.append("adhaarNo", adhaarNo);
        formData.append("uploadadhaarcard1", uploadadhaarcard1);
        formData.append("panNo", panNo);
        formData.append("uploadpancard1", uploadpancard1);
        formData.append("Address", Address);
        formData.append("uploadphoto1", uploadphoto1);
 
        axios
          .put(`https://api.chitgenius.com/api/update/${UserId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            setState({
              UserName: "",
              userpassword: "",
              UserDob: "",
              mobileNo: "",
              email: "",
              gender: "",
              occupation: "",
              createdbyid: "",
              createdbyrole: "",
              role: "",
              adhaarNo: "",
              uploadadhaarcard1: {},
              panNo: "",
              uploadpancard1: {},
              Address: "",
              uploadphoto1: {},
            });
            toast.success('Member Updated Successfully');
 
          })
          .catch((err) => toast.error(err.response.data));
        // alert.success("Member Updated Successfully");
      }
    }
  };
 
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
 
  return (
    <div style={{ display: "flex", overflow: "hidden" }}>
      <div>
        <Userside />
      </div>
      <div className="user-totalm">
        <div className="user-AI">
          <div className="h">
            <div className="">
              <NavLink className="" to={"/uprofile"}>
                <FaArrowLeftLong className="ume" />
              </NavLink>
            </div>
            <div className="umedash-heading">
              <h2>Member Entry</h2>
            </div>
          </div>
 
          <div className="image">
            <div>
              <img
                src={
                  user.uploadPhoto
                    ? `https://api.chitgenius.com/uploads/${user.uploadPhoto}`
                    : userimage
                }
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                // style={{ height: "50px", borderRadius: "30px", width: "50px" }}
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
 
        <hr style={{ margin: "10px 0px" }}></hr>
        <form className="info-container" onSubmit={handleSubmit}>
          <div className="user-display-full-0">
            <div className="user-ab">
              <div className="user-ab-1">
                <div className="user-display-0">
                  <lable htmlFor="UserName">
                    <strong>Name</strong>
                  </lable>
                  <input
                    type="text"
                    id="UserName"
                    name="UserName"
                    placeholder="enter your name"
                    className="user-input-0"
                    value={UserName || ""}
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="user-display-0">
                  <lable htmlFor="userpassword">
                    <strong>Password</strong>
                  </lable>
                  <input
                    type="text"
                    id="userpassword"
                    name="userpassword"
                    placeholder="enter your password"
                    className="user-input-0"
                    value={userpassword || ""}
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="user-display-0">
                  <lable htmlFor="User Dob">
                    <strong>Date-Of-Birth</strong>
                  </lable>
                  <input
                    type="date"
                    id="UserDob"
                    name="UserDob"
                    className="user-input-0"
                    value={UserDob || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
 
              <div className="user-ab-1">
                <div className="user-display-0">
                  <lable htmlFor="mobileNo">
                    <strong>Mobile Number</strong>
                  </lable>
                  <input
                    type="number"
                    id="mobileNo"
                    name="mobileNo"
                    placeholder="enter your number"
                    className="user-input-0"
                    value={mobileNo || ""}
                    onChange={handleInputChange}
                    readOnly={!!UserId}
                  />
                </div>
 
                <div className="user-display-0">
                  <lable htmlFor="email">
                    <strong>Email</strong>
                  </lable>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="enter your mail id"
                    className="user-input-0"
                    value={email || ""}
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="user-display-0">
                  <lable htmlFor="gender">
                    <strong>Gender</strong>
                  </lable>
                  <input
                    type="text"
                    id="gender"
                    name="gender"
                    className="user-input-0"
                    placeholder="enter your gender"
                    value={gender || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
 
              <div className="user-ab-1">
                <div className="user-display-0">
                  <lable htmlFor="occupation">
                    <strong>Occupation</strong>
                  </lable>
                  <input
                    type="text"
                    id="occupation"
                    className="user-input-0"
                    name="occupation"
                    placeholder="enter your occupation"
                    value={occupation || ""}
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="user-display-0">
                  <lable htmlFor="adhaarNo">
                    <strong>Adhaar Number</strong>
                  </lable>
                  <input
                    type="text"
                    id="adhaarNo"
                    name="adhaarNo"
                    placeholder="enter your adhaar number"
                    className="user-input-0"
                    value={adhaarNo || ""}
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="user-display-0">
                  <lable htmlFor="panNo">
                    <strong>Pan Number</strong>
                  </lable>
                  <input
                    type="text"
                    id="panNo"
                    name="panNo"
                    placeholder="enter your pan number"
                    className="user-input-0"
                    value={panNo || ""}
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="user-display-0" style={{ visibility: "hidden" }}>
                  <lable htmlFor="role">
                    <strong>Role</strong>
                  </lable>
                  <select
                    type="text"
                    id="role"
                    name="role"
                    placeholder="enter your role"
                    className="user-input-0"
                    value={role || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">select</option>
                    <option value="Admin">Admin</option>
                    <option value="Agent">Agent</option>
                    <option value="user">user</option>
                    <option value="collectionAgent">collectionAgent</option>
                  </select>
                </div>
              </div>
 
              <div className="user-ab-1">
                <div className="user-display-0">
                  <lable htmlFor="uploadPhoto">
                    <strong>Upload Photo</strong>
                  </lable>
                  <input
                    type="file"
                    id="uploadPhoto"
                    name="uploadPhoto"
                    className="user-input-0"
                    // className='upload'
                    //   value={uploadpancard || ""}
                    onChange={(e) => {
                      setState({ ...state, uploadphoto1: e.target.files[0] });
                    }}
                  />
                </div>
 
                <div className="user-display-0">
                  <lable htmlFor="uploadadhaarcard">
                    <strong>Upload Adhaar</strong>
                  </lable>
                  <input
                    type="file"
                    id="uploadadhaarcard"
                    name="uploadadhaarcard"
                    className="user-input-0"
                    // className='upload'
                    //   value={uploadadhaarcard || ""}
                    onChange={(e) => {
                      setState({
                        ...state,
                        uploadadhaarcard1: e.target.files[0],
                      });
                    }}
                  />
                </div>
 
                <div className="user-display-0">
                  <lable htmlFor="uploadpancard">
                    <strong>Upload Pan Card</strong>
                  </lable>
                  <input
                    type="file"
                    id="uploadpancard"
                    name="uploadpancard"
                    className="user-input-0"
                    // className='upload'
                    //   value={uploadpancard || ""}
                    onChange={(e) => {
                      setState({ ...state, uploadpancard1: e.target.files[0] });
                    }}
                  />
                </div>
              </div>
 
              <div className="user-ab-1">
                <div className="user-display-0">
                  <lable htmlFor="Address">
                    <strong>Address</strong>
                  </lable>
                  <input
                    type="text"
                    id="Address"
                    name="Address"
                    placeholder="enter your address"
                    className="user-input-0"
                    value={Address || ""}
                    onChange={handleInputChange}
                  />
                </div>
 
                <div className="user-display-0" style={{ visibility: "hidden" }}>
                  <lable htmlFor="gender">
                    <strong>Createdbyrole</strong>
                  </lable>
                  <input
                    type="text"
                    id="createdbyrole"
                    name="createdbyrole"
                    className="user-input-0"
                    placeholder="enter your gender"
                    value={user.role}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
 
              <div>
                <div className="user-display-0" style={{ visibility: "hidden" }}>
                  <lable htmlFor="gender">
                    <strong>CreatedbyId</strong>
                  </lable>
                  <input
                    type="text"
                    id="createdbyid"
                    name="createdbyid"
                    className="user-input-0"
                    placeholder="enter your gender"
                    value={user.UserId}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
 
              <div className="user-ubutton">
                <input
                  type="submit"
                  className="user-usavebutton"
                  value={UserId ? "Update" : "Save"}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
 
    </div>
  );
};
 
export default Usermember;
 