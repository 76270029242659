import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import './AgentProfile.css'; 
import AgentSide from "../agentcompo/AgentSide";
import { AgentContext } from "../context/agentcontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import userimage from "./images/userimage.png"

const AgentProfile = () => {
  const usernamedashboard = localStorage.getItem("username");
  const { agentname } = useContext(AgentContext);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState(""); 
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/ap") {
        navigate("/ap");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/agent") {
        navigate("/");
      }
      return;
    }

    setUsername(state.username);
    setRole(state.role);
  }, [location, navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setUser(response.data[0]);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    fetchUserData();
  }, [usernamedashboard]);

  return (
    <div className="agent-profile">
      <div>
        <AgentSide />
      </div>
      <div className="agent-profile-content">
        <div className="agent-profile-info">
          <div className="agent-profile-heading">
            <h2>Agent Profile</h2>
          </div>
          <hr />
          <div className='AD-TEXT'>
          <p>Complete Your Profile Here <Link to={`/agentedit/profilememberentry/${user.UserId}`} className='AD-edit'>Edit</Link></p>
          </div>
          <div className="agent-profile-body">
            <div className='agent-profile-text'>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt="Profile Pic"
                className="agent-profile-avatar"
              />
              {/* <p>Complete Your Profile Here <Link to={`/agentedit/profilememberentry/${user.UserId}`} className='agent-profile-edit-link'>Edit</Link></p> */}
            </div>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.4 }}
              className="agent-profile-main"
            >
              <div className="agent-profile-column">
                <div className="agent-profile-details">
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">User Id</div>
                    <div className="agent-profile-input">{user.UserId}</div>
                  </div>
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">User Name</div>
                    <div className="agent-profile-input">{user.UserName}</div>
                  </div>
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">User Password</div>
                    <div className="agent-profile-input">{user.userpassword}</div>
                  </div>
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">Date Of Birth</div>
                    <div className="agent-profile-input">{user.UserDob}</div>
                  </div>
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">Mobile No</div>
                    <div className="agent-profile-input">{user.mobileNo}</div>
                  </div>
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">Email</div>
                    <div className="agent-profile-input">{user.email}</div>
                  </div>
                </div>
              </div>
              <div className="agent-profile-column">
                <div className="agent-profile-details">
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">Gender</div>
                    <div className="agent-profile-input">{user.gender}</div>
                  </div>
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">Occupation</div>
                    <div className="agent-profile-input">{user.occupation}</div>
                  </div>
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">Role</div>
                    <div className="agent-profile-input">{user.role}</div>
                  </div>
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">Adhaar No</div>
                    <div className="agent-profile-input">{user.adhaarNo}</div>
                  </div>
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">Pan No</div>
                    <div className="agent-profile-input">{user.panNo}</div>
                  </div>
                  <div className="agent-profile-row">
                    <div className="agent-profile-label">Address</div>
                    <div className="agent-profile-input">{user.Address}</div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentProfile;
