import React, { createContext, useState } from "react";

export const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [customername, setCustomername] = useState("");

  return (
    <CustomerContext.Provider value={{ customername, setCustomername }}>
      {children}
    </CustomerContext.Provider>
  );
};