import React from 'react'
import AgentSide from '../agentcompo/AgentSide'

const Agentcomm = () => {
  return (
    <div style={{display:'flex'}}>
    <div>
        <AgentSide></AgentSide>
    </div>
    <div>Agentcommunication</div>
    </div>
  )
}

export default Agentcomm