import React, { createContext, useState } from "react";

export const CollectionagentContext = createContext();

export const CollectionagentProvider = ({ children }) => {
  const [collectionagentname, setCollectionagentname] = useState("");

  return (
    <CollectionagentContext.Provider value={{ collectionagentname, setCollectionagentname }}>
      {children}
    </CollectionagentContext.Provider>
  );
};