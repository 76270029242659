import React from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useEffect, useState, useContext } from "react";
import AgentSide from "../agentcompo/AgentSide";
import "./Payoutdetails.css";
import { useNavigate } from "react-router-dom";
import { AgentContext } from "../context/agentcontext";
import userimage from "./images/userimage.png"

const AgentAllpayout = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const { groupId } = useParams(); // Extract the groupId from the URL path
  const { paymentId } = useParams();
  const history = useNavigate();
  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});

  const dltgroupuser = async (paymentId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const res = await axios.delete(
          `https://api.chitgenius.com/api/delete/payoutdataid/${paymentId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.status === 201) {
          toast.success("Successfully deleted!");
          fetchData();
        } else {
          console.log("Error deleting data");
        }
      } catch (error) {
        console.error("There was an error deleting the payout data", error);
        toast.error("There was an error deleting the data.");
      }
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.chitgenius.com/agent/ap/api/bidingdetailsbybidingId/${groupId}`
      ); // Use the groupId from the URL path
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 useEffect(() => {
    fetchData();
}, [groupId]);
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  return (
    <div style={{ display: "flex" }}>
      <div>
        <AgentSide />
      </div>
      <div  className="C-ttd">
        <div className="courses1-head">
          <div className="h">
            <div className="">
              <NavLink className="" to={"/agentchits"}>
                <FaArrowLeftLong className="Agent-Arrow" />
              </NavLink>
            </div>
            <div className="dash-heading">
              <h2>Payout Details</h2>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" ,marginRight:"30px" }}>
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
</div>
        <hr></hr>
        <div className="">
          <div className="p1-table-auction">
            <table className="p1-tables-style">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>No.</th>
                  {/* <th style={{ textAlign: "center" }}>groupId</th> */}
                  <th className="tahe" style={{ textAlign: "center" }}>GroupId</th>
                  <th className="tahe" style={{ textAlign: "center" }}>GroupName</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Month</th>
                  <th className="tahe" style={{ textAlign: "center" }}>InstallmentNumber</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Paytowinner</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Toal Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>paid Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Payment mode</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Balance</th>
                  {/* <th style={{ textAlign: "center" }}>commission</th> */}
                  <th className="tahe" style={{ textAlign: "center" }}>Payment Date</th>

                  <th className="tahe" style={{ textAlign: "center" }}>ACTION</th>
                </tr>
              </thead>

              {data.length > 0 ? (
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{item.idgroup}</td>
                      <td style={{ textAlign: "center" }}>{item.namegroup}</td>
                      <td style={{ textAlign: "center" }}>{item.month}</td>
                      <td style={{ textAlign: "center" }}>{item.insNum}</td>
                      <td style={{ textAlign: "center" }}>{item.bidwinner}</td>
                      <td style={{ textAlign: "center" }}>{item.tap}</td>
                      <td style={{ textAlign: "center" }}>{item.hap}</td>
                      <td style={{ textAlign: "center" }}>{item.paymentMode}</td>
                      <td style={{ textAlign: "center" }}>{item.Balance}</td>
                      {/* <td>{item.commission_k}</td> */}
                      <td style={{ textAlign: "center" }}>{moment(item.paymentDate).format("DD-MM-YYYY")}</td>
                      <td style={{ textAlign: "center" }}>
                        <div className="gtf">
                          <div></div>
                          <div>
                            <button
                              onClick={() => dltgroupuser(item.paymentId)}
                              className="ch"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      payout not done for this ID
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default AgentAllpayout;
