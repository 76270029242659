import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
// import SideBar from "../components/Sidebar/SideBar";
import Respside from "./Respside"
import moment from "moment";
import { NavLink, Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { ReceiptionContext } from "../context/receiptioncontext";
import {useNavigate,useLocation} from "react-router-dom"
import Cookies from 'js-cookie';
import '../receiption/respbiddingdetails.css';
import userimage from "./images/userimage.png"
 


const RespBiddingdetails = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const { receiptionname } = useContext(ReceiptionContext);
  const [user, setuser] = useState({});

   

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
      const token = Cookies.get('token');
      const { state } = location;

      if (token) {
         
          if (location.pathname !== '/respbiddingdetails') {
              navigate('/respbiddingdetails');
          }
          return;
      }

      if ( !state || !state.username || !state.role) {
        if (location.pathname !== '/receiptionist') {
          navigate('/');
        }
        return;
      }
     
          setUsername(state.username);
          setRole(state.role);
          console.log(state.username, state.role);
         
    }, [location, navigate]);


 
  const loadData = async () => {
    const response = await axios.get("https://api.chitgenius.com/chit/allbidingdata/get/receiption");
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, []);



  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);



 
  const dltbid = async (bidingId) => {
    console.log(bidingId);
    const res = await axios.delete(
      `https://api.chitgenius.com/api/delete/bid/${bidingId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.status == 201) {
      // console.log("success")
      // getAssessmentData()
      //  setShow(true)
      navigate("/moreinfo");
    } else {
      console.log("error");
    }
  };
  return (
    <div className="Resp-bidingdetails-main" style={{ display: "flex" }}>
      <div>
        <Respside />
      </div>
      <div className="Resp-bidingdetails-full">
        <div className="Resp-bidingdetails-head">
          
          <div className="Resp-bidingdetails-heading">
            <h2>Biding Details</h2>
          </div>
          <div className="image">
              <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
              </div>
              <div>
              <p style={{margin:'0px'}}>{user.UserName}</p>
              <p style={{margin:'0px'}}>({user.role})</p>
              </div>
              </div>
        </div>
        <hr />
 
        <div className="Resp-searchContainer">
          <input
            type="search"
            placeholder="Search by Name"
            className="Resp-quizSearch"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
 
        <div className="">
          <div className="Resp-table-auctiondata">
            <table className="Resp-table-style-Gu">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center" }}>No.</th>
                  <th className="tahe" style={{ textAlign: "center" }}>groupId</th>
                  <th className="tahe" style={{ textAlign: "center" }}>chit Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>No.of users</th>
                  <th className="tahe" style={{ textAlign: "center" }}>installment Number</th>
                  <th className="tahe" style={{ textAlign: "center" }}>commission</th>
                  <th className="tahe" style={{ textAlign: "center" }}>bid Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>bid Winner</th>
                  <th className="tahe" style={{ textAlign: "center" }}>
                    collecting from subcriber
                  </th>
                  <th className="tahe" style={{ textAlign: "center" }}>pay to Winner</th>
                  <th className="tahe" style={{ textAlign: "center" }}>AuctionId</th>
                  <th className="tahe" style={{ textAlign: "center" }}>date</th>
                  {/* <th style={{ textAlign: "center" }}>ACTION</th> */}
                </tr>
              </thead>
              <tbody>
                {data
                  .filter(
                    (data) =>
                      data.bid_winner
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      //data.id_group === parseInt(search)
                      data.id_group.toString() === search.trim() ||
                      data.id_auction === parseInt(search)
                  )
                  .map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.id_group}</td>
                        <td>{item.amount_chit}</td>
                        <td>{item.user_num}</td>
                        <td>{item.install_numb}</td>
                        <td>{item.comision}</td>
 
                        <td>{item.amount_bid}</td>
 
                        <td>{item.bid_winner}</td>
 
                        <td>{item.payoutsub}</td>
                        <td>{item.payinsub}</td>
                        <td>{item.id_auction}</td>
 
                        <td>{moment(item.date).format("DD-MM-YYYY")}</td>
                        {/* <td>
                          <button
                            onClick={() => dltbid(item.bidingId)}
                            className="ch"
                          >
                            Delete
                          </button>
                          <Link to={`/Payout/${item.bidingId}`}>
                            <button className="ch">Payout</button>
                          </Link>
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default RespBiddingdetails;