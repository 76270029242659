import React, { useState, useEffect, useContext } from "react";
import "./dashboard.css";
import SideBar from "../components/Sidebar/SideBar";
import axios from "axios";
// import { Chartdata } from "./ChartData";
import { motion } from "framer-motion";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import "react-calendar/dist/Calendar.css";
import { UserContext } from "../context/usecontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import userimage from "./images/userimage.png"

const Dashboard = () => {
  const [search, setSearch] = useState("");
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const [Activedata, setActiveData] = useState([]);
  const [InActivedata, setInActiveData] = useState([]);
  const [Chartdata, setChartdata] = useState([]);
  const [userChartdata, setUserChartdata] = useState([]);
  const [paymentChartdata, setPaymentChartdata] = useState([]);
  const { username } = useContext(UserContext);
  
  const [user, setuser] = useState({});
  const [countchitdata, setCountChitData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/dash") {
        navigate("/dash");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
      return;
    }

    setUsersname(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  const [date, setDate] = useState(new Date());
  const onChange = () => {
    setDate(date);
  };

  const fetchData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/payout/details"
    );
    setChartdata(response.data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchUserData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/user/payment/details"
    );
    setUserChartdata(response.data);
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchPaymentData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/payout/payment/details"
    );
    setPaymentChartdata(response.data);
  };
  useEffect(() => {
    fetchPaymentData();
  }, []);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const logincountuserid = user.UserId;

  const loadcountchitData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/count/dash/chit/admin/${logincountuserid}`
    );
    setCountChitData(response.data);
  };
  useEffect(() => {
    loadcountchitData();
  }, [logincountuserid]);

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/member/count/Admin/chits/${logincountuserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [logincountuserid]);

  const loadDataInActiveChit = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chitsparticular/count/Admin/InActive/${logincountuserid}`
    );
    setInActiveData(response.data);
  };
  useEffect(() => {
    loadDataInActiveChit();
  }, [logincountuserid]);

  const loadDataActiveChit = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chitsparticular/count/Admin/Active/${logincountuserid}`
    );
    setActiveData(response.data);
  };
  useEffect(() => {
    loadDataActiveChit();
  }, [logincountuserid]);

  return (
    <div className="Admin-dashboard-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>

      <div className="Admin-dashboard-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className=""
        >
          <div className="Ad-dashboard-head">
            <div className="Ad-dashboard-heading">
              <h2>Dashboard</h2>
            </div>

            <div className="image">
              <div>
                <img
                  src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
              </div>

              {/* <p>Role: {role}</p> */}
            </div>
          </div>
          <hr />
         

          <div className="Ad-dashboard-cards-1">
            <div className="Ad-dashboard-card red">
              <p className="Ad-dashboard-tip">NO OF MEMBERS</p>
              {data.map((opts, i) => (
                <option key={i} value={opts.Membercount}>
                  {opts.Membercount}
                </option>
              ))}
            </div>
            <div className="Ad-dashboard-card blue">
              <p className="Ad-dashboard-tip">TOTAL CHITS</p>
              {countchitdata.map((opts, i) => (
                <option key={i} value={opts.chitcount}>
                  {opts.chitcount}
                </option>
              ))}
            </div>
            <div className="Ad-dashboard-card green">
              <p className="Ad-dashboard-tip">ACTIVE CHITS</p>
              {Activedata.map((opts, i) => (
                <option key={i} value={opts.chitActivecount}>
                  {opts.chitActivecount}
                </option>
              ))}
            </div>
            <div className="Ad-dashboard-card purple">
              <p className="Ad-dashboard-tip">INACTIVE CHITS</p>
              {InActivedata.map((opts, i) => (
                <option key={i} value={opts.chitInActivecount}>
                  {opts.chitInActivecount}
                </option>
              ))}
            </div>
          </div>

          <div className="Ad-dashboard-second-part">
            <div className="Ad-dashboard-chartdata first">
              <h4>Payout Chart</h4>
              <div className="Ad-dashboard-container-chart">
                <BarChart width={350} height={350} data={Chartdata}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis interval={25000} tickCount={5} domain={[0, 100000]} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="TP" fill="#8884d8" barSize={20} />
                  <Bar dataKey="HP" fill="#82ca9d" barSize={20} />
                </BarChart>
              </div>
            </div>
            
            <div className="Ad-dashboard-chartdata">
              <h4>Payment collection Chart</h4>
              <div className="Ad-dashboard-container-chart">
                <BarChart width={350} height={350} data={userChartdata}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="payment_month" />
                  <YAxis interval={25000} tickCount={5} domain={[0, 100000]} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="TP" fill="#8884d8" barSize={20} />
                  <Bar dataKey="HP" fill="#82ca9d" barSize={20} />
                </BarChart>
              </div>
            </div>
            <div className="Ad-dashboard-chartdata">
              <h4>Transaction Chart</h4>
              <div className="Ad-dashboard-container-chart">
                <BarChart width={350} height={350} data={paymentChartdata}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis interval={10000} tickCount={5} domain={[0, 100000]} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="payout_HP" fill="#8884d8" barSize={20} />
                  <Bar dataKey="payment_HP" fill="#82ca9d" barSize={20} />
                </BarChart>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      {/* <div>
      <ProfileAdmin username={username} />
      </div> */}
    </div>
  );
};

export default Dashboard;
