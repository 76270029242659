import React, { useState } from 'react';
import numWords from 'num-words'; // Library to convert numbers to words

function NumberToWordsConverter() {
  const [number, setNumber] = useState('');
  const [numberWords, setNumberWords] = useState('');

  const handleNumberChange = (e) => {
    const inputNumber = e.target.value.trim();
    if (!isNaN(inputNumber)) {
      // Check if input is a valid number
      setNumber(inputNumber);
      setNumberWords(numWords(inputNumber));
    } else {
      // Clear the number and words if input is not a valid number
      setNumber('');
      setNumberWords('');
    }
  };

  return (
    <div>
      <input
        type="text"
        value={number}
        onChange={handleNumberChange}
        placeholder="Enter a number"
      />
      <div>Number: {number}</div>
      <div>Number in words: {numberWords}</div>
    </div>
  );
}

export default NumberToWordsConverter;