import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaSquarePhone } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import Swal from "sweetalert2"; // Import SweetAlert2
 
 
const initialState = {
  UserName: "",
  role: "user",
  userpassword: "",
  UserDob: "",
  mobileNo: "",
  email: "",
  gender: "",
  createdbyrole: "user",
};
 
const Registrationpage = ({ handleSignInMode }) => {
  const [state, setState] = useState(initialState);
  const [error, setError] = useState("");
  const {
    UserName,
    role,
    userpassword,
    UserDob,
    mobileNo,
    email,
    gender,
    createdbyrole,
  } = state;
  const navigate = useNavigate();
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
 
    const mobileRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
 
    if (!UserName || !UserDob || !mobileNo || !email || !gender || !userpassword ||
      !mobileRegex.test(mobileNo) || !emailRegex.test(email) || !passwordRegex.test(userpassword)) {
      let errorMessage = "";
      if (!UserName) errorMessage += "Please fill the Username.\n";
      if (!UserDob) errorMessage += "Please fill the Date of Birth.\n";
      if (!mobileNo) errorMessage += "Please fill the Mobile Number.\n";
      if (!email) errorMessage += "Please fill the Email.\n";
      if (!gender) errorMessage += "Please fill the Gender.\n";
      if (!userpassword) errorMessage += "Please fill the Password.\n";
      if (mobileNo && !mobileRegex.test(mobileNo)) errorMessage += "Mobile number should be exactly 10 digits.\n";
      if (email && !emailRegex.test(email)) errorMessage += "Please enter a valid email address.\n";
      if (userpassword && !passwordRegex.test(userpassword)) errorMessage += "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.\n";
      alert(errorMessage);
      return;
    }
 
    try {
      await axios.post("https://api.chitgenius.com/api/post/memberegister/signup", {
        UserName,
        role,
        userpassword,
        UserDob,
        mobileNo,
        email,
        gender,
        createdbyrole,
      });
 
      Swal.fire({
        title: "Success!",
        text: "Sign up successfully! Please Signin",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        setState(initialState);
        // navigate("/");
      });
 
      // setState(initialState);
      // navigate("/");
    } catch (err) {
      if (err.response && err.response.data) {
        alert(err.response.data);
      } else {
        alert("An unexpected error occurred. Please try again later.");
      }
    }
  };
 
 
  return (
    <div className="signup__heading">
      <div className="ss-container">
        <form
          className="sign-up-form"
          onSubmit={handleSubmit}
          // style={{ border: "1px solid black" }}
        >
          <h2 className="title">Sign up</h2>
 
          {error && <div className="error-message">{error}</div>}
 
          <div>
            <div className="signup__div">
              <div className="signup__field">
                {/* <label>Username</label> */}
                <i class="login__icon fa FaUser">
                  <FaUser />
                </i>
                <input
                  type="text"
                  id="UserName"
                  name="UserName"
                  placeholder="User Name"
                  className="signup__input"
                  value={UserName}
                  onChange={handleInputChange}
                ></input>
              </div>
 
              <div className="signup__field">
                {/* <label>Password</label> */}
                <i class="login__icon fa FaLock">
                  <FaLock />
                </i>
                <input
                  type="password"
                  id="userpassword"
                  name="userpassword"
                  placeholder="Enter your password"
                  className="signup__input"
                  value={userpassword}
                  onChange={handleInputChange}
                />
              </div>
            </div>
 
            <div className="signup__div">
              <div className="signup__field">
                {/* <label>mobileNumber</label> */}
                <i class="login__icon fa6 FaSquarePhone">
                  <FaSquarePhone />
                </i>
                <input
                  type="number"
                  id="mobileNo"
                  name="mobileNo"
                  placeholder="Enter mobile number"
                  className="signup__input"
                  value={mobileNo || ""}
                  onChange={handleInputChange}
                />
              </div>
 
              <div className="signup__field">
                {/* <label>Email</label> */}
                <i class="login__icon io5 IoMail">
                  <IoMail />
                </i>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your mail id"
                  className="signup__input"
                  value={email || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
 
            <div className="signup__div">
              <div className="signup__field">
                {/* <label>Date-of-birth</label> */}
                <input
                  type="date"
                  id="UserDob"
                  placeholder="enter date of birth"
                  name="UserDob"
                  className="signup__input"
                  value={UserDob || ""}
                  onChange={handleInputChange}
                />
                {/* <label className="signup-dob">date-of-birth</label> */}
              </div>
 
              <div className="signup__field">
                {/* <label>Gender</label> */}
                <i class="login__icon fa FaUser">
                  <FaUser />
                </i>
                <select
                  type="text"
                  id="gender"
                  name="gender"
                  className="signup__input"
                  value={gender || ""}
                  onChange={handleInputChange}
                >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>  
 
                </select>
              </div>
            </div>
 
            <div style={{ visibility: "hidden" }}>
              <label>Role</label>
              <input
                type="text"
                id="role"
                name="role"
                className="ss-input-0"
                value={role}
                onChange={handleInputChange}
              ></input>
            </div>
 
            <div style={{ visibility: "hidden" }}>
              <label>createdbyrole</label>
              <input
                type="text"
                id="createdbyrole"
                name="createdbyrole"
                className="ss-input-0"
                value={createdbyrole}
              ></input>
            </div>
          </div>
 
          <button className="button signup__submit">
            <input type="submit" className="save__button" value="Sign Up Now" />
            <i class="button__icon fas fa-chevron-right">
              <FaChevronRight />
            </i>
          </button>
 
      
 
          <p className="social__text">
            If you have account ?{" "}
            <button className="signin__button" style={{ background:"none" }} onClick={handleSignInMode}>
              Sign in
            </button>
          </p>
           <div>
           <p className="signin__hompage">Go to <Link to="/">Homepage</Link></p>
 
           </div>
        </form>
 
      </div>
    </div>
  );
};
 
export default Registrationpage;