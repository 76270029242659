import React, { useState } from 'react';
import '../pages/Style.css';
import { Loginpage } from "../pages/loginpage";
import Registrationpage from "../pages/Registrationpage";



function Parentcomponent() {
  const [isSignUp, setIsSignUp] = useState(false);

  const handleSignUpMode = () => {
    setIsSignUp(true);
  };

  const handleSignInMode = () => {
    setIsSignUp(false);
  };

  return (
    <div className={`pp-container ${isSignUp ? 'sign-up-mode' : ''}`}>
      <div className="pp-forms-container">
        <div className="signin-signup">
          {!isSignUp ? (
            <Loginpage handleSignUpMode={handleSignUpMode} />
          ) : (
            <Registrationpage handleSignInMode={handleSignInMode} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Parentcomponent;
