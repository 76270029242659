import React, { useState, useEffect, useContext } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { motion } from "framer-motion";
import axios from "axios";
import Select from "react-select";
import AgentSide from "../agentcompo/AgentSide";
import "./Acution.css";
import userimage from "./images/userimage.png"
import { AgentContext } from "../context/agentcontext";

import Swal from 'sweetalert2';  // Import SweetAlert2
 
 
const initialState = {
  group_id: "",
  group_name: "",
  chit_amount: "",
  commission_k: "",
  auctionDate: "",
  amountdueDate: "",
  InstallmentNumber: "",
  nextauctionDate: "",
  bidAmount: "",
  bidderName: "",
  noofusers: "",
  data: [],
};
 
const AgentAuction = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [state, setState] = useState(initialState);
  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});
 
  const {
    group_id,
    group_name,
    chit_amount,
    commission_k,
    auctionDate,
    amountdueDate,
    InstallmentNumber,
    nextauctionDate,
    bidAmount,
    bidderName,
    noofusers,
  } = state;
 
  const [data, setData] = useState([]);
 
  const { groupId, groupName, chitAmount, commission, noOfUsers } = useParams();
  console.log(groupId);
 
  const navigate = useNavigate();
 
  const { auctionId } = useParams();
 
  useEffect(() => {
    axios
      .get(`https://api.chitgenius.com/api/getauctionid/${auctionId}`)
      .then((resp) =>
        setState((prevState) => ({ ...prevState, ...resp.data[0] }))
      );
  }, [auctionId]);
 
  const loadData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/api/getall/biddername"
    );
    setData(response.data);
  };
 
  useEffect(() => {
    setState({
      ...state,
      group_id: groupId,
      group_name: groupName,
      chit_amount: chitAmount,
      commission_k: commission,
      noofusers: noOfUsers,
    });
    loadData();
 
 
    axios.get(`https://api.chitgenius.com/api/count/no.ofinstallments/${groupId}`)
    .then((response) => {
      const { count, message } = response.data;
      if (message) {
        Swal.fire({
          title: 'Info',
          text: message,
          icon: 'info',
          confirmButtonText: 'OK'
        });
      }
      setState(prevState => ({
        ...prevState,
        InstallmentNumber: count
      }));
    })
    .catch((error) => {
      console.error("Error fetching auction count", error);
    });
  }, [groupId, groupName, chitAmount, commission, noOfUsers]);
 
  const isValidDate = (date) => {
    const today = new Date();
    const inputDate = new Date(date);
    return (
      inputDate.getFullYear() > today.getFullYear() ||
      (inputDate.getFullYear() === today.getFullYear() && inputDate.getMonth() >= today.getMonth())
    );
  };
 
 
  const isNextAuctionDateValid = (nextDate, auctionDate) => {
    const next = new Date(nextDate);
    const auction = new Date(auctionDate);
 
    return (
      next.getFullYear() > auction.getFullYear() ||
      (next.getFullYear() === auction.getFullYear() && next.getMonth() > auction.getMonth())
    );
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!auctionDate || !amountdueDate || !InstallmentNumber || !nextauctionDate || !bidAmount) {
      Swal.fire({
        title: 'Error',
        text: 'Please fill all the fields',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
   
    if (!isValidDate(auctionDate)) {
      Swal.fire({
        title: 'Error',
        text: ' "Auction date" should be the current month or an upcoming month.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
 
    if (!isValidDate(amountdueDate)) {
      Swal.fire({
        title: 'Error',
        text: ' "Amount due date"should be the current month or an upcoming month.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
 
    if (!isNextAuctionDateValid(nextauctionDate, auctionDate)) {
      Swal.fire({
        title: 'Error',
        text: '"Next auction date" should be in the month after the auction date or in an upcoming month.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }
 
 
 
    if (!auctionId) {
      axios
        .post("https://api.chitgenius.com/api/add/auction/post", {
          group_id,
          group_name,
          chit_amount,
          commission_k,
          auctionDate,
          amountdueDate,
          InstallmentNumber,
          nextauctionDate,
          bidAmount,
          bidderName: iduser,
          noofusers,
        })
        .then(() => {
          setState({
            group_id: "",
            group_name: "",
            chit_amount: "",
            commission_k: "",
            auctionDate: "",
            amountdueDate: "",
            InstallmentNumber: "",
            nextauctionDate: "",
            bidAmount: "",
            bidderName: "",
            noofusers: "",
          });
          Swal.fire({
            title: 'Success',
            text: '"Auction data" saved successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          });
        })
        .catch((err) => Swal.fire({
          title: 'Error',
          text: err.response.data,
          icon: 'error',
          confirmButtonText: 'OK',
        }));
        // .catch((err) => alert.error(err.response.data));
    } else {
      axios
        .put(`https://api.chitgenius.com/update/auctiondata/api/${auctionId}`, {
          group_id,
          group_name,
          chit_amount,
          commission_k,
          auctionDate,
          amountdueDate,
          InstallmentNumber,
          nextauctionDate,
          bidAmount,
          bidderName: iduser,
          noofusers,
        })
        .then(() => {
          setState({
            group_id: "",
            group_name: "",
            chit_amount: "",
            commission_k: "",
            auctionDate: "",
            amountdueDate: "",
            InstallmentNumber: "",
            nextauctionDate: "",
            bidAmount: "",
            bidderName: "",
            noofusers: "",
          });
          Swal.fire({
            title: 'Success',
            text: '"Auction data" updated successfully',
            icon: 'success',
            confirmButtonText: 'OK',
          });
        })
        .catch((err) => Swal.fire({
          title: 'Error',
          text: err.response.data,
          icon: 'error',
          confirmButtonText: 'OK',
        }));
        // .catch((err) => alert.error(err.response.data));
    }
   
    setTimeout(() => {
      navigate("/agentchits");
    }, 500);
  };
 
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);
  const iduser = user.UserId;
 
  return (
    <div className="Agent-auction-main" style={{ display: "flex" }}>
      <div>
        <AgentSide />
      </div>
      <div className="Ag-auction-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className="C-courses1"
        >
          <div className="Ag-auction-head">
            <div className="Ad-h">
              <NavLink
                style={{ color: "black" }}
                to={"/agentchits"}
              >
                <FaArrowLeftLong className="Ad-ume"/>
              </NavLink>
              <div className="Ag-auction-heading">
                <h2>Auction</h2>
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px",marginRight:"10px" }}>
              <div className="image">
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  style={{
                    height: "50px",
                    borderRadius: "30px",
                    width: "50px",
                  }}
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
              </div>
            </div>
          </div>
 
          <hr style={{ margin: "10px 0px" }}></hr>
          <div className="Ag-auction-info-container">
            <form onSubmit={handleSubmit}>
              {/* <div className="hus">
                <input
                  type="submit"
                  value={auctionId ? "update" : "save"}
                  className="cdf"
                ></input>
              </div> */}
              <div className="Ag-auction-display-full-3">
                <div className="Ag-auction-getdata Ag-auction-mse">
                  <div className="Ag-auction-tr-1 Ag-auction-mse">
                    <div className="Ag-auction-display-33 Ag-auction-mse">
                      <div>
                        <label className="Ag-auction-mag">GroupId</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="group_id"
                          class="Ag-auction-input-33 Ag-auction-mse"
                          value={group_id || ""}
                          placeholder="Group Id:"
                          onChange={handleInputChange}
                          disabled={auctionId ? true : false} // Disable if editing
                        />
                      </div>
                    </div>
                    <div className="Ag-auction-display-33 Ag-auction-mse">
                      <div>
                        <label className="Ag-auction-mag">No of Users</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="noofusers"
                          class="Ag-auction-input-33 Ag-auction-mse"
                          value={noofusers || ""}
                          placeholder="Group Id:"
                          onChange={handleInputChange}
                          disabled={auctionId ? true : false} // Disable if editing
                        />
                      </div>
                    </div>
                    <div className="Ag-auction-display-33 Ag-auction-mse">
                      <div>
                        <label className="Ag-auction-mag">GroupName</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="group_name"
                          class="Ag-auction-input-33 Ag-auction-mse"
                          value={group_name || ""}
                          placeholder="Group Name:"
                          onChange={handleInputChange}
                          disabled={auctionId ? true : false} // Disable if editing
                        />
                      </div>
                    </div>
                  </div>
                  <div className="Ag-auction-trs Ag-auction-mse">
                    <div className="Ag-auction-display-33 Ag-auction-mse">
                      <div>
                        <label className="Ag-auction-mag">ChitAmount</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="chit_amount"
                          class="Ag-auction-input-33 Ag-auction-mse"
                          value={chit_amount || ""}
                          placeholder="Chit Amount:"
                          onChange={handleInputChange}
                          disabled={auctionId ? true : false} // Disable if editing
                        />
                      </div>
                    </div>
                    <div className="Ag-auction-display-33">
                      <div>
                        <label className="Ag-auction-mag">Commission</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="commission_k"
                          class="Ag-auction-input-33 Ag-auction-mse"
                          value={commission_k || ""}
                          placeholder="Chit Amount:"
                          onChange={handleInputChange}
                          disabled={auctionId ? true : false} // Disable if editing
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Ag-auction-tr">
                  <div className="Ag-auction-display-33">
                    <div>
                      <label className="Ag-auction-mag">AuctionDate</label>
                    </div>
                    <div>
                      <input
                        type="date"
                        name="auctionDate"
                        class="Ag-auction-input-33"
                        value={auctionDate || ""}
                        placeholder="Auction Date:"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="Ag-auction-display-33">
                    <div>
                      <label className="Ag-auction-mag">AmountDueDate</label>
                    </div>
                    <div>
                      <input
                        type="date"
                        name="amountdueDate"
                        class="Ag-auction-input-33"
                        value={amountdueDate || ""}
                        placeholder="AmountDueDate:"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="Ag-auction-display-33">
                    <div>
                      <label className="Ag-auction-mag">Installment Number</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="InstallmentNumber"
                        class="Ag-auction-input-33"
                        value={InstallmentNumber || ""}
                        placeholder="ex:1"
                        onChange={handleInputChange}
                        disabled

                      />
                    </div>
                  </div>
                </div>
                <div className="Ag-auction-tr">
                  <div className="Ag-auction-display-33">
                    <div>
                      <label className="Ag-auction-mag">Next Auction Date</label>
                    </div>
                    <div>
                      <input
                        type="date"
                        name="nextauctionDate"
                        class="Ag-auction-input-33"
                        value={nextauctionDate || ""}
                        placeholder="Next Auction Date:"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="Ag-auction-display-33">
                    <div>
                      <label className="Ag-auction-mag">Bid Amount</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="bidAmount"
                        class="Ag-auction-input-33"
                        value={bidAmount || ""}
                        placeholder="ex:10,000"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="Ag-auction-display-33">
                    <div>
                      <label className="Ag-auction-mag">Bidded by</label>
                    </div>
                    <div>
                      <input
                        name="bidderName"
                        className="Ag-auction-input-33"
                        value={iduser || ""}
                        onChange={handleInputChange}
                      >
                        
                      </input>
                    </div>
                  </div>
 
                  
 
                  
                  
                  
                </div>
              </div>
              <div className="Ad-auction-hus">
                <input
                  type="submit"
                  value={auctionId ? "update" : "save"}
                  className="Ad-auction-cdf"
                ></input>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
 
export default AgentAuction;