import React, { useState, useEffect, useContext } from "react";
import { NavLink, useParams, Link } from "react-router-dom";
import "./Agentmem.css";
// import  NavLink  from "react-router-dom";
// import './memberentry.css';
import axios from "axios";
import { FaArrowLeftLong } from "react-icons/fa6";
// import { toast } from "react-toastify";
// import SideBar from "../components/Sidebar/SideBar";
import AgentSide from "../agentcompo/AgentSide";
import { AgentContext } from "../context/agentcontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import userimage from "./images/userimage.png"


const initialState = {
  UserName: "",
  userpassword: "",
  UserDob: "",
  mobileNo: "",
  email: "",
  gender: "",
  occupation: "",
  role: "",
  createdbyid: "",
  createdbyrole: "",
  adhaarnumber: "",
  uploadadhaarcard1: {},
  pannumber: "",
  uploadpancard1: {},
  uploadphoto1: {},
  Address: "",
};

const Agentmemberentry = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [state, setState] = useState(initialState);
  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [roles, setRoles] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/agent/membertry") {
        navigate("/agent/membertry");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/agent") {
        navigate("/");
      }
      return;
    }

    setUsername(state.username);
    setRoles(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  const {
    UserName,
    userpassword,
    UserDob,
    mobileNo,
    email,
    gender,
    occupation,
    createdbyid,
    createdbyrole,
    role,
    adhaarNo,
    uploadadhaarcard1,
    panNo,
    uploadpancard1,
    Address,
    uploadphoto1,
  } = state;

  //  const history = useHistory();

  const { UserId } = useParams();

  useEffect(() => {
    axios
      .get(`https://api.chitgenius.com/api/get/${UserId}`)
      .then((resp) => setState({ ...resp.data[0] }));
  }, [UserId]);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const handleSubmit = async(e) => {
    e.preventDefault();

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const mobileRegex = /^\d{10}$/; // Regex pattern to check if mobile number is exactly 10 digits
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email should contain '@' and end with '.com'
    const adhaarRegex = /^\d{12}$/;
    const panRegex = /^[A-Z0-9]{10}$/;


    let missingFields = [];
 
    if (!UserName) missingFields.push("Name");
    if (!userpassword) missingFields.push("Password");
    if (!UserDob) missingFields.push("Date of Birth");
    if (!mobileNo) missingFields.push("Mobile Number");
    if (!email) missingFields.push("Email");
    if (!role) missingFields.push("Role");
 
    if (missingFields.length > 0) {
      alert(`Please provide values for the following fields: ${missingFields.join(", ")}`);
      return;
    }
 
    let validationErrors = [];
 
    if (!passwordRegex.test(userpassword)) validationErrors.push("Valid Password->Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character");
    if (!mobileRegex.test(mobileNo)) validationErrors.push("Valid Mobile Number (10 digits)");
    if (!emailRegex.test(email)) validationErrors.push("Valid Email");
    if (!adhaarRegex.test(adhaarNo)) validationErrors.push("Valid Adhaar Number (12 digits)");
    if (!panRegex.test(panNo)) validationErrors.push("Valid PAN Number (10 alphanumeric characters)");

 
    if (validationErrors.length > 0) {
      alert(`Please correct the following fields: ${validationErrors.join(", ")}`);
      return;
    }
 
    try {
      
      const mobileResponse = await axios.get(
        `https://api.chitgenius.com/api/check-mobile/${mobileNo}`
      );
      if (mobileResponse.data.exists) {
        alert("Mobile number already exists, please provide a different one.");
        return;
      }
 
      const emailResponse = await axios.get(
        `https://api.chitgenius.com/api/check-email/${email}`
      );
      if (emailResponse.data.exists) {
        alert("Email already in use, please provide a different one.");
        return;
      }

      const adhaarResponse = await axios.get(
        `https://api.chitgenius.com/api/check-adhaar/${adhaarNo}`
      );
      if (adhaarResponse.data.exists) {
        alert("Adhaar number already in use, please provide a different one.");
        return;
      }
     
      const panResponse = await axios.get(`https://api.chitgenius.com/api/check-panNo/${panNo}`);
      if (panResponse.data.exists) {
        alert("PAN number already in use, please provide a different one.");
        return;
      }
      
    } catch (error) {
      console.error("Error checking mobile number", error);
      alert("There was an error checking the mobile number. Please try again.");
      return;
    }

    if (
      !UserName ||
      !userpassword ||
      !UserDob ||
      !mobileNo ||
      !email ||
      !gender ||
      !occupation ||
      !role 
      // !adhaarNo ||
      //!uploadadhaarcard1 ||
      // !panNo ||
      //!uploadpancard1 ||
      // !Address ||
      //!uploadphoto1
    ) {
      let errorMessage = "";
      if (userpassword && !passwordRegex.test(userpassword))
        errorMessage +=
          "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.\n";
      alert(errorMessage);
      debugger;
      alert.error("Please provide a value for each input field");
    } else {
      if (!UserId) {
        const formData = new FormData();
        formData.append("UserName", UserName);
        formData.append("userpassword", userpassword);
        formData.append("UserDob", UserDob);
        formData.append("mobileNo", mobileNo);
        formData.append("email", email);
        formData.append("gender", gender);
        formData.append("occupation", occupation);
        formData.append("createdbyid", user.UserId);
        formData.append("createdbyrole", user.role);

        formData.append("role", role);
        formData.append("adhaarNo", adhaarNo);
        formData.append("uploadadhaarcard1", uploadadhaarcard1);
        formData.append("panNo", panNo);
        formData.append("uploadpancard1", uploadpancard1);
        formData.append("Address", Address);
        formData.append("uploadphoto1", uploadphoto1);

        axios
          .post("https://api.chitgenius.com/api/myupload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            setState({
              UserName: "",
              userpassword: "",
              UserDob: "",
              mobileNo: "",
              email: "",
              gender: "",
              occupation: "",
              createdbyid: "",
              createdbyrole: "",
              role: "",
              adhaarNo: "",
              uploadadhaarcard1: {},
              panNo: "",
              uploadpancard1: {},
              Address: "",
              uploadphoto1: {},
            });
          })
          .catch((err) => alert.error(err.response.data));
        alert.success("Member Added Successfully");
      } else {
        console.log(UserId);
        const formData = new FormData();
        formData.append("UserName", UserName);
        formData.append("userpassword", userpassword);
        formData.append("UserDob", UserDob);
        formData.append("mobileNo", mobileNo);
        formData.append("email", email);
        formData.append("gender", gender);
        formData.append("occupation", occupation);
        formData.append("createdbyid", user.UserId);
        formData.append("createdbyrole", user.role);

        formData.append("role", role);
        formData.append("adhaarNo", adhaarNo);
        formData.append("uploadadhaarcard1", uploadadhaarcard1);
        formData.append("panNo", panNo);
        formData.append("uploadpancard1", uploadpancard1);
        formData.append("Address", Address);
        formData.append("uploadphoto1", uploadphoto1);

        axios
          .put(`https://api.chitgenius.com/api/update/${UserId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            setState({
              UserName: "",
              userpassword: "",
              UserDob: "",
              mobileNo: "",
              email: "",
              gender: "",
              occupation: "",
              createdbyid: "",
              createdbyrole: "",
              role: "",
              adhaarNo: "",
              uploadadhaarcard1: {},
              panNo: "",
              uploadpancard1: {},
              Address: "",
              uploadphoto1: {},
            });
          })
          .catch((err) => alert.error(err.response.data));
        alert.success("Member Updated Successfully");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div className="agent-mecontainer" style={{ display: "flex", width:"100%" }}>
      <div>
        <AgentSide />
      </div>
      <div className="Ag-totalm">
        <div className="Ag-courses-head-me">
          <div className="Ag-me-heading">
            <h2>Member Entry</h2>
          </div>

          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
        
        <hr style={{ margin: "10px 0px" }}></hr>
        <form
          className="Ag-member-container"
          // style={{ width: "75%", marginTop: "25px" }}
          onSubmit={handleSubmit}
        >
         

          <div >
            <div className="Ag-memberentry-container">
              <div className="Ag-member">
                <div className="Ag-display-02">
                  <lable htmlFor="UserName">
                    <strong>Name</strong>
                  </lable>
                  <input
                    type="text"
                    id="UserName"
                    name="UserName"
                    placeholder="enter your name"
                    className="Ag-input-02"
                    value={UserName || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="Ag-display-02">
                  <lable htmlFor="userpassword">
                    <strong>Password</strong>
                  </lable>
                  <input
                    type="text"
                    id="userpassword"
                    name="userpassword"
                    placeholder="enter your password"
                    className="Ag-input-02"                    
                    value={userpassword || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="Ag-display-02">
                  <lable htmlFor="User Dob">
                    <strong>Date-Of-Birth</strong>
                  </lable>
                  <input
                    type="date"
                    id="UserDob"
                    name="UserDob"
                    //  placeholder='enter your name'
                    className="Ag-input-02"                    
                    value={UserDob || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="Ag-member">
                <div className="Ag-display-02">
                  <lable htmlFor="mobileNo">
                    <strong>Mobile Number</strong>
                  </lable>
                  <input
                    type="number"
                    id="mobileNo"
                    name="mobileNo"
                    placeholder="enter your number"
                    className="Ag-input-02"                   
                     value={mobileNo || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="Ag-display-02">
                  <lable htmlFor="email">
                    <strong>Email</strong>
                  </lable>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="enter your mail id"
                    className="Ag-input-02"
                    value={email || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="Ag-display-02">
                  <lable htmlFor="gender">
                    <strong>Gender</strong>
                  </lable>
                  <input
                    type="text"
                    id="gender"
                    name="gender"
                    className="Ag-input-02"
                    placeholder="enter your gender"
                    value={gender || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="Ag-member">
                <div className="Ag-display-02">
                  <lable htmlFor="occupation">
                    <strong>Occupation</strong>
                  </lable>
                  <input
                    type="text"
                    id="occupation"
                    className="Ag-input-02"
                    name="occupation"
                    placeholder="enter your occupation"
                    value={occupation || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="Ag-display-02">
                  <lable htmlFor="role">
                    <strong>Role</strong>
                  </lable>
                  <select
                    type="text"
                    id="role"
                    name="role"
                    placeholder="enter your role"
                    className="Ag-input-02"
                    value={role || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">select</option>
                    <option value="Agent">Admin</option>
                    <option value="Agent">Agent</option>
                    <option value="user">user</option>
                  </select>
                </div>

                <div className="Ag-display-02">
                  <lable htmlFor="Address">
                    <strong>Address</strong>
                  </lable>
                  <input
                    type="text"
                    id="Address"
                    name="Address"
                    placeholder="enter your address"
                    className="Ag-input-02"
                    value={Address || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="Ag-member">
                <div className="Ag-display-02">
                  <lable htmlFor="adhaarNo">
                    <strong>Adhaar Number</strong>
                  </lable>
                  <input
                    type="text"
                    id="adhaarNo"
                    name="adhaarNo"
                    placeholder="enter your adhaar number"
                    className="Ag-input-02"
                    value={adhaarNo || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="Ag-display-02">
                  <lable htmlFor="panNo">
                    <strong>Pan Number</strong>
                  </lable>
                  <input
                    type="text"
                    id="panNo"
                    name="panNo"
                    placeholder="enter your pan number"
                    className="Ag-input-02"
                    value={panNo || ""}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="Ag-display-02">
                  <lable htmlFor="uploadPhoto">
                    <strong>Upload Photo</strong>
                  </lable>
                  <input
                    type="file"
                    id="uploadPhoto"
                    name="uploadPhoto"
                    className="Ag-input-02"
                    // className='upload'
                    //   value={uploadpancard || ""}
                    onChange={(e) => {
                      setState({ ...state, uploadphoto1: e.target.files[0] });
                    }}
                  />
                </div>
              </div>

              <div className="Ag-member">
                <div className="Ag-display-02">
                  <lable htmlFor="uploadadhaarcard">
                    <strong>Upload Adhaar</strong>
                  </lable>
                  <input
                    type="file"
                    id="uploadadhaarcard"
                    name="uploadadhaarcard"
                    className="Ag-input-02"
                    // className='upload'
                    //   value={uploadadhaarcard || ""}
                    onChange={(e) => {
                      setState({
                        ...state,
                        uploadadhaarcard1: e.target.files[0],
                      });
                    }}
                  />
                </div>

                <div className="Ag-display-02">
                  <lable htmlFor="uploadpancard">
                    <strong>Upload Pan Card</strong>
                  </lable>
                  <input
                    type="file"
                    id="uploadpancard"
                    name="uploadpancard"
                    className="Ag-input-02"
                    // className='upload'
                    //   value={uploadpancard || ""}
                    onChange={(e) => {
                      setState({ ...state, uploadpancard1: e.target.files[0] });
                    }}
                  />
                </div>

                <div className="Ag-display-02">
                <lable htmlFor="gender">
                  <strong>Createdbyrole</strong>
                </lable>
                <input
                  type="text"
                  id="createdbyrole"
                  name="createdbyrole"
                  className="Ag-input-02"
                  placeholder="enter your gender"
                  value={user.role}
                  onChange={handleInputChange}
                />
              </div>
              </div>
              <div className="Ag-display-02">
                <lable htmlFor="gender">
                  <strong>CreatedbyId</strong>
                </lable>
                <input
                  type="text"
                  id="createdbyid"
                  name="createdbyid"
                  className="Ag-input-02"
                  placeholder="enter your gender"
                  value={user.UserId}
                  onChange={handleInputChange}
                />
              </div>
              

              <div className="Ag-me-sbutton">
                <input
                  type="submit"
                  className="Ag-me-cdf"
                  value={UserId ? "Update" : "Save"}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Agentmemberentry;
