import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import CollectingAgentSide from "./CollectingAgentSide";
import { CollectionagentContext } from "../context/collectionagentcontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import "./detailofuserpayment.css";
import userimage from "./images/userimage.png"

const Detailsofuserpayment = () => {
  const usernamedashboard = localStorage.getItem("username");
  const { collectionagentname } = useContext(CollectionagentContext);
  const [user, setuser] = useState({});
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/collectiondetails") {
        navigate("/collectiondetails");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/collectionagent") {
        navigate("/");
      }
      return;
    }

    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const valuesid = user.createdbyid;
  console.log(valuesid);

  ///collection/details/collectingagent/admin/:valuesid

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/collection/details/collectingagent/admin/${valuesid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [valuesid]);

  return (
    <div className="collectingagent-doup" style={{ display: "flex" }}>
      <div>
        <CollectingAgentSide />
      </div>
      <div className="CA-doup-f">
        <div className="CA-courses-head">
          <div className="CA-doup-heading">
            <h2>Collection Details</h2>
          </div>
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                // style={{ height: "50px",borderRadius:'30px',width:"50px" }}
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
              {/* {user.createdbyid} */}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="CA-outer-pay">
          <div className="CA-table-pay">
            <table className="CA-tables-style">
              <thead>
                <tr>
                  <th className="tahe" style={{ alignItems: "center" }}>No</th>
                  <th className="tahe" style={{ alignItems: "center" }}>GroupName</th>
                  <th className="tahe" style={{ alignItems: "center" }}>GroupId</th>
                  <th className="tahe" style={{ alignItems: "center" }}>Installment</th>
                  <th className="tahe" style={{ alignItems: "center" }}>UserId</th>
                  <th className="tahe" style={{ alignItems: "center" }}>TotalAmount</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr>
                      <td style={{ alignItems: "center" }}>{index + 1}</td>
                      <td style={{ alignItems: "center" }}>{item.groupid}</td>
                      <td style={{ alignItems: "center" }}>{item.groupname}</td>
                      <td style={{ alignItems: "center" }}>{item.installment}</td>
                      <td style={{ alignItems: "center" }}>{item.userid}</td>
                      <td style={{ alignItems: "center" }}>{item.payoutsub}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detailsofuserpayment;
