import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
// import "./Auctiondata.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { ReceiptionContext } from "../context/receiptioncontext";
import {useNavigate,useLocation} from "react-router-dom"
import Cookies from 'js-cookie';
import '../receiption/respauctiondetails.css';
 import userimage from "./images/userimage.png"
// import SideBar from "../components/Sidebar/SideBar";
import Respside from "./Respside";
// import "./Auctiondata.css";
 
const RespAuctiondetails = () => {
  const usernamedashboard = localStorage.getItem("username");
  const { receiptionname } = useContext(ReceiptionContext);
  const [user, setuser] = useState({});
 
  const [data, setData] = useState([]);
  const { auctionId } = useParams();
  // const history = useNavigate();
  const [search, setSearch] = useState("");



  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
      const token = Cookies.get('token');
      const { state } = location;

      if (token) {
         
          if (location.pathname !== '/respauctiondetails') {
              navigate('/respauctiondetails');
          }
          return;
      }

      if ( !state || !state.username || !state.role) {
        if (location.pathname !== '/receiptionist') {
          navigate('/');
        }
        return;
      }
     
          setUsername(state.username);
          setRole(state.role);
          console.log(state.username, state.role);
         
    }, [location, navigate]);



 
  const dltgroupuser = async (auctionId) => {
    console.log(auctionId);
    const res = await axios.delete(
      `https://api.chitgenius.com/api/delete/auctiondataid/${auctionId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.status == 201) {
      // console.log("success")
      // getAssessmentData()
      //  setShow(true)
    navigate("/chitgroup");
    } else {
      console.log("error");
    }
  };
 
  const loadData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/chit/auctionsdata/get/receiption"
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, []);
 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);
 
  return (
    <div className="Resp-auctiondetails-main" style={{ display: "flex" }}>
      <div>
        <Respside />
      </div>
      <div className="Resp-auctiondetails-full">
        <div className="Resp-auctiondetails-head">
          <div className="Resp-auctiondetails-heading">
            <h2>Auction Details</h2>
          </div>
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                style={{ height: "50px", borderRadius: "30px", width: "50px" }}
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="Resp-searchContainer">
          <input
            type="search"
            placeholder="Search by Name"
            className="Resp-quizSearch"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
        <div className="">
          <div className="Resp-table-auctiondata">
            <table className="Resp-table-style-Gu">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>No.</th>
                  {/* <th style={{ textAlign: "center" }}>groupId</th> */}
                  <th className="tahe" style={{ textAlign: "center" }}>Auction Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Amount Due Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Installment Number</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Next Auction Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Bid Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Bider Name</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Group Id</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Group Name</th>
                  <th className="tahe" style={{ textAlign: "center" }}>chit Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>commission</th>
                  <th className="tahe" style={{ textAlign: "center" }}>DateOfCreation</th>
 
                  {/* <th style={{ textAlign: "center" }}>ACTION</th> */}
                </tr>
              </thead>
              <tbody>
                {data
                  .filter(
                    (data) =>
                      data.bidderName
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      data.auctionDate.includes(search) ||
                      data.group_id.toString() === search.trim()
                  )
                  .map((item, index) => {
                    return (
                      <tr style={{ margin: "0px" }}>
                        <td style={{ margin: "0px" }}>{index + 1}</td>
                        {/* <td>{item.auctionId}</td> */}
                        <td>{item.auctionDate}</td>
                        <td>{item.amountdueDate}</td>
                        <td>{item.InstallmentNumber}</td>
                        <td>{item.nextauctionDate}</td>
                        <td>{item.bidAmount}</td>
                        <td>{item.bidderName}</td>
                        <td>{item.group_id}</td>
                        <td>{item.group_name}</td>
                        <td>{item.chit_amount}</td>
                        <td>{item.commission_k}</td>
                        <td>
                          {moment(item.autionCreationDate).format("DD-MM-YYYY")}
                        </td>
                        {/* <td>
                      <div className="gtf">
                      <div>
                        <Link to={`/update/autiondata/${item.auctionId}`}>
                          <button  className="cdf">Edit</button>
                        </Link>
                        </div>
                        <div>
                        <button onClick={() => dltgroupuser(item.auctionId)} className="ch">
                          Delete
                        </button>
                        </div>
                        <div>
                        <Link to={`/biding/${item.auctionId}/${item.group_id}`}>
                        <button className="create-course">Biding</button>
                        </Link>
                        </div>
                      </div>
                      </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default RespAuctiondetails;