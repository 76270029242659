// import React, { useState } from "react";
// import axios from "axios";
// import SideBar from "../components/Sidebar/SideBar";
// import moment from "moment";
// import './transitionhistory.css';
// import { Link } from "react-router-dom";

// const Searchtest = () => {
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [searchResult, setSearchResult] = useState([]);

//   const handleSearch = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post("https://api.chitgenius.com/search/bydate", {
//         startDate,
//         endDate,
//       });
//       setSearchResult(response.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div style={{ display: "flex" }}>
//       <div>
//         <SideBar />
//       </div>
//       <div>
//         <div>
//           <h4>Userpayment Reports</h4>
//         </div>
//         <hr/>
//         <div className="bord"
//           // style={{ width: "200px", height: "50px" }}
//         >
//           <Link to="/tdate">
//             <button className="doo" style={{ backgroundColor: "blue", color: "white" }}>
//               Custom
//             </button>
//           </Link>
//           <Link to="/transhistory">
//             <button className="do">MontlyByGroupID</button>
//           </Link>
//         </div>

//         <form onSubmit={handleSearch}>
//           <div className="poor">
//             <div className="vgtd">
//               <label className="vf"><strong>Start Date</strong></label>
//               <input
//                 type="date"
//                 className="veg"
//                 value={startDate}
//                 onChange={(e) => setStartDate(e.target.value)}
//               />
//             </div>
//             <div className="vgtd">
//               <label className="vf"><strong>End Date</strong></label>
//               <input
//                 type="date"
//                 className="veg"
//                 value={endDate}
//                 onChange={(e) => setEndDate(e.target.value)}
//               />
//             </div>
//           </div>
          
//           <input type="submit"  className="src" value="Search" />
//         </form>

//         <div className="bgfs">
//           {searchResult.length > 0 ? (
//             <div className="table-wrap">
//               <table className="tables-style">
//                 <thead>
//                   <tr className="sasq">
//                     <th colSpan="11" style={{ textAlign: "center" }}>
//                       Transactions
//                     </th>
//                   </tr>
//                   <tr>
//                     <th style={{ textAlign: "center" }}>No.</th>
//                     <th style={{ textAlign: "center" }}>payment_userId</th>

//                     <th style={{ textAlign: "center" }}>payment_username</th>
//                     <th style={{ textAlign: "center" }}>payment_idgroup</th>
//                     <th style={{ textAlign: "center" }}>payment_namegroup</th>
//                     <th style={{ textAlign: "center" }}>payment_month</th>
//                     <th style={{ textAlign: "center" }}>payment_insNum</th>
//                     <th style={{ textAlign: "center" }}>payment_tap</th>
//                     <th style={{ textAlign: "center" }}>payment_hap</th>
//                     <th style={{ textAlign: "center" }}>payment_Mode</th>
//                     <th style={{ textAlign: "center" }}>payment_mobilenum</th>
//                     <th style={{ textAlign: "center" }}>payment_Date</th>
//                     <th style={{ textAlign: "center" }}>payment_Balance</th>
//                     <th style={{ textAlign: "center" }}>payment_bidingId</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {searchResult.map((result, index) => (
//                     <tr key={index}>
//                       <td>{index + 1}</td>

//                       <td>{result.payment_userId}</td>
//                       <td>{result.payment_username}</td>
//                       <td>{result.payment_idgroup}</td>
//                       <td>{result.payment_namegroup}</td>
//                       <td>{result.payment_month}</td>

//                       <td>{result.payment_insNum}</td>

//                       <td>{result.payment_tap}</td>
//                       <td>{result.payment_hap}</td>
//                       <td>{result.payment_Mode}</td>
//                       <td>{result.payment_mobilenum}</td>

//                       <td>
//                         {moment(result.payment_Date).format("DD-MM-YYYY")}
//                       </td>
//                       <td>{result.payment_Balance}</td>
//                       <td>{result.payment_bidingId}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           ) : (
//             <div>
//               <p className="vwq">No Data Available</p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Searchtest;



import React, { useState } from "react";
import axios from "axios";
import SideBar from "../components/Sidebar/SideBar";
import moment from "moment";
import "./transitionhistory.css";
import { Link } from "react-router-dom";
 
const Searchtest = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchResult, setSearchResult] = useState([]);
 
  const handleSearch = async (e) => {
    e.preventDefault();
 
    try {
      const response = await axios.post("https://api.chitgenius.com/search/bydate", {
        startDate,
        endDate,
      });
      setSearchResult(response.data);
    } catch (error) {
      console.error(error);
    }
  };
 
  return (
    <div style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div>
        <div>
          <h3>Userpayment Reports</h3>
        </div>
        <hr />
 
        <div className="book">
          <div
            className="bord"
            // style={{ width: "200px", height: "50px" }}
          >
            <Link to="/tdate">
              <button
                className="doos"
               
              >
                Custom
              </button>
            </Link>
            <Link to="/transhistory">
              <button className="dos">MontlyByGroupID</button>
            </Link>
            <Link to="/user/pc">
            <button className="dos">BYUSERID</button>
            </Link>
          </div>
 
          <form onSubmit={handleSearch}>
            <div className="poor">
              <div className="vgtd">
                <label className="vf">
                  <strong>Start Date</strong>
                </label>
                <input
                  type="date"
                  className="veg"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="vgtd">
                <label className="vf">
                  <strong>End Date</strong>
                </label>
                <input
                  type="date"
                  className="veg"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <input type="submit" className="src" value="Search" />
          </form>
 
          <div className="bgfs">
            {searchResult.length > 0 ? (
              <div className="table-wrap">
                <table className="tables-style">
                  <thead>
                    <tr className="sasq">
                      <th colSpan="11" style={{ textAlign: "center" }}>
                        Transactions
                      </th>
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }}>No.</th>
                      <th style={{ textAlign: "center" }}>payment_userId</th>
 
                      <th style={{ textAlign: "center" }}>payment_username</th>
                      <th style={{ textAlign: "center" }}>payment_idgroup</th>
                      <th style={{ textAlign: "center" }}>payment_namegroup</th>
                      <th style={{ textAlign: "center" }}>payment_month</th>
                      <th style={{ textAlign: "center" }}>payment_insNum</th>
                      <th style={{ textAlign: "center" }}>payment_tap</th>
                      <th style={{ textAlign: "center" }}>payment_hap</th>
                      <th style={{ textAlign: "center" }}>payment_Mode</th>
                      <th style={{ textAlign: "center" }}>payment_mobilenum</th>
                      <th style={{ textAlign: "center" }}>payment_Date</th>
                      <th style={{ textAlign: "center" }}>payment_Balance</th>
                      <th style={{ textAlign: "center" }}>payment_bidingId</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResult.map((result, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
 
                        <td>{result.payment_userId}</td>
                        <td>{result.payment_username}</td>
                        <td>{result.payment_idgroup}</td>
                        <td>{result.payment_namegroup}</td>
                        <td>{result.payment_month}</td>
 
                        <td>{result.payment_insNum}</td>
 
                        <td>{result.payment_tap}</td>
                        <td>{result.payment_hap}</td>
                        <td>{result.payment_Mode}</td>
                        <td>{result.payment_mobilenum}</td>
 
                        <td>
                          {moment(result.payment_Date).format("DD-MM-YYYY")}
                        </td>
                        <td>{result.payment_Balance}</td>
                        <td>{result.payment_bidingId}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <p className="vwq">No Data Available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Searchtest;