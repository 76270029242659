import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import axios from "axios";
import SideBar from "../components/Sidebar/SideBar";

import { NavLink, Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { UserContext } from "../context/usecontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../pages/paymentcollectiondetails.css';
import userimage from "./images/userimage.png"

const Paymentcollectiondetails = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);

  const [search, setSearch] = useState("");
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/home/bid") {
        navigate("/home/bid");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
      return;
    }

    setUsersname(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const commonpaymentcollectgetid = user.UserId;

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/paymentcollection/all/details/${commonpaymentcollectgetid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [commonpaymentcollectgetid]);

  const dltbid = async (paymentcollect_Id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const res = await axios.delete(
          `https://api.chitgenius.com/api/delete/pid/${paymentcollect_Id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.status === 201) {
          toast.success("Successfully deleted!");
          // navigate("/moreinfo"); // Redirect after successful deletion
          loadData();
        } else {
          console.log("Error deleting data");
        }
      } catch (error) {
        console.error("There was an error deleting the data", error);
        toast.error("There was an error deleting the data.");
      }
    }
  };

  return (
    <div className="Ad-paymentcollectiondetails-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-paymentcollectiondetails-mm">
        <div className="Ad-paymentcollectiondetails-full">
           
            <div className="Ad-paymentcollectiondetails-head">
              <div className="Ad-h">
                <div className="">
                  <NavLink className="" to={"/moreinfo"}>
                    <FaArrowLeftLong className="Ad-ume" />
                  </NavLink>
                </div>
                <div className="Ad-paymentcollectiondetails-heading">
                  <h2>paymentcollection details</h2>
                </div>
              </div>
              <div className="image">
                <div>
                  <img
                  src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                    // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                    alt=""
                    style={{
                      height: "50px",
                      borderRadius: "30px",
                      width: "50px",
                    }}
                    className="images"
                  ></img>
                </div>
                <div>
                  <p style={{ margin: "0px" }}>{user.UserName}</p>
                  <p style={{ margin: "0px" }}>({user.role})</p>
                </div>
              </div>
            </div>
          
          <hr />

          <div className="Ad-GU-searchContainer">
            <input
              type="search"
              placeholder="Search by Name"
              className="Ad-GU-quizSearch"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>

          

          <div className="Ad-table-auctiondata">
            <div className="">
              <table className="Ad-table-style-Gu">
                <thead>
                  <tr>
                    <th className="tahe" style={{ textAlign: "center" }}>No.</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_userId</th>

                   {/*<th className="tahe" style={{ textAlign: "center" }}>payment_username</th>*/}
                    <th className="tahe" style={{ textAlign: "center" }}>payment_idgroup</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_namegroup</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_month</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_insNum</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_tap</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_hap</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_Mode</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_mobilenum</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_Date</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_Balance</th>
                    {/* <th style={{ textAlign: "center" }}>payment_bidingId</th> */}
                    <th className="tahe" style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data
                    .filter((data) => data.payment_mobilenum.includes(search))

                    .map((item, index) => {
                      return (
                        <tr>
                          <td  style={{ textAlign: "center" }}>{index + 1}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_userId}</td>
                          {/* <td  style={{ textAlign: "center" }}>{item.payment_username}</td> */}
                          <td  style={{ textAlign: "center" }}>{item.payment_idgroup}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_namegroup}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_month}</td>

                          <td  style={{ textAlign: "center" }}>{item.payment_insNum}</td>

                          <td  style={{ textAlign: "center" }}>{item.payment_tap}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_hap}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_Mode}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_mobilenum}</td>

                          <td  style={{ textAlign: "center" }}>
                            {moment(item.payment_Date).format("DD-MM-YYYY")}
                          </td>
                          <td  style={{ textAlign: "center" }}>{item.payment_Balance}</td>
                          {/* <td>{item.payment_bidingId}</td> */}
                          <td  style={{ textAlign: "center" }}>
                            <button
                              onClick={() => dltbid(item.paymentcollect_Id)}
                              className="ch"
                              style={{marginLeft:'10px'}}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                {/* <tbody>
                  

                  {Array.isArray(data) &&
                    data
                      .filter(
                        (item) =>
                          item.payment_username
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          item.payment_mobilenum.includes(search)
                      )

                      .map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.payment_userId}</td>
                            <td>{item.payment_username}</td>
                            <td>{item.payment_idgroup}</td>
                            <td>{item.payment_namegroup}</td>
                            <td>{item.payment_month}</td>

                            <td>{item.payment_insNum}</td>

                            <td>{item.payment_tap}</td>
                            <td>{item.payment_hap}</td>
                            <td>{item.payment_Mode}</td>
                            <td>{item.payment_mobilenum}</td>

                            <td>
                              {moment(item.payment_Date).format("DD-MM-YYYY")}
                            </td>
                            <td>{item.payment_Balance}</td>
                            <td>{item.payment_bidingId}</td>

                            <td>
                              <button
                                onClick={() => dltbid(item.paymentcollect_Id)}
                                className="ch"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                </tbody> */}
              </table>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Paymentcollectiondetails;
