import React, { useState, useEffect, useContext } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import "./Agentaddexit.css";
import axios from "axios";
import { FaArrowLeftLong } from "react-icons/fa6";
import Select from "react-select";
import AgentSide from "../agentcompo/AgentSide";
import { AgentContext } from "../context/agentcontext";
import Swal from "sweetalert2";
import userimage from "./images/userimage.png"
 
const initialState = {
  groud_Id: "",
  group_Name: "",
  user_Name: "",
  user_Id: "",
  mobileNo: "",
  email: "",
};
 
const AgentAddexisting = () => {
  const [state, setState] = useState(initialState);
  const [userData, setUserData] = useState({});
  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});
 
  const { group_Id, group_Name, user_Name, user_Id, mobileNo, email } = state;
 
  const [data, setData] = useState([]);
  const { groupId, groupName, noOfUsers } = useParams();
  console.log(groupId);
  console.log(noOfUsers);
 
 
  const navigate = useNavigate();
 
  useEffect(() => {
    setState({ ...state, group_Id: groupId, group_Name: groupName });
    loadData();
  }, [groupId, groupName]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
   
 
    axios
      .post("https://api.chitgenius.com/api/adduser/chit", {
        group_Id,
        group_Name,
        user_Name,
        user_Id,
        mobileNo,
        email,
        createdbyid: user.UserId,
        noOfUsers,
 
      })
      .then(() => {
        setState({
          user_Name: "",
          user_Id: "",
          mobileNo: "",
          email: "",
        });
 
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "User added successfully",
        });
        setTimeout(() => {
            navigate("/agentchits");
        }, 500);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data,
        });
      });
  };
 
  
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
 
  const loadData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/api/getall/usernames"
    );
    setData(response.data);
  };
  // useEffect(() => {
  //   loadData();
  // }, []);
 
  useEffect(() => {
    if (mobileNo) {
      fetchUserData();
    }
  }, [mobileNo]);
 
  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `https://api.chitgenius.com/api/get/auto/${mobileNo}`
      );
      setUserData(response.data);
      setState({
        ...state,
        user_Name: response.data.UserName,
        user_Id: response.data.UserId,
        email: response.data.email,
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${agentname}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [agentname]);
 
  return (
    <div className="Agent-addexisting-main" style={{ display: "flex" }}>
      <div>
        <AgentSide />
      </div>
      <div className="Ag-addexisting-full">
        <div className="Ag-addexisting-head">
          <div className="Ad-h">
            <div className="">
              <NavLink className="" to={"/agentchits"}>
                <FaArrowLeftLong className="Ad-ume" />
              </NavLink>
            </div>
            <div className="Ag-addexisting-heading">
              <h2> AddUsers</h2>
            </div>
          </div>
 
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
 
        <hr></hr>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="Ag-addexisting-kh">
              <div className="Ag-addexisting-display-003">
                <label>
                  <strong>GroupId</strong>
                </label>
                <input
                  type="text"
                  name="group_Id"
                  className="Ag-addexisting-input-003"
                  value={group_Id}
                  onChange={handleInputChange}
                  readOnly
 
                ></input>
              </div>
 
              <div className="Ag-addexisting-display-003 ">
                <label>
                  <strong>GroupName</strong>
                </label>
                <input
                  type="text"
                  name="group_Name"
                  className="Ag-addexisting-input-003"
                  value={group_Name}
                  onChange={handleInputChange}
                  readOnly
 
                ></input>
              </div>
 
              <div className="Ag-addexisting-display-003">
                <label>
                  <strong>Select MobileNo</strong>
                </label>
                <Select
                  options={data.map((opts) => ({
                    label: opts.mobileNo,
                    value: opts.mobileNo,
                  }))}
                  value={{ label: mobileNo, value: mobileNo }}
                  className="Ag-addexisting-input-003"
                  onChange={(selectedOption) =>
                    setState({ ...state, mobileNo: selectedOption.value })
                  }
                  isSearchable={true}
                />
              </div>
            </div>
 
            <div className="Ag-addexisting-kh">
              <div className="Ag-addexisting-display-003">
                <label>
                  <strong>User Name</strong>
                </label>
                <div>
                  <input
                    type="text"
                    value={user_Name}
                    className="Ag-addexisting-input-003"
                  ></input>
                  
                </div>
              </div>
 
              <div className="Ag-addexisting-display-003">
                <label>
                  <strong>Select UserId</strong>
                </label>
                <input
                  type="text"
                  value={user_Id}
                  className="Ag-addexisting-input-003"
                ></input>
              
              </div>
 
              <div className="Ag-addexisting-display-003">
                <label>
                  <strong>Select Email</strong>
                </label>
               
                <input type="text" value={email} className="Ag-addexisting-input-003"></input>
              </div>
            </div>
            <div className="Ad-addexisting-kh" >
            <div className="Ag-addexisting-display-003">
              <label><strong>createdbyid</strong></label>
              <input
                type="text"
                name="createdbyid"
                value={user.UserId}
                className="Ag-addexisting-input-003"
              ></input>
            </div>
            </div>
           
            <div className="Ag-addexisting-vdt">
              <input type="submit" value=" Add user" className="Ag-addexisting-ccc"></input>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
 
export default AgentAddexisting;
 