import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { CollectionagentContext } from "../context/collectionagentcontext";
import CollectionAgentDash from '../collectAgent/CollectionAgentDash'




export const Collectionagent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
//   const [username, setUsername] = useState("");
const {collectionagentname ,setCollectionagentname } = useContext(CollectionagentContext);


  useEffect(() => {
    const token = Cookies.get('token');
    const { state } = location;

    if (!token || !state || !state.username || !state.role) {
      navigate('/');
    } else {
      setCollectionagentname(state.username);
      setRole(state.role);
      console.log(state.username, state.role); // Log username and role
    }
  }, [location, navigate]);

  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
  };

  return (
    <div>
      {/* <h2>Welcome to the Parent Page!</h2> */}
      {/* <h1>Welcome, {collectionagentname}</h1> */}

      {/* <p>Role: {role}</p> */}
      {/* <button onClick={handleLogout}>Logout</button> */}
      <CollectionAgentDash/>
    </div>
  );
};
