import React, { useState, useEffect, useContext } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import "./Create.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import { toast } from "react-toastify";
import AgentSide from "../agentcompo/AgentSide";
import { AgentContext } from "../context/agentcontext";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import userimage from "./images/userimage.png"

const initialState = {
  groupName: "",
  auctionType: "",
  startDate: "",
  noOfInstallments: "",
  endDate: "",
  noOfUsers: "",
  commission: "",
  auctionDate: "",
  chitAmount: "",
  createdby: "",
  chitstatus: "",
};

export const Agentcreategroup = () => {
  const usernamedashboard = localStorage.getItem("username");
  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});
  const [state, setState] = useState(initialState);
  const {
    groupName,
    auctionType,
    startDate,
    noOfInstallments,
    endDate,
    noOfUsers,
    commission,
    auctionDate,
    chitAmount,
    createdby,
    chitstatus,
  } = state;

  const navigate = useNavigate();

  const { groupId } = useParams();

  useEffect(() => {
    axios
      .get(`https://api.chitgenius.com/api/getchitid/${groupId}`)
      .then((resp) => setState({ ...resp.data[0] }));
  }, [groupId]);

 

  const calculateEndDate = () => {
    if (state.startDate && state.noOfInstallments) {
      const [startYear, startMonth, startDay] = state.startDate
        .split("-")
        .map(Number);
      const installments = parseInt(state.noOfInstallments);

      const newStartDate = new Date(startYear, startMonth - 1, startDay);
      newStartDate.setMonth(newStartDate.getMonth() + installments);

      const newEndDate = `${newStartDate.getFullYear()}-${(
        newStartDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${newStartDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;

      // Update the state with the new end date
      setState((prevState) => ({ ...prevState, endDate: newEndDate }));
    }
  };
  useEffect(() => {
    const endDate = calculateEndDate();
    if (endDate) {
      console.log("End Date:", endDate);
    }
  }, [state.startDate, state.noOfInstallments]);

  // useEffect(() => {
  //   calculateEndDate();
  // }, [state.startDate, state.noOfInstallments]);

  const validateGroupName = (name) => {
    const regex = /^(?=.*[a-zA-Z]{3,})(?=.*[0-9]{1,2})(?=.*[\+\-])[a-zA-Z0-9\+\-]+$/;
    return regex.test(name);
  };

  
  const checkGroupNameExists = async (name) => {
    try {
      const response = await axios.get(`https://api.chitgenius.com/api/checkGroupName/${name}`);
      return response.data.exists;
    } catch (error) {
      console.error("Error checking group name", error);
      return false;
    }
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (!validateGroupName(groupName)) {
      alert("Group Name must contain at least 3 letters, 1 or 2 numbers, and one special character (+ or -).");
      return;
    }
    if (
      !groupName ||
      !auctionType ||
      !startDate ||
      !noOfInstallments ||
      !endDate ||
      !noOfUsers ||
      !commission ||
      !auctionDate ||
      !chitAmount ||
      !createdby
    ) {
      alert("Please fill all the fields");
    } else {
      if (parseInt(chitAmount) < 50000) {
        alert("Chit amount must be at least 50,000");
        return;
      }
      if (noOfUsers < 5) {
        alert("Number of Users must be at least 5");
        return;
      }
      if (noOfInstallments < 5) {
        alert("Number of Installments must be at least 5");
        return;
      }
      if (commission > 20) {
        alert("Commission must be at most 20%");
        return;
      }

      if (!groupId) {
        const groupNameExists = await checkGroupNameExists(groupName);
        if (groupNameExists) {
          alert("Group Name already exists. Please enter another group name.");
          return;
        }


        axios
          .post("https://api.chitgenius.com/api/chitcreate/post", {
            groupName,
            auctionType,
            startDate,
            noOfInstallments,
            endDate,
            noOfUsers,
            commission,
            auctionDate,
            chitAmount,
            createdby,
            chitstatus,
          })
          .then(() => {
            setState({
              groupName: "",
              autiontype: "",
              startDate: "",
              noOfInstallments: "",
              endDate: "",
              noOfUsers: "",
              commission: "",
              auctionDate: "",
              chitAmount: "",
              createdby: "",
              chitstatus: "",
            });
            toast.success("chit created successfully");
          })
          .catch((err) => toast.error(err.response.data));
      } else {
        axios
          .put(`https://api.chitgenius.com/api/chitcreate/update/${groupId}`, {
            groupName,
            auctionType,
            startDate,
            noOfInstallments,
            endDate,
            noOfUsers,
            commission,
            auctionDate,
            chitAmount,
            createdby,
            chitstatus,
          })
          .then(() => {
            setState({
              groupName: "",
              autiontype: "",
              startDate: "",
              noOfInstallments: "",
              endDate: "",
              noOfUsers: "",
              commission: "",
              auctionDate: "",
              chitAmount: "",
              createdby: "",
              chitstatus: "",
            });
            toast.success("chit  updated successfully");
          })
          .catch((err) => toast.error(err.response.data));
      }
      
    }
  };

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "commission" || name === "noOfInstallments" || name === "noOfUsers") &&
      !/^\d{0,2}$/.test(value)
    ) {
      return;
    }
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
        setState((prevState) => ({
          ...prevState,
          createdby: response.data[0].UserId,
        }));
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const creatorname = user.UserId;
  console.log(creatorname);

  return (
    <div className="Agent-createcourse-main" style={{ display: "flex" }}>
      <div>
        <AgentSide />
      </div>
      <div className="Ag-cc-full">
        <div className="courses1-head">
          <div className="Ch-h" >
            <div className="">
              <NavLink className="" to={"/agentchits"}>
                <FaArrowLeftLong className="Agent-Arrow"/>
              </NavLink>
            </div>
            
            <div className="dash-heading">
              <h2>Create Chit</h2>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" ,marginRight:"30px" }}>

          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
</div>
        <hr style={{ marginTop: "0px" }}></hr>
        <div className="C-bd">
          <form className="" onSubmit={handleSubmit}>
            <div className="Ch-ngo">
              <div className="Ch-display-11">
                <label className="Ch-mug">Group Name</label>
                <input
                  type="text"
                  name="groupName"
                  class="Ch-input-11"
                  value={groupName || ""}
                  placeholder="Group Name"
                  onChange={handleInputChange}
                  readOnly={!!groupId}
                />
              </div>

              <div className="Ch-display-11">
                <label className="Ch-mug">AuctionType</label>
                <select
                  name="auctionType"
                  className="Ch-input-11"
                  value={auctionType || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select Auction Type</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
              <div className="Ch-display-11">
                <label className="Ch-mug">StartDate</label>

                <input
                  type="date"
                  name="startDate"
                  class="Ch-input-11"
                  value={startDate || ""}
                  placeholder="startDate"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="Ch-ngo">
              <div className="Ch-display-11">
                <label className="Ch-mug">No.of Installments</label>

                <input
                  type="number"
                  name="noOfInstallments"
                  value={noOfInstallments || ""}
                  class="Ch-input-11"
                  placeholder="ex:10"
                  min="0"
                  max="99"
                  onChange={handleInputChange}
                />
              </div>
              <div className="Ch-display-11">
                <label className="mug">EndDate</label>

                <input
                  type="date"
                  name="endDate"
                  class="Ch-input-11"
                  value={endDate || ""}
                  placeholder="endDate"
                  onChange={handleInputChange}
                />
              </div>
              <div className="Ch-display-11">
                <label className="Ch-mug">No.of Users</label>

                <input
                  type="int"
                  name="noOfUsers"
                  class="Ch-input-11"
                  value={noOfUsers || ""}
                  placeholder="ex: 10"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="Ch-ngo">
              <div className="Ch-display-11">
                <label className="mug">Commission(in Percentage)</label>

                <input
                  type="int"
                  name="commission"
                  class="Ch-input-11"
                  value={commission || ""}
                  placeholder="Ex:-4"
                  onChange={handleInputChange}
                />
              </div>
             

              <div className="Ch-display-11">
                <label className="mug">Every Month Auction Date</label>
                <select
                  name="auctionDate"
                  className="Ch-input-11"
                  value={auctionDate || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {Array.from({ length: 31 }, (_, index) => index + 1).map(
                    (day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className="Ch-display-11">
                <label className="mug">ChitAmount</label>

                <input
                  type="text"
                  name="chitAmount"
                  class="Ch-input-11"
                  value={chitAmount || ""}
                  placeholder="ex: (1,00,000) or(10,000,00)"
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>

            <div className="Ch-display-11">
              <label className="Ch-mug">Createdby</label>

              <input
                type="text"
                name="createdby"
                class="Ch-input-11"
                value={createdby || ""}
                placeholder="ex: (1,00,000) or(10,000,00)"
                onChange={handleInputChange}
              >
                {/* <option value="">Select</option>
                  <option value="Agent">Agent</option> */}
              </input>
            </div>
            
            <div className="Ag-cc-hus">
              <input
                type="Submit"
                value={groupId ? "Update" : "save"}
                className="Ag-cc-cdf"
              />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};
