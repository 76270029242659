import React, { useState, useEffect, useContext } from "react";
import "./Agentmb.css";

import axios from "axios";
import "./Chitgroup.css";
import Select from "react-select";
import AgentSide from "../agentcompo/AgentSide";
import { AgentContext } from "../context/agentcontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import userimage from "./images/userimage.png"

const Agentmb = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [duration, setDuration] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/amb") {
        navigate("/amb");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/agent") {
        navigate("/");
      }
      return;
    }

    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!groupName || !duration) {
      alert("Please fill in all input fields.");
      return;
    }
    try {
      const response = await axios.post(
        "https://api.chitgenius.com/transactions/groupname",
        {
          groupName,
          duration: duration.trim(),
        }
      );
      if (response.data.length === 0) {
        alert("No data available.");
      } else {
        setTransactions(response.data);
      }
      // setTransactions(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      setError("An error occurred. Please try again.");
    }
  };

  

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const loginuserid = user.UserId;

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chit/data/get/${loginuserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [loginuserid]);

  return (
    <div style={{ display: "flex" }}>
      
        <div>
        <AgentSide />
      </div>
      <div className="C-ttd">
        
          <div className="C-courses1-head">
            <div className="dash-heading">
              <h2>MembersPassbook</h2>
            </div>
            <div className="image">
              <div>
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
              </div>
            </div>
           
          </div>

        <hr></hr>
        <div>
          <form onSubmit={handleSearch}>
            <div className="book-pure">
              <div className="book-vgtdf">
                <label>
                  <strong>Group Name</strong>
                </label>
                <Select
                  className="M-i-2"
                  value={{ label: groupName, value: groupName }}
                  onChange={(selectedOption) =>
                    setGroupName(selectedOption.value)
                  }
                  options={data.map((opts) => ({
                    label: opts.groupName,
                    value: opts.groupName,
                  }))}
                  isSearchable={true}
                />
              </div>
              <div className="book-vgtdf">
                <label>
                  <strong>Duration</strong>
                </label>
                <select
                 className="M-i-2"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                 
                  required
                >
                  <option value="">Select</option>
                  <option value="3 months">3 months</option>
                  <option value="6 months">6 months</option>
                  <option value="12 months">12 months</option>
                </select>
              </div>
              <div className="cft">
                <button type="submit" className="mb-save">
                  Search
                </button>
              </div>
            </div>
          </form>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="bg-table">
            {transactions.length > 0 ? (
              <div className="table-member">
                <table className="tables-style">
                  <thead>
                    <tr className="sasq">
                      <th colSpan="14" style={{ textAlign: "center" }}>
                        Transactions
                      </th>
                    </tr>
                    <tr>
                      <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>
                        No.
                      </th>
                      <th className="tahe" style={{ textAlign: "center" }}>Payment ID</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Group ID</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Group Name</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Month</th>
                      <th className="tahe" style={{ textAlign: "center" }}>
                        Installment Number
                      </th>
                      <th className="tahe" style={{ textAlign: "center" }}>Bid Winner</th>
                      <th className="tahe" style={{ textAlign: "center" }}>
                        Total Amount to pay
                      </th>
                      <th className="tahe"   style={{ textAlign: "center" }}>Amount Paid</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Payment Mode</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Payment Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{transaction.paymentId}</td>
                        <td>{transaction.idgroup}</td>
                        <td>{transaction.namegroup}</td>
                        <td>{transaction.month}</td>
                        <td>{transaction.insNum}</td>
                        <td>{transaction.bidwinner}</td>
                        <td>{transaction.tap}</td>
                        <td>{transaction.hap}</td>
                        <td>{transaction.paymentMode}</td>
                        <td>{transaction.paymentDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
            {/* (
               <div>
              <p className="vwq">No Data Available</p>
             
                </div> 
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agentmb;
