import React, { useState, useEffect,useContext } from "react";
// import "../pages/dashboard.css";
import "../superpages/Superdash.css"
import Superside from '../superadmin/Superside';
import axios from "axios";
// import { Chartdata } from "./ChartData";
import { motion } from "framer-motion";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { SuperadminContext } from "../context/superadmincontext";
import {useNavigate,useLocation} from "react-router-dom"
import Cookies from 'js-cookie';
import userimage from "./images/userimage.png"
 
  
 
const SuperDash = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [cdata, setCData] = useState([]);
  const [Activedata, setActiveData] = useState([]);
  const [InActivedata, setInActiveData] = useState([]);
  const [Chartdata, setChartdata]=useState([]);
  const [userChartdata, setUserChartdata]=useState([]);
  const [paymentChartdata, setPaymentChartdata]=useState([]);
  const { superadminname } = useContext(SuperadminContext);
  const [user, setuser] = useState({});


  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
      const token = Cookies.get('token');
      const { state } = location;

      if (token) {
         
          if (location.pathname !== '/superadmin/dash') {
              navigate('/superadmin/dash');
          }
          return;
      }

      if ( !state || !state.username || !state.role) {
        if (location.pathname !== '/superadmin') {
          navigate('/');
        }
        return;
      }
     
          setUsername(state.username);
          setRole(state.role);
          console.log(state.username, state.role);
         
    }, [location, navigate]);

 
  const [date, setDate] =useState(new Date());
  const onChange=()=>{
    setDate(date);
  };
 
  const loadData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/AllMember/count/Admin"
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, []);
 
  const loadDataChit = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/Admin"
    );
    setCData(response.data);
  };
  useEffect(() => {
    loadDataChit();
  }, []);
 
  const loadDataActiveChit = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/Admin/Active"
    );
    setActiveData(response.data);
  };
  useEffect(() => {
    loadDataActiveChit();
  }, []);
 
  const loadDataInActiveChit = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/Admin/InActive"
    );
    setInActiveData(response.data);
  };
  useEffect(() => {
    loadDataInActiveChit();
  }, []);
 
  const fetchData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/payout/details"
    );
    setChartdata(response.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
 
 
  const fetchUserData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/user/payment/details"
    );
    setUserChartdata(response.data);
  };
  useEffect(() => {
    fetchUserData();
  }, []);
 
 
  const fetchPaymentData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/payout/payment/details"
    );
    setPaymentChartdata(response.data);
  };
  useEffect(() => {
    fetchPaymentData();
  }, []);



  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

 
  return (
    <div className="super-dashboard-main" style={{ display: "flex" }}>
      <div>
        <Superside />
      </div>
 
      <div className="super-dashboard-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className="scourses"
        >
          <div className="super-dashboard-head">
            <div className="super-dashboard-heading">
              <h2>Dashboard</h2>
            </div>
            <div className="image">
              <div >
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
              </div>
              <div>
              <p style={{margin:'0px'}}>{user.UserName}</p>
              <p style={{margin:'0px'}}>({user.role})</p>
              </div>
              </div>
          </div>
          <hr />
         
 
          <div className="super-dashboard-cards-1">
            <div className="super-dashboard-card red">
              <p className="super-dashboard-tip">NO OF MEMBERS</p>
              {data.map((opts, i) => (
                <option key={i} value={opts.Membercount}>
                  {opts.Membercount}
                </option>
              ))}
            </div>
            <div className="super-dashboard-card blue">
              <p className="super-dashboard-tip">TOTAL CHITS</p>
              {cdata.map((opts, i) => (
                <option key={i} value={opts.chitcount}>
                  {opts.chitcount}
                </option>
              ))}
            </div>
            <div className="super-dashboard-card green">
              <p className="super-dashboard-tip">ACTIVE CHITS</p>
              {Activedata.map((opts, i) => (
                <option key={i} value={opts.chitActivecount}>
                  {opts.chitActivecount}
                </option>
              ))}
            </div>
            <div className="super-dashboard-card purple">
              <p className="super-dashboard-tip">INACTIVE CHITS</p>
              {InActivedata.map((opts, i) => (
                <option key={i} value={opts.chitInActivecount}>
                  {opts.chitInActivecount}
                </option>
              ))}
            </div>
          </div>

          <div className="super-dashboard-second-part">
          <div className="super-dashboard-chartdata first">
               <h4>Payout Chart</h4>
            <div className="super-dashboard-container-chart">
              <BarChart  width={350} height={350} data={Chartdata} >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis  interval={25000}  tickCount={5} domain={[0, 100000]} />
                <Tooltip />
                <Legend />
                <Bar dataKey="TP" fill="#8884d8"  barSize={20}/>
                <Bar dataKey="HP" fill="#82ca9d" barSize={20} />
              </BarChart>
            </div>
          </div>
          
           <div className="super-dashboard-chartdata">
               <h4>Payment collection Chart</h4>
            <div className="super-dashboard-container-chart">
              <BarChart  width={350} height={350} data={userChartdata} >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="payment_month" />
                <YAxis  interval={25000}  tickCount={5} domain={[0, 100000]} />
                <Tooltip />
                <Legend />
                <Bar dataKey="TP" fill="#8884d8"  barSize={20}/>
                <Bar dataKey="HP" fill="#82ca9d" barSize={20} />
              </BarChart>
            </div>
          </div>
          <div className="super-dashboard-chartdata">
               <h4>Transaction Chart</h4>
            <div className="super-dashboard-container-chart">
              <BarChart  width={350} height={350} data={paymentChartdata} >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis  interval={10000}  tickCount={5} domain={[0, 100000]} />
                <Tooltip />
                <Legend />
                <Bar dataKey="payout_HP" fill="#8884d8"  barSize={20}/>
                <Bar dataKey="payment_HP" fill="#82ca9d" barSize={20} />
              </BarChart>
            </div>
          </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
 
export default SuperDash;