import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import "./transitionhistory.css";
import SideBar from "../components/Sidebar/SideBar";
import { Link } from "react-router-dom";
import { UserContext } from "../context/usecontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import userimage from "./images/userimage.png"

const TransactionHistory = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [groupId, setGroupId] = useState("");
  const [month, setMonth] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [groupName, setGroupName] = useState("");

  const [data, setData] = useState([]);
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/transhistory") {
        navigate("/transhistory");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
      return;
    }

    setUsersname(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!groupName || !month) {
      alert("Please fill in all input fields.");
      return;
    }
    try {
      const response = await axios.post(
        "https://api.chitgenius.com/search/paymentcollection/admin/getdetails",
        {
          // groupId,
          groupName,
          month,
        }
      );
      if (response.data.length === 0) {
        alert("No data available.");
      }
      setSearchResult(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // const loadData = async () => {
  //   const response = await axios.get(
  //     "https://api.chitgenius.com/api/getall/groupnames"
  //   );
  //   setData(response.data);
  // };

  // useEffect(() => {
  //   loadData();
  // }, []); //

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const loginuserid = user.UserId;

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chit/data/get/${loginuserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [loginuserid]);

  return (
    <div className="Ad-history-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-history-full">
        <div className="Ad-history-head">
          <div className="Ad-history-heading">
            <h2>Userpayment Reports</h2>
          </div>
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
        <hr></hr>

        <div className="Ad-book">
          <div className="Ad-bord">
            <div>
              <Link to="/transhistory">
                <button className="Ad-do">MontlyByGroupID</button>
              </Link>
              <Link to="/user/pc">
                <button className="Ad-doo">BYUSERID</button>
              </Link>
            </div>
          </div>
          <form onSubmit={handleSearch}>
            <div className="Ad-bye">
              <div className="Ad-history-vgt">
                <label>
                  <strong>Group Name</strong>
                </label>
                <Select
                  className="Ad-history-vg"
                  value={{ label: groupName, value: groupName }}
                  onChange={(selectedOption) =>
                    setGroupName(selectedOption.value)
                  }
                  options={data.map((opts) => ({
                    label: opts.groupName,
                    value: opts.groupName,
                  }))}
                  isSearchable={true}
                />
              </div>

              <div className="Ad-history-vgt">
                <label>
                  <strong>Month</strong>
                </label>
                <select
                  value={month}
                  className="Ad-history-vg"
                  name="payment_month"
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="">Select</option>

                  <option value="Jan">January</option>
                  <option value="Feb">February</option>
                  <option value="Mar">March</option>
                  <option value="Apr">April</option>
                  <option value="May">May</option>
                  <option value="Jun">June</option>
                  <option value="Jul">July</option>
                  <option value="Aug">August</option>
                  <option value="Sep">September</option>
                  <option value="Oct">October</option>
                  <option value="Nov">November</option>
                  <option value="Dec">December</option>
                </select>
              </div>
            </div>
            <div>
              <input type="submit" className="Ad-history-src" value="search"></input>
            </div>
          </form>

          <div className="Ad-up-bgfs">
            {searchResult.length > 0 ? (
              <div className="Ad-history-table-groupid">
                <table className="Ad-table-style-Gu">
                  <thead>
                    <tr className="Ad-sasq">
                      <th colSpan="14" style={{ textAlign: "center" }}>
                        Transactions
                      </th>
                    </tr>
                    <tr>
                      <th className="tahe" style={{ textAlign: "center" }}>No.</th>
                      <th className="tahe" style={{ textAlign: "center" }}>payment_userId</th>

                      {/* <th className="tahe" style={{ textAlign: "center" }}>payment_username</th> */}
                      <th className="tahe" style={{ textAlign: "center" }}>payment_idgroup</th>
                      <th className="tahe" style={{ textAlign: "center" }}>payment_namegroup</th>
                      <th className="tahe" style={{ textAlign: "center" }}>payment_month</th>
                      <th className="tahe" style={{ textAlign: "center" }}>payment_insNum</th>
                      <th className="tahe" style={{ textAlign: "center" }}>payment_tap</th>
                      <th className="tahe" style={{ textAlign: "center" }}>payment_hap</th>
                      <th className="tahe" style={{ textAlign: "center" }}>payment_Mode</th>
                      <th className="tahe" style={{ textAlign: "center" }}>payment_mobilenum</th>
                      <th className="tahe" style={{ textAlign: "center" }}>payment_Date</th>
                      <th className="tahe" style={{ textAlign: "center" }}>payment_Balance</th>
                      {/* <th style={{ textAlign: "center" }}>payment_bidingId</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {searchResult.map((result, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>{result.payment_userId}</td>
                        {/* <td>{result.payment_username}</td> */}
                        <td>{result.payment_idgroup}</td>
                        <td>{result.payment_namegroup}</td>
                        <td>{result.payment_month}</td>

                        <td>{result.payment_insNum}</td>

                        <td>{result.payment_tap}</td>
                        <td>{result.payment_hap}</td>
                        <td>{result.payment_Mode}</td>
                        <td>{result.payment_mobilenum}</td>

                        <td>
                          {moment(result.payment_Date).format("DD-MM-YYYY")}
                        </td>
                        <td>{result.payment_Balance}</td>
                        {/* <td>{result.payment_bidingId}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                {/* <p className="vwq">No Data Available</p> */}
              </div>
            )}
          </div>
        </div>

        {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
      </div>
    </div>
  );
};

export default TransactionHistory;
