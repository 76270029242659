import React from "react";
import "./Footer.css";
import chigeniuslogo from "../images/chigenius-logo.png";
import { Link } from "react-router-dom";
import { BsArrowReturnRight } from "react-icons/bs";
import { BsSuitHeartFill } from "react-icons/bs";

const image = { src: chigeniuslogo };

const Footer = () => {
  return (
    <div className="lg-footer">
      <div className="lg-footer-section">
        <div>
          <div className="lg-logo">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={image.src} className="lg-logo-13" alt="WiseInvest" />
              <div>
                <div className="lg-text-3">Chit Genius</div>
              </div>
            </Link>
          </div>
        </div>

        <div>
          <div className="lg-text-auction">
            <div className="lg-chit-plan">
              <h4>
                Wealth Fund <BsArrowReturnRight style={{ marginLeft:"10px" }} />
              </h4>
              <h4>
                Installment plan <BsArrowReturnRight style={{ marginLeft:"10px" }} />
              </h4>
              <h4>
                Bid online  <BsArrowReturnRight style={{ marginLeft:"10px" }} />
              </h4>
            </div>

            <div className="lg-chit-plan">
              {/* <h4 className="lg-wise">WiseInvest</h4> */}
              <div className="">
                <div>
                  <p>About Us</p>
                  <p>Careers</p>
                  <p>Contact Us</p>
                </div>
                
              </div>
            </div>

            <div className="lg-chit-plan">
              <div className="lg-chit-plannews">
                <p>News</p>
                <p>Why Us</p>
                <p>Branch Locator</p>
              </div>
            </div>

            <div className="lg-text-sect">
              <h4 className="lg-wise-1">Quick Links</h4>
              <div className="lg-text-plan">
                
                <p>Complaints</p>
                <p>Suggestions/</p>
                <p>Feedback</p>
              </div>
            </div>
          </div>
          <hr></hr>
        </div>
      </div>
      {/* <div className="lg-last-section">
        <p className="copy-text">Copyright 2023 - All rights reserved | Made with <BsSuitHeartFill className="heart"/> by ants digital</p>
      </div> */}
    </div>
  );
};

export default Footer;
