import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
// import {useNavigate} from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
// import SideBar from "../components/Sidebar/SideBar";
import Respside from "./Respside";
// import'./Infodata.css';
import { Link } from "react-router-dom";
import moment from "moment";
import { ReceiptionContext } from "../context/receiptioncontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import '../receiption/respgroupusers.css';
import userimage from "./images/userimage.png"

const Respgroupusers = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const { receiptionname } = useContext(ReceiptionContext);
  const [user, setuser] = useState({});

  const { userGroupId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/receptionist/new") {
        navigate("/receptionist/new");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/receiptionist") {
        navigate("/");
      }
      return;
    }

    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  const dltgroupuser = async (userGroupId) => {
    console.log(userGroupId);
    const res = await axios.delete(
      `https://api.chitgenius.com/api/delete/groupusers/${userGroupId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.status == 201) {
      // console.log("success")
      // getAssessmentData()
      //  setShow(true)
      navigate("/chitgroup");
    } else {
      console.log("error");
    }
  };

  const loadData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/api/chit/data/groupusers"
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  return (
    <div className="Resp-groupuser-main" style={{ display: "flex" }}>
      <div>
        <Respside />
      </div>
      <div className="Resp-groupuser-full">
        <div className="Resp-groupuser-head">
          <div className="Resp-groupuser-heading">
            <h2>Group users</h2>
          </div>
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
        <hr />
        <div className="Resp-searchContainer">
          <input
            type="search"
            placeholder="Search by Name"
            className="Resp-quizSearch"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
        <div className="">
          <div className="Resp-table-groupusers">
            <table className="Resp-table-style-Gu">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center"  }}>No.</th>
                  <th className="tahe" style={{ textAlign: "center"  }}>groupId</th>
                  <th className="tahe" style={{ textAlign: "center"  }}>groupName</th>
                  <th className="tahe" style={{ textAlign: "center"  }}>user_Name</th>
                  <th className="tahe" style={{ textAlign: "center"  }}>user_Id</th>
                  <th className="tahe" style={{ textAlign: "center" }}>DateOfCreation</th>
                  {/* <th style={{ textAlign: "center" }}>ACTION</th> */}
                </tr>
              </thead>
              <tbody>
                {data
                  .filter(
                    (data) =>
                      
                      data.userAddedDate.includes(search) ||
                      data.group_Id.toString() === search.trim()
                  )

                  .map((item, index) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{item.group_Id}</td>
                        <td style={{ textAlign: "center" }}>{item.group_Name}</td>
                        <td style={{ textAlign: "center" }}>{item.user_Name}</td>
                        <td style={{ textAlign: "center" }}>{item.user_Id}</td>
                        <td style={{ textAlign: "center" }}>
                          {moment(item.userAddedDate).format("DD-MM-YYYY")}
                        </td>
                        {/* <td>
                                <button onClick={()=>dltgroupuser(item.userGroupId)} className="ch">Delete</button>
                            </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Respgroupusers;
