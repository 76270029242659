

import React, { useState, useEffect, useContext } from "react";
import CollectingAgentSide from "./CollectingAgentSide";
import axios from "axios";
import { CollectionagentContext } from "../context/collectionagentcontext";
import { useNavigate, useLocation } from "react-router-dom";
import '../collectAgent/collectingagentdash.css'
import Cookies from "js-cookie";
import userimage from "./images/userimage.png"

const CollectionAgentDash = () => {
  const usernamedashboard = localStorage.getItem("username");
  const { collectionagentname } = useContext(CollectionagentContext);
  const [user, setuser] = useState({});
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/collectingagentdashboard") {
        navigate("/collectingagentdashboard");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/collectionagent") {
        navigate("/");
      }
      return;
    }

    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  //   const createdby =user.createdbyid;
  //   console.log(createdby);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const valueid = user.createdbyid;
  console.log(valueid);

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/api/getagentadmin/${valueid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [valueid]);

  return (
    <div className="collectingagent-dash" style={{ display: "flex" }}>
      <div>
        <CollectingAgentSide />
      </div>
      <div className="CA-dash-f">
        <div >
          <div className="CA-courses-head">
            <div className="CA-h2-heading">
              <h2>Dashboard</h2>
            </div>
            <div className="image">
              <div>
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  // style={{ height: "50px",borderRadius:'30px',width:"50px" }}
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
                {/* {user.createdbyid} */}
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="">
          <div className="CA-table-dashboard">
            <table className="CA-tables-style">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>No.</th>
                  <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>
                    groupId
                  </th>
                  <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>
                    groupName
                  </th>
                  <th className="tahe" style={{ textAlign: "center" }}>Auction Type</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Start Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>No Of Installments</th>
                  <th className="tahe" style={{ textAlign: "center" }}>End Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>No Of Users</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Commission</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Auction Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Date Of Creation</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Chit Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Chit Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "center" }}>{item.groupId}</td>
                    <td style={{ textAlign: "center" }}>{item.groupName}</td>
                    <td style={{ textAlign: "center" }}>{item.auctionType}</td>
                    <td style={{ textAlign: "center" }}>{item.startDate}</td>
                    <td style={{ textAlign: "center" }}>
                      {item.noOfInstallments}
                    </td>
                    <td style={{ textAlign: "center" }}>{item.endDate}</td>
                    <td style={{ textAlign: "center" }}>{item.noOfUsers}</td>
                    <td style={{ textAlign: "center" }}>{item.commission}</td>
                    <td style={{ textAlign: "center" }}>{item.auctionDate}</td>
                    <td style={{ textAlign: "center" }}>
                      {item.dateOfCreation}
                    </td>
                    <td style={{ textAlign: "center" }}>{item.chitAmount}</td>
                    <td style={{ textAlign: "center" }}>{item.chitstatus}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionAgentDash;
