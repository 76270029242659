import React from 'react'
import './communication.css';
import SideBar from '../components/Sidebar/SideBar';

const Communication = () => {
  return (
    <div style={{display:'flex'}}>
    <div>
    <SideBar/>
  </div>
    <div>Communication</div>
    </div>
  )
}

export default Communication;