import React, { createContext, useState } from "react";

export const ReceiptionContext = createContext();

export const ReceiptionProvider = ({ children }) => {
  const [receiptionname, setReceiptionname] = useState("");

  return (
    <ReceiptionContext.Provider value={{ receiptionname, setReceiptionname }}>
      {children}
    </ReceiptionContext.Provider>
  );
};