import React, { useState, useEffect, useContext } from "react";
// import Userside from '../usercompo/Userside';
import axios from "axios";
import { motion } from "framer-motion";
import "./profile.css";
import SideBar from "../components/Sidebar/SideBar";
import { UserContext } from "../context/usecontext";
import {useNavigate,useLocation} from "react-router-dom";
import { Link } from 'react-router-dom';
import userimage from "./images/userimage.png"
import Cookies from 'js-cookie';

const ProfileAdmin = () => {
  const usernamedashboard = localStorage.getItem("username");
  // const [data, setData] = useState([]);
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
      const token = Cookies.get('token');
      const { state } = location;

      if (token) {
         
          if (location.pathname !== '/profile') {
              navigate('/profile');
          }
          return;
      }

      if ( !state || !state.username || !state.role) {
        if (location.pathname !== '/admin') {
          navigate('/');
        }
        return;
      }
     
          setUsersname(state.username);
          setRole(state.role);
          console.log(state.username, state.role);
         
    }, [location, navigate]);



  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  // const loadData = async () => {
  //   const response = await axios.get("https://api.chitgenius.com/api/getall/usernames/adminid");
  //   setData(response.data);
  // };
  // useEffect(() => {
  //   loadData();
  // }, []);

  return (
    <div className="Ad-profile-main" style={{ display: "flex"}}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-profile-css">
      <div className=''>
        <div className='Ad-infoshead'>
          <div className="Ad-profile-heading">
            <h2>Profile</h2>
          </div>
        </div>
        <hr/>
        <div>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
            className=""
          >
          <div className='AD-TEXT'>
          <p>Complete Your Profile Here <Link to={`/adminedit/profilememberentry/${user.UserId}`} className='AD-edit'>Edit</Link></p>
          </div>
            <div className='Ad-profile-full' >
              <div className='Ad-display-001'>
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  // style={{ height: "50px",borderRadius:'30px',width:"50px" }}
                  className="images-1"
                ></img>
                {/* <div className='mtr'><label><strong>User Profile</strong></label></div> */}
              </div>
 
              <div className='Ad-pro'>
                <div className='Ad-display-pro'>
                  <label><strong>User Id</strong></label>
                  <p className='Ad-input-pro'>{user.UserId}</p>
                </div>
 
                <div className='Ad-display-pro'>
                  <label><strong>User Name</strong></label>
                  <p className='Ad-input-pro'>{user.UserName}</p>
                </div>
 
                <div className='Ad-display-pro'>
                  <label><strong>User Password</strong></label>
                  <p className='Ad-input-pro'>{user.userpassword}</p>
                </div>
              </div>
 
              <div className='Ad-pro'>
                <div className='Ad-display-pro'>
                  <label><strong>Date Of Birth</strong></label>
                  <p className='Ad-input-pro'>{user.UserDob}</p>
                </div>
 
                <div className='Ad-display-pro'>
                  <label><strong>Mobile No</strong></label>
                  <p className='Ad-input-pro'>{user.mobileNo}</p>
                </div>
 
                <div className='Ad-display-pro'>
                  <label><strong>Email</strong></label>
                  <p className='Ad-input-pro'>{user.email}</p>
                </div>
              </div>
 
              <div className='Ad-pro'>
                <div className='Ad-display-pro'>
                  <label><strong>Gender</strong></label>
                  <p className='Ad-input-pro'>{user.gender}</p>
                </div>
 
                <div className='Ad-display-pro'>
                  <label><strong>Occupation</strong></label>
                  <p className='Ad-input-pro'>{user.occupation}</p>
                </div>
 
                <div className='Ad-display-pro'>
                  <label><strong>Role</strong></label>
                  <p className='Ad-input-pro'>{user.role}</p>
                </div>
              </div>
 
              <div className='Ad-pro'>
              <div className='Ad-display-pro'>
                  <label><strong>Adhaar No</strong></label>
                  <p className='Ad-input-pro'>{user.adhaarNo}</p>
                </div>
 
                <div className='Ad-display-pro'>
                  <label><strong>Pan No</strong></label>
                  <p className='Ad-input-pro'>{user.panNo}</p>
                </div>
 
                <div className='Ad-display-pro'>
                  <label><strong>Address</strong></label>
                  <p className='Ad-input-pro'>{user.Address}</p>
                </div>
              </div>
            </div>
          </motion.div>
          </div>
         
 
         
      </div>


       
      </div>
    </div>
  );
};

export default ProfileAdmin;
