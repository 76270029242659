import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./Auctiondata.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import userimage from "./images/userimage.png"
// import { useNavigate } from "react-router-dom";

import SideBar from "../components/Sidebar/SideBar";
import "./Auctiondata.css";
import { UserContext } from "../context/usecontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Autiondata = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const { auctionId } = useParams();
  // const history = useNavigate();
  const [search, setSearch] = useState("");
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message


  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/auctiondetails") {
        navigate("/auctiondetails");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
      return;
    }

    setUsersname(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  const dltgroupuser = async (auctionId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const res = await axios.delete(
          `https://api.chitgenius.com/api/delete/auctiondataid/${auctionId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status === 201) {
          toast.success("Successfully deleted!");
          loadData();
          // navigate("/moreinfo");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    }
  };

 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const commonautionuserid = user.UserId;

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chit/allauctiondata/get/${commonautionuserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [commonautionuserid]);

  return (
    <div className="Ad-auctiondata-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-auctiondata-full">
        <div className="Ad-auctiondata-head">
          <div className="Ad-auctiondata-h">
            <div className="">
              <NavLink className="" to={"/moreinfo"}>
                <FaArrowLeftLong className="Ad-auctiondata-ume" />
              </NavLink>
            </div>
              <div className="Ad-auctiondata-heading">
                <h2>Auction Details</h2>
              </div>
          </div>

          <div className="image">
              <div>
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
              </div>
            </div>
        </div>
        <hr></hr>
        <div className="Ad-GU-searchContainer">
          <input
            type="search"
            placeholder="      Search by bidderName,groupid,auctiondate"
            className="Ad-GU-quizSearch"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
        <div className="">
          <div className="Ad-table-auctiondata">
            <table className="Ad-table-style-Gu">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>No.</th>
                  {/* <th style={{ textAlign: "center" }}>groupId</th> */}
                  <th className="tahe" style={{ textAlign: "center" }}>Auction Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Amount Due Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Installment Number</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Next Auction Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Bid Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Bider Name</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Group Id</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Group Name</th>
                  <th className="tahe" style={{ textAlign: "center" }}>chit Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>commission</th>
                  <th className="tahe" style={{ textAlign: "center" }}>DateOfCreation</th>

                  <th className="tahe" style={{ textAlign: "center" }}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .filter(
                    (data) =>
                      data.bidderName
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      data.auctionDate.includes(search) ||
                      data.group_id.toString() === search.trim()
                  )
                  .map((item, index) => {
                    return (
                      <tr style={{ margin: "0px" }}>
                        <td style={{ margin: "0px" }}>{index + 1}</td>
                        {/* <td>{item.auctionId}</td> */}
                        <td>{item.auctionDate}</td>
                        <td>{item.amountdueDate}</td>
                        <td>{item.InstallmentNumber}</td>
                        <td>{item.nextauctionDate}</td>
                        <td>{item.bidAmount}</td>
                        <td>{item.bidderName}</td>
                        <td>{item.group_id}</td>
                        <td>{item.group_name}</td>
                        <td>{item.chit_amount}</td>
                        <td>{item.commission_k}</td>
                        <td>
                          {moment(item.autionCreationDate).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <div className="Ad-gtf-Ad">
                            <div>
                              <Link to={`/update/autiondata/${item.auctionId}`}>
                                <button className="Ad-home-edit">Edit</button>
                              </Link>
                            </div>
                            <div>
                              <button
                                onClick={() => dltgroupuser(item.auctionId)}
                                className="Ad-auctiondata-ch"
                              >
                                Delete
                              </button>
                            </div>
                            <div>
                              <Link
                                to={`/biding/${item.auctionId}/${item.group_id}`}
                              >
                                <button className="Ad-home-edit-1">
                                  Biding
                                </button>
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {errorMessage && (
          <div className="popup">;
            <div className="popup-inner">
              <h3 className="popupheading">Warning!</h3>
              <p  className="popupmessage">{errorMessage}</p>
              <button className="popup-close" onClick={() => setErrorMessage("")}>Close</button>
           </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
   
  );
};

export default Autiondata;
