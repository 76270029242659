import React, { useState, useEffect, useContext } from "react";
import "./chitgroup.css";
import {
  AiOutlineShareAlt,
  AiOutlineInfoCircle,
  AiOutlineEye,
} from "react-icons/ai";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import SideBar from "../components/Sidebar/SideBar";
import { TbEditCircle } from "react-icons/tb";
import { RiAuctionLine } from "react-icons/ri";
import { IoCreateOutline } from "react-icons/io5";
import { UserContext } from "../context/usecontext";
import {useNavigate,useLocation} from "react-router-dom"
import Cookies from 'js-cookie';
import userimage from "./images/userimage.png"


const formatChitAmount = (amount) => {
  const numericAmount = parseFloat(amount.replace(/,/g, ""));
  let suffix = "";
  if (numericAmount >= 10000000) {
    suffix = "Cr";
  } else if (numericAmount >= 100000) {
    suffix = "L";
  }
  let formattedAmount = (
    numericAmount / (suffix === "Cr" ? 10000000 : 100000)
  ).toFixed(2);
  formattedAmount = formattedAmount.replace(/\.00$/, "") + suffix;
  return formattedAmount;
};

const Chitgroup = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});


  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
      const token = Cookies.get('token');
      const { state } = location;

      if (token) {
         
          if (location.pathname !== '/chitgroup') {
              navigate('/chitgroup');
          }
          return;
      }

      if ( !state || !state.username || !state.role) {
        if (location.pathname !== '/admin') {
          navigate('/');
        }
        return;
      }
     
          setUsersname(state.username);
          setRole(state.role);
          console.log(state.username, state.role);
         
    }, [location, navigate]);


  // const loadData = async () => {
  //   const response = await axios.get("https://api.chitgenius.com/chit/data/get");
  //   setData(response.data);
  // };
  // useEffect(() => {
  //   loadData();
  // }, []);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const loginuserid = user.UserId;

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chit/data/get/${loginuserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [loginuserid]);

  return (
    <div className="Admin-chitgroup-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-chitgroup-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className=""
        >
          <div>
            <div className="Ad-chitgroup-head">
              <div className="Ad-chitgroup-heading">
                <h2>Groups</h2>
              </div>

                <div className="image">
                  <div>
                    <img
                    src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                      // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                      alt=""
                      className="images"
                    ></img>
                  </div>
                  <div>
                    <p style={{ margin: "0px" }}>{user.UserName}</p>
                    <p style={{ margin: "0px" }}>({user.role})</p>
                  </div>
                </div>
              </div>
          </div>

          {/* <h1>Welcome, {username}</h1> */}

          <hr />
          <div className="Ad-chitgroup-AC-1">
          <NavLink className="create-course" to={"/createcourse"}>
            + Create
          </NavLink>
          </div>

          <div className="Ad-chitgroup-course-card">
            {data.map((item, index) => {
              return (
                <div className="Ad-chitgroup-full-card" key={index}>
                <div>
                    <Link
                      to={`/addusersintochit/${item.groupId}/${item.groupName}/${item.noOfUsers}`}
                      style={{ textDecorationLine: "none" }}
                    >
                      <div
                        className="Ad-chitgroup-card-chitgroup"
                        title={`Add users`}
                      >
                        <div className="Ad-chitgroup-logo-cont">
                          <AiOutlineShareAlt
                            className="Ad-chitgroup-share"
                            style={{ color: "black" }}
                          />
                        </div>
                        <Link
                          to={`/addusersintochit/${item.groupId}/${item.groupName}/${item.noOfUsers}`}
                          className="Ad-chitgroup-batch"
                          title={`Add users`}
                        >
                          <h4 className="Ad-chitgroup-vg-1">{`${item.groupId}/${
                            item.groupName
                          }/${formatChitAmount(item.chitAmount)}`}</h4>
                        </Link>
                      </div>
                    </Link>
                  </div>
                  <div className="Ad-chitgroup-batch-details">
                    <p className="Ad-chitgroup-id">
                      <strong>GroupID:</strong> {item.groupId}
                    </p>
                    <p className="Ad-chitgroup-id">
                      <strong>GroupName:</strong> {item.groupName}
                    </p>
                    <p className="Ad-chitgroup-id">
                      <strong>Group Members:</strong> {item.noOfUsers}
                    </p>
                    <p className="Ad-chitgroup-id">
                      <strong>CreatedBy:</strong> {item.createdby}
                    </p>
                  </div>
                  <hr />
                  <div className="Ad-chitgroup-bottom-icons">
                    <NavLink
                      style={{ color: "black" }}
                      to={`/information/${item.groupId}`}
                    >
                      <AiOutlineInfoCircle
                        className="Ad-chitgroup-card-icon"
                        title={`Information`}
                      />
                    </NavLink>

                    <NavLink
                      style={{ color: "black" }}
                      to={`/auction/${item.groupId}/${item.groupName}/${item.chitAmount}/${item.commission}/${item.noOfUsers}`}
                    >
                      <IoCreateOutline
                        className="Ad-chitgroup-card-icon"
                        title={`Auction`}
                      />
                    </NavLink>

                    <NavLink
                      style={{ color: "black" }}
                      to={`/allauctionsbygroupid/${item.groupId}`}
                    >
                      <RiAuctionLine className="Ad-chitgroup-card-icon" title={`Biding`} />
                    </NavLink>

                    <NavLink
                      style={{ color: "black" }}
                      to={`/allusers/${item.groupId}`}
                    >
                      <AiOutlineEye className="Ad-chitgroup-card-icon" title={`Allusers`} />
                    </NavLink>

                    <NavLink
                      style={{ color: "black" }}
                      to={`/edit/chit/${item.groupId}`}
                    >
                      <TbEditCircle className="Ad-chitgroup-card-icon" title={`Edit`} />
                    </NavLink>
                  </div>
                </div>
              );
            })}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Chitgroup;
