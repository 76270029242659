

import React, { useState, useEffect, useContext } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
// import { toast } from "react-toastify";
// import AgentSide from "../agentcompo/AgentSide";
import SideBar from "../components/Sidebar/SideBar";
// import { AgentContext } from "../context/agentcontext";
import { UserContext } from "../context/usecontext";
import { useLocation } from "react-router-dom";
 import Cookies from "js-cookie";
 import '../pages/CreateCourse.css';
 import { toast, ToastContainer } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css'
 import userimage from "./images/userimage.png"

const initialState = {
  groupName: "",
  auctionType: "",
  startDate: "",
  noOfInstallments: "",
  endDate: "",
  noOfUsers: "",
  commission: "",
  auctionDate: "",
  chitAmount: "",
  createdby: "",
  chitstatus: "",
};

export const CreateCourse = () => {
  const usernamedashboard = localStorage.getItem("username");
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});
  const [state, setState] = useState(initialState);
  const {
    groupName,
    auctionType,
    startDate,
    noOfInstallments,
    endDate,
    noOfUsers,
    commission,
    auctionDate,
    chitAmount,
    createdby,
    chitstatus,
  } = state;

  const navigate = useNavigate();

  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  const { groupId } = useParams();

  useEffect(() => {
    axios
      .get(`https://api.chitgenius.com/api/getchitid/${groupId}`)
      .then((resp) => setState({ ...resp.data[0] }));
  }, [groupId]);

  const calculateEndDate = () => {
    if (state.startDate && state.noOfInstallments) {
      const [startYear, startMonth, startDay] = state.startDate
        .split("-")
        .map(Number);
      const installments = parseInt(state.noOfInstallments);

      const newStartDate = new Date(startYear, startMonth - 1, startDay);
      newStartDate.setMonth(newStartDate.getMonth() + installments);

      const newEndDate = `${newStartDate.getFullYear()}-${(
        newStartDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${newStartDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;

      // Update the state with the new end date
      setState((prevState) => ({ ...prevState, endDate: newEndDate }));
    }
  };
  useEffect(() => {
    const endDate = calculateEndDate();
    if (endDate) {
      console.log("End Date:", endDate);
    }
  }, [state.startDate, state.noOfInstallments]);

  const validateGroupName = (name) => {
    const regex = /^(?=(?:[^a-zA-Z]*[a-zA-Z]){3})(?!^\d+$)(?!^[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=\|]+$)[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=\|]{1,10}$/;
    return regex.test(name);
  };

  const checkGroupNameExists = async (name) => {
    try {
      const response = await axios.get(`https://api.chitgenius.com/api/checkGroupName/${name}`);
      return response.data.exists;
    } catch (error) {
      console.error("Error checking group name", error);
      return false;
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateGroupName(groupName)) {
      alert("Group Name must contain at least 3 letters, 1 or 2 numbers, and one special character (+ or -).");
      return;
    }
    if (
      !groupName ||
      !auctionType ||
      !startDate ||
      !noOfInstallments ||
      !endDate ||
      !noOfUsers ||
      !commission ||
      !auctionDate ||
      !chitAmount ||
      !createdby
    ) {
      alert("Please fill all the fields");
      return;
    }

    if (parseInt(chitAmount) < 50000) {
      alert("Chit amount must be at least 50,000");
      return;
    }
    if (noOfUsers < 5) {
      alert("Number of Users must be at least 5");
      return;
    }
    if (noOfInstallments < 5) {
      alert("Number of Installments must be at least 5");
      return;
    }
    if (commission > 20) {
      alert("Commission must be at most 20%");
      return;
    }

    if (!groupId) {

      const groupNameExists = await checkGroupNameExists(groupName);
      if (groupNameExists) {
        alert("Group Name already exists. Please enter another group name.");
        return;
      }

      axios
        .post("https://api.chitgenius.com/api/chitcreate/post", {
          groupName,
          auctionType,
          startDate,
          noOfInstallments,
          endDate,
          noOfUsers,
          commission,
          auctionDate,
          chitAmount,
          createdby,
          chitstatus,
        })
        .then(() => {
          setState({
            groupName: "",
            auctionType: "",
            startDate: "",
            noOfInstallments: "",
            endDate: "",
            noOfUsers: "",
            commission: "",
            auctionDate: "",
            chitAmount: "",
            createdby: "",
            chitstatus: "",
          });
          toast.success("chit created successfully");
        })
        .catch((err) => toast.error(err.response.data));
        
    } else {
      axios
        .put(`https://api.chitgenius.com/api/chitcreate/update/${groupId}`, {
          groupName,
          auctionType,
          startDate,
          noOfInstallments,
          endDate,
          noOfUsers,
          commission,
          auctionDate,
          chitAmount,
          createdby,
          chitstatus,
        })
        .then(() => {
          setState({
            groupName: "",
            auctionType: "",
            startDate: "",
            noOfInstallments: "",
            endDate: "",
            noOfUsers: "",
            commission: "",
            auctionDate: "",
            chitAmount: "",
            createdby: "",
            chitstatus: "",
          });
          toast.success("chit  updated successfully");
        })
        .catch((err) => toast.error(err.response.data));
        
    }

    // setTimeout(() => {
    //   navigate("/chitgroup");
    // }, 500);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "commission" || name === "noOfInstallments" || name === "noOfUsers") &&
      !/^\d{0,2}$/.test(value)
    ) {
      return;
    }
    setState({ ...state, [name]: value });
  };

 

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
        setState((prevState) => ({
          ...prevState,
          createdby: response.data[0].UserId,
        }));
        // setState((prevState) => ({ ...prevState, createdby: `${response.data[0].UserId}_${response.data[0].role}` }));
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const creatorname = user.UserId;
  console.log(creatorname);

  return (
    <div className="admin-createcourse-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-cc-full">
        <div className="Ad-create-head">
          <div className="Ad-carrow">
            <div className="">
              <NavLink className="" style={{ color:"black" }} to={"/chitgroup"}>
                <FaArrowLeftLong className="Ad-fa"/>
              </NavLink>
            </div>
            <div className="Ad-cc-heading">
              <h2>Create/Edit Chit</h2>
            </div>
          </div>

          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>

        <hr style={{ marginTop: "0px" }}></hr>
        <div className="Ad-bd">
          <form className="" onSubmit={handleSubmit}>
            <div className="Ad-ngo">
              <div className="Ad-cc-display-11">
                <label className="mug"><strong>Group Name</strong></label>
                <input
                  type="text"
                  name="groupName"
                  class="Ad-cc-input-11"
                  value={groupName || ""}
                  placeholder="Group Name"
                  onChange={handleInputChange}
                  readOnly={!!groupId}
                />
              </div>

              <div className="Ad-cc-display-11">
                <label className="mug"><strong>AuctionType</strong></label>
                <select
                  name="auctionType"
                  className="Ad-cc-input-11"
                  value={auctionType || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select Auction Type</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>

              <div className="Ad-cc-display-11">
                <label className="mug"><strong>StartDate</strong></label>

                <input
                  type="date"
                  name="startDate"
                  class="Ad-cc-input-11"
                  value={startDate || ""}
                  placeholder="startDate"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="Ad-ngo">
              <div className="Ad-cc-display-11">
                <label className="mug"><strong>No.of Installments</strong></label>

                <input
                  type="number"
                  name="noOfInstallments"
                  value={noOfInstallments || ""}
                  class="Ad-cc-input-11"
                  placeholder="ex:10"
                  min="0"
                  max="99"
                  onChange={handleInputChange}
                />
              </div>

              <div className="Ad-cc-display-11">
                <label className="mug"><strong>EndDate</strong></label>

                <input
                  type="date"
                  name="endDate"
                  class="Ad-cc-input-11"
                  value={endDate || ""}
                  placeholder="endDate"
                  onChange={handleInputChange}
                />
              </div>

              <div className="Ad-cc-display-11">
                <label className="mug"><strong>No.of Users</strong></label>

                <input
                  type="number"
                  name="noOfUsers"
                  class="Ad-cc-input-11"
                  value={noOfUsers || ""}
                  placeholder="ex: 10"
                  min="0"
                  max="99"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="Ad-ngo">
              <div className="Ad-cc-display-11">
                <label className="mug"><strong>Commission(in Percentage)</strong></label>

                <input
                  type="number"
                  name="commission"
                  class="Ad-cc-input-11"
                  value={commission || ""}
                  placeholder="Ex:-4"
                  min="0"
                  max="99"
                  onChange={handleInputChange}
                />
              </div>
             

              <div className="Ad-cc-display-11">
                <label className="mug"><strong>Every Month Auction Date</strong></label>
                <select
                  name="auctionDate"
                  className="Ad-cc-input-11"
                  value={auctionDate || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {Array.from({ length: 31 }, (_, index) => index + 1).map(
                    (day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className="Ad-cc-display-11">
                <label className="mug"><strong>ChitAmount</strong></label>

                <input
                  type="number"
                  name="chitAmount"
                  class="Ad-cc-input-11"
                  value={chitAmount || ""}
                  placeholder="ex: (1,00,000) or(10,000,00)"
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>

            <div className="Ad-cc-display-11">
              <label className="mug"><strong>Createdby</strong></label>

              
              <input
                type="text"
                name="createdby"
                class="Ad-cc-input-11"
                value={createdby || ""}
                placeholder="ex: (1,00,000) or(10,000,00)"
                onChange={handleInputChange}
              >
                {/* <option value="">Select</option>
                  <option value="Agent">Agent</option> */}
              </input>
            </div>
            <div className="Ad-cc-display-01">
              <label className="jug"><strong>chit status</strong></label>
              <select
                name="chitstatus"
                className="Ad-cc-input-11"
                value={chitstatus || ""}
                onChange={handleInputChange}
              >
                <option value="">Select Status</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
            <div className="Ad-cc-hus">
              <input
                type="Submit"
                value={groupId ? "Update" : "save"}
                className="Ad-cc-cdf"
              />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};
