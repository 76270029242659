import React, { useState, useEffect, useContext } from "react";
import "./AgnDash.css";
import axios from "axios";
// import { Chartdata } from "./ChartData";
import { motion } from "framer-motion";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import AgentSide from "../agentcompo/AgentSide";
import { AgentContext } from "../context/agentcontext";
import {useNavigate,useLocation} from "react-router-dom"
import Cookies from 'js-cookie';
import userimage from "./images/userimage.png"




const AgnDash = () => {
  
  const usernamedashboard = localStorage.getItem("username");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  // const [cdata, setCData] = useState([]);
  const [Activedata, setActiveData] = useState([]);
  const [InActivedata, setInActiveData] = useState([]);
  const [Chartdata, setChartdata]=useState([]);
  const [userChartdata, setUserChartdata]=useState([]);
  const [paymentChartdata, setPaymentChartdata]=useState([]);
  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});
  const [countchitdata,setCountChitData]= useState([]);
 

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
      const token = Cookies.get('token');
      const { state } = location;

      if (token) {
         
          if (location.pathname !== '/agent/new') {
              navigate('/agent/new');
          }
          return;
      }

      if ( !state || !state.username || !state.role) {
        if (location.pathname !== '/agent') {
          navigate('/');
        }
        return;
      }
     
          setUsername(state.username);
          setRole(state.role);
          console.log(state.username, state.role);
         
    }, [location, navigate]);





  const [date, setDate] =useState(new Date());
  const onChange=()=>{
    setDate(date);
  };

  

  const fetchData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/payout/details"
    );
    setChartdata(response.data);
  };
  useEffect(() => {
    fetchData();
  }, []);


  const fetchUserData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/user/payment/details"
    );
    setUserChartdata(response.data);
  };
  useEffect(() => {
    fetchUserData();
  }, []);


  const fetchPaymentData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/Allchits/count/payout/payment/details"
    );
    setPaymentChartdata(response.data);
  };
  useEffect(() => {
    fetchPaymentData();
  }, []);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const logincountuserid= user.UserId;


  const loadcountchitData = async () => {
    const response = await axios.get(`https://api.chitgenius.com/count/dash/chit/admin/${logincountuserid}`);
    setCountChitData(response.data);
  };
  useEffect(() => {
    loadcountchitData();
  }, [logincountuserid]);



  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/member/count/Admin/chits/${logincountuserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [logincountuserid]);

  const loadDataInActiveChit = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chitsparticular/count/Admin/InActive/${logincountuserid}`
    );
    setInActiveData(response.data);
  };
  useEffect(() => {
    loadDataInActiveChit();
  }, [logincountuserid]);

  const loadDataActiveChit = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chitsparticular/count/Admin/Active/${logincountuserid}`
    );
    setActiveData(response.data);
  };
  useEffect(() => {
    loadDataActiveChit();
  }, [logincountuserid]);





  return (
    <div className="agent-container" style={{ display: "flex" }}>
      <div >
        <AgentSide />
      </div>

      <div className="Ag-userprofile-p">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className="Ag-courses"
        >
          <div className="Ag-courses-head">
            <div className="Ag-dash-heading">
              <h2>Agent Dashboard</h2>
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
              <div>
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  style={{ height: "50px", borderRadius: '30px', width: "50px" }}
                  className=""
                ></img>
              </div>
              <div>
                <p style={{ margin: '0px' }}>{user.UserName}</p>
                <p style={{ margin: '0px' }}>({user.role})</p>
              </div>
            </div>
          </div>
          <hr />
          <div className="Ag-cards-1">
            <div className="Ag-card-red">
              <p className="tip">NO OF MEMBERS</p>
              {data.map((opts, i) => (
                <option key={i} value={opts.Membercount}>
                  {opts.Membercount}
                </option>
              ))}
            </div>
            <div className="Ag-card-blue">
              <p className="tip">TOTAL CHITS</p>
              {countchitdata.map((opts, i) => (
                <option key={i} value={opts.chitcount}>
                  {opts.chitcount}
                </option>
              ))}
            </div>
            <div className="Ag-card-green">
              <p className="tip">ACTIVE CHITS</p>
              {Activedata.map((opts, i) => (
                <option key={i} value={opts.chitActivecount}>
                  {opts.chitActivecount}
                </option>
              ))}
            </div>
            <div className="Ag-card-purple">
              <p className="Ag-tip">INACTIVE CHITS</p>
              {InActivedata.map((opts, i) => (
                <option key={i} value={opts.chitInActivecount}>
                  {opts.chitInActivecount}
                </option>
              ))}
            </div>
          </div>
          <div className="Ag-second-part">
            <div className="Ag-chartdata-first">
              <h4 className="ag-h4">Payout Chart</h4>
              <div className="Ag-container-chart">
                <BarChart width={350} height={350} data={Chartdata}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis interval={25000} tickCount={5} domain={[0, 100000]} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="TP" fill="#8884d8" barSize={20} />
                  <Bar dataKey="HP" fill="#82ca9d" barSize={20} />
                </BarChart>
              </div>
            </div>
            <div className="Ag-main-charts">
            <div className="Ag-chartdata">
              <h4 className="ag-h4">Payment collection Chart</h4>
              <div className="Ag-container-chart">
                <BarChart width={350} height={350} data={userChartdata}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Ag-payment_month" />
                  <YAxis interval={25000} tickCount={5} domain={[0, 100000]} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="TP" fill="#8884d8" barSize={20} />
                  <Bar dataKey="HP" fill="#82ca9d" barSize={20} />
                </BarChart>
              </div>
              </div>
            </div>
            <div className="Ag-chartdata">
              <h4 className="ag-h4">Transaction Chart</h4>
              <div className="Ag-container-chart">
                <BarChart width={350} height={350} data={paymentChartdata}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis interval={10000} tickCount={5} domain={[0, 100000]} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="payout_HP" fill="#8884d8" barSize={20} />
                  <Bar dataKey="payment_HP" fill="#82ca9d" barSize={20} />
                </BarChart>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
    
  );
};

export default AgnDash;