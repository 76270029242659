import React from "react";
import "./Contact.css";
import Navbar from "./Navbar";
import { IoCall } from "react-icons/io5";
import Footer from "./Footer";

const Contactus = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <div className="lg-containerr">
        <h3 className="lg-contact-1">Contact Us</h3>
        <div className="lg-contact-info">
          <div className="lg-cont">
            <h6 className="lg-contact-textt">
              {" "}
              <IoCall style={{ color: "blue" }} />
              Monday - Saturday
            </h6>
            <h6 className="lg-contact-textt">
              {" "}
              <IoCall style={{ color: "blue" }} />
              10 am - 7 pm
            </h6>
            <h6 className="lg-contact-textt">
              {" "}
              <IoCall style={{ color: "blue" }} />
              info@Yatayati.COM
            </h6>
            <h6 className="lg-contact-textt">
              {" "}
              <IoCall style={{ color: "blue" }} />
              08886802039
            </h6>
            <div>
              <p className="lg-contact-textt1">Hyderabad,</p>
              <p className="lg-contact-textt1">Telangana</p>
            </div>
           
          </div>

          <div className="lg-text-section20">
            <h4 className="lg-head-text">You can reach us at:</h4>
            <div className="lg-text">
              <div className="lg-text-section1">
                <div className="lg-cards">
                  <cards>
                    <h6 className="lg-text-head">
                      Chit Genius Pvt Ltd.<br></br>Hyderabad (Corporate office)
                      :
                    </h6>
                    <p className="lg-para-text">
                      4th floor, above Zenius It services,
                      Om Sri Sai Nagar Colony, Old Alwal,
                      Secunderabad, Telangana 500010,India
                    </p>
                   
                  </cards>
                </div>
                <div className="lg-cards">
                  <cards>
                    <h6 className="lg-text-head">
                      Chit Genius Pvt Ltd.<br></br>Hyderabad :
                    </h6>
                    <p className="lg-para-text">
                      4th floor, above Zenius It services,
                      Om Sri Sai Nagar Colony, Old Alwal,
                      Secunderabad, Telangana 500010,India
                    </p>
                   
                  </cards>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Contactus;
