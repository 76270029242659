import React from "react";
import { motion } from "framer-motion";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useState } from "react";
import { useEffect, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "./AgentPayout.css";
import { FaLocationDot } from "react-icons/fa6";
import { AgentContext } from "../context/agentcontext";
import "./Chitgroup.css";
import AgentSide from "../agentcompo/AgentSide";
//import {toast} from "react-toastify";
import userimage from "./images/userimage.png"

const initialState = {
  month: "",
  paymentMode: "",
  amountToPay: "",
  balance: "",
  collectedby: "",
  paid_location: "",
};

const Agentpayout = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState(initialState);
  const [customData, setCustomData] = useState(initialState);
  const { bidingId } = useParams();
  const [address, setAddress] = useState("");
  const [paymentCollectionThisMonth, setPaymentCollectionThisMonth] =
    useState(""); // New state for payment collection this month

  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});

  const [balanceAmtToPay, setBalanceAmtToPay] = useState("");
  const [balanceAmount, setBalanceAmount] = useState("");

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const [tap, setTap] = useState("");
  const [hap, setHap] = useState("");
  const [result, setResult] = useState(null);

  const handleSuccessClick = () => {
    Swal.fire({
      title: "Payment made Successfully!",
      text: `Your action has been completed.
        Total Payment = ${data.payinsub};  
        Amount you paid = ${customData.amountToPay}\n
        Balance = ${data.payinsub - customData.amountToPay}`,
      icon: "Success",
    });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      console.log(latitude, longitude);
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => setAddress(data.address))
        .catch((error) => console.error("Error fetching location:", error));
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/bid/getbidid/${bidingId}`
        );
        setData({ ...response.data[0] });

        // // Update state with fetched data
        setData({
          ...response.data[0],
        });
      } catch (error) {
        console.error("Error fetching biding data", error);
      }
    };
    fetchData();
  }, [bidingId]);

  useEffect(() => {
    const fetchBalanceAmtToPay = async () => {
      try {
        const response = await axios.post(
          `https://api.chitgenius.com/api/paymentout/balancepayment`,
          {
            namegroup: data.name_grp,
            mobileNumber: data.bid_winner,
            insNum: data.install_numb,
          }
        );
        var balAmtToPay =
          (isNaN(data.payinsub) ? 0 : data.payinsub) -
          (isNaN(response.data) ? 0 : response.data);
        var balAmt =
          balAmtToPay -
          (isNaN(customData.amountToPay) ? 0 : customData.amountToPay);
        setBalanceAmtToPay(balAmtToPay);
        setBalanceAmount(balAmt);
      } catch (error) {
        console.error(error);
        // setTotalBalanceAmt("");
        setBalanceAmount("");
      }
    };
    fetchBalanceAmtToPay();
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!customData.month || !customData.amountToPay || !customData.paymentMode) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill all the fields',
      });
      return;
    }
    // if(!data.name_grp || !customData.month || !data.install_numb || !data.bid_winner || !data.payinsub || !customData.amountToPay || !customData.paymentMode || !customData.balance) {
    //   alert.error("please provide value into each input field");
    // } else {
    if (bidingId) {
      axios
        .post("https://api.chitgenius.com/api/paymentout/post", {
          idgroup: data.id_group,
          namegroup: data.name_grp,
          biding_id: data.bidingId,

          month: customData.month,
          insNum: data.install_numb,
          bidwinner: data.bid_winner,
          winnerid: data.winner_id,
          tap: data.payinsub,
          hap: customData.amountToPay,
          paymentMode: customData.paymentMode,
          balance: customData.balance,
          status: null,
          collectedby: user.UserId,
          paid_location: addressParagraph,
          paymentCollectionThisMonth: paymentCollectionThisMonth,
        })
        .then(() => {
          handleSuccessClick();
          setData(initialState);
        })
        .catch((err) => console.error(err.response.data));
      console.success("Saved Sussessfully");
    }
    // }
  };

  const addressParagraph = `${address.road}, ${address.neighbourhood}, ${address.state}, ${address.city}, ${address.state_district}, ${address.postcode}, ${address.country}`;

  useEffect(() => {
    if (
      data.name_grp &&
      customData.month &&
      data.id_group &&
      data.install_numb
    ) {
      const fetchPaymentCollection = async () => {
        try {
          const response = await axios.get(
            `https://api.chitgenius.com/api/getPaymentCollectionThisMonth/${data.id_group}/${data.name_grp}/${customData.month}/${data.install_numb}`
          );
          // Check if response.data is null or empty
          if (response.data && response.data.length > 0) {
            // response.data is an array containing an object
            // Access the payment_hap value from the first object in the array
            const sumPaymentHap = response.data[0].total_payment_hap || 0;
            setPaymentCollectionThisMonth(sumPaymentHap); // Set the state with the sum value
          } else {
            // If result is null or empty, set paymentCollectionThisMonth to 0
            setPaymentCollectionThisMonth(0);
          }
        } catch (error) {
          console.error("Error fetching payment collection this month", error);
        }
      };
      fetchPaymentCollection();
    }
  }, [data.name_grp, customData.month, data.id_group, data.install_numb]);

  return (
    <div className="Agent-Payout-main" style={{ display: "flex", overflowX: "hidden"}}>
      <div>
        <AgentSide />
      </div>
      <div >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4 }}
        className="Ag-payoutadmin-full"
      >
        <div className="Ad-payoutadmin-head">
          <div className="Ad-h">
            <NavLink
              style={{ color: "black"}}
              to={"/agentchits"}
            >
              <FaArrowLeftLong className="Ad-ume"/>
            </NavLink>
            <div className="dash-heading">
              <h2>Pay out</h2>
            </div>
          </div>
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
        <hr style={{ margin: "10px 0px" }}></hr>
        <div className="Ad-payin-9">
          <p className="Ad-cpayin-10">
            <FaLocationDot />
          </p>
          <input type="text" className="Ad-cpayin-9" value={addressParagraph} />
        </div>
        <form
          className="Ad-info-container"
          style={{ width: "75%", marginTop: "25px" }}
          onSubmit={handleSubmit}
        >
          <div>
            <div className="Ad-payout-hk">
              <div className="Ad-payout-display-44">
                <label>
                  <strong>Group Id :</strong>
                </label>
                <input
                  type="text"
                  name="idgroup"
                  className="Ad-payout-input-44"
                  value={data.id_group}
                  disabled
                />
              </div>

              <div className="Ad-payout-display-44">
                <label>
                  <strong>Group Name :</strong>
                </label>
                <input
                  type="text"
                  name="namegroup"
                  className="Ad-payout-input-44"
                  value={data.name_grp}
                  disabled
                />
              </div>

              <div className="Ad-payout-display-44">
                <label>
                  <strong>Biding Id :</strong>
                </label>
                <input
                  type="text"
                  name="biding_id"
                  className="Ad-payout-input-44"
                  value={data.bidingId}
                  disabled
                />
              </div>
            </div>

            <div className="Ad-payout-hk">
              <div className="Ad-payout-display-44">
                <label>
                  <strong>Month :</strong>
                </label>
                <select
                  name="dropdown"
                  className="Ad-payout-input-44"
                  value={customData.month}
                  onChange={(event) => {
                    setCustomData({ ...customData, month: event.target.value });
                  }}
                >
                  <option value="" disabled select>
                    Select Month
                  </option>
                  <option value="Jan">January</option>
                  <option value="Feb">February</option>
                  <option value="Mar">March</option>
                  <option value="Apr">April</option>
                  <option value="May">May</option>
                  <option value="Jun">June</option>
                  <option value="Jul">July</option>
                  <option value="Aug">August</option>
                  <option value="Sep">September</option>
                  <option value="Oct">October</option>
                  <option value="Nov">November</option>
                  <option value="Dec">December</option>
                </select>
              </div>
              <div className="Ad-payout-display-44">
                <label>
                  <strong>Bid Winner :</strong>
                </label>
                <input
                  type="text"
                  name="bidwinner"
                  className="Ad-payout-input-44"
                  value={data.bid_winner}
                  disabled
                />
              </div>

              <div className="Ad-payout-display-44">
                <label>
                  <strong> Winner Id :</strong>
                </label>
                <input
                  type="text"
                  name="winnerid"
                  className="Ad-payout-input-44"
                  value={data.winner_id}
                  disabled
                />
              </div>
            </div>

            <div className="Ad-payout-hk">
              <div className="Ad-payout-display-44">
                <label>
                  <strong>Installment Number :</strong>
                </label>
                <input
                  type="text"
                  name="insNum"
                  className="Ad-payout-input-44"
                  value={data.install_numb}
                  placeholder="install_numb"
                  disabled
                />
              </div>

              <div className="Ad-payout-display-44">
                <label>
                  <strong>Pay Through :</strong>
                </label>
                <select
                  name="dropdown"
                  className="Ad-payout-input-44"
                  value={customData.paymentMode}
                  onChange={(event) => {
                    setCustomData({
                      ...customData,
                      paymentMode: event.target.value,
                    });
                  }}
                >
                  <option value="" disabled select>
                    Pay Through
                  </option>
                  <option value="phone pay">Phone pay</option>
                  <option value="google pay">Google pay</option>
                  <option value="cash">Cash</option>
                </select>
              </div>

              <div className="Ad-payout-display-44">
                <label>
                  <strong>Total Amount :</strong>
                </label>
                <input
                  type="text"
                  name="tap"
                  className="Ad-payout-input-44"
                  value={data.payinsub}
                  onChange={(e) => setTap(e.target.value)}
                  disabled
                />
              </div>
            </div>

            <div className="Ad-payout-hk">
              <div className="Ad-payout-display-44">
                <label className="">
                  <strong>Collected By :</strong>
                </label>
                <input
                  type="text"
                  name="collectedby"
                  className="Ad-payout-input-44"
                  value={user.UserId}
                  disabled
                />
              </div>

              <div className="Ad-payout-display-44">
                <label>
                  <strong>Enter Amount To Pay :</strong>
                </label>
                <input
                  type="text"
                  name="payamount"
                  className="Ad-payout-input-44"
                  value={customData.amountToPay}
                  onChange={(event) => {
                    var amountToPay = Number(event.target.value);
                    var bal = balanceAmtToPay - amountToPay;
                    if (bal >= 0) {
                      setCustomData({
                        ...customData,
                        amountToPay: amountToPay,
                        balance: bal,
                       
                      });
                      setBalanceAmount(bal);
 
                    } else {
                      alert("Amount should not be greater than 'Balance to pay'!! please enter valid amount.");
                    }
                    // setCustomData({
                    //   ...customData,
                    //   amountToPay: amountToPay,
                    //   balance: bal,
                    // });
                    // setBalanceAmount(bal);
                  }}
                  placeholder="Enter Amount:"
                />
              </div>

              <div className="Ad-payout-display-44">
                <label>
                  <strong>Balance to pay</strong>
                </label>
                <input
                  type="text"
                  name="balanceAmountToPay"
                  className="Ad-payout-input-44"
                  value={balanceAmtToPay}
                  disabled
                />
              </div>
            </div>

            <div className="hk-agent">
              <div className="Ad-payout-display-44">
                <label>
                  <strong>Payment Collection This Month :</strong>
                </label>
                <input
                  type="text"
                  name="paymentcollectionthismonth"
                  className="Ad-payout-input-44"
                  value={paymentCollectionThisMonth}
                  disabled
                />
              </div>

              <div className="Ad-payout-display-404">
                <label>
                  <strong>Balance :</strong>
                </label>
                <input
                  type="text"
                  name="balanceAmount"
                  className="Ad-payout-input-44"
                  value={balanceAmount}
                  disabled
                />
              </div>
            </div>

            <div className="">
              <button
                type="Submit"
                onSubmit={handleSubmit}
                className="Ad-payout-button-66"
                value={data.id_group}
              >
                Make Payment
              </button>
            </div>
          </div>
        </form>
      </motion.div>
    </div>
    </div>
  );
};

export default Agentpayout;
