import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { CustomerContext } from "../context/customercontext";
import Usermemberpassbook from '../userpages/Usermemberpassbook';
import Userdash from '../userpages/Userdash';


export const User = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const {customername, setCustomername}=useContext(CustomerContext);



  useEffect(() => {
    const token = Cookies.get('token');
    const { state } = location;

    if (!token || !state || !state.username || !state.role) {
      navigate('/');
    } else {
      setCustomername(state.username);
    //   setRole(state.role);
    //   console.log(state.username, state.role); // Log username and role
    }
  }, [location, navigate]);

  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
  };

  return (
    <div>
      {/* <h2>Welcome to the Parent Page!</h2> */}
      {/* <p>Username: {customername}</p> */}
      {/* <p>Role: {role}</p>
      <button onClick={handleLogout}>Logout</button> */}
      <Userdash/>
    </div>
  );
};
