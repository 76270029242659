import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Chitgroup from "./pages/Chitgroup";
import MemberEntry from "./pages/MemberEntry";
import Memberpassbook from "./pages/Memberpassbook";
import Paymentreport from "./pages/Paymentreport";
import Auction from "./pages/auction";
import Information from "./pages/Information";
import Biding from "./pages/biding";
import Allusers from "./pages/allusers";
import Addexisting from "./pages/addexisting";
import Payout from "./pages/Payout";
import Profile from "./pages/profile";
import Communication from "./pages/communication";
import AgentChitgroup from "./agentpages/AgentChitgroup";
import { Agentcreategroup } from "./agentpages/Agentcreategroup";
// import Agentuserentry from "./agentpages/Agentuserentry";
import Agentmb from "./agentpages/Agentmb";
import Agentpayout from "./agentpages/Agentpayout";
import Agentprofile from "./agentpages/Agentprofile";
import Agentcomm from "./agentpages/Agentcomm";
// import Userpayreport from "./userpages/Userpayreport";
import Userpro from "./userpages/Userpro";
import MoreInfo from "./pages/MoreInfo";
import Autiondata from "./pages/Autiondata";
import { CreateCourse } from "./pages/CreateCourse";
import AgnDash from "./agentpages/AgnDash";
import Infodata from "./pages/Infodata";
import Allauctions from "./pages/Allauctions";
import Detailbiding from "./pages/Detailbiding";
import Searchtest from "../src/pages/Searchtest";
import Agentchitinfo from "./agentpages/Agentchitinfo";
import AgentAuction from "./agentpages/AgentAuction";
import AgentAllAuctions from "./agentpages/AgentAllAuctions";
import Agentbiding from "./agentpages/Agentbiding";
import AgentAddexisting from "./agentpages/AgentAddexisting";
import AgentAllusers from "./agentpages/AgentAllusers";
import Agentbidingdetails from "./agentpages/Agentbidingdetails";
import Home from "./pages/Home";
import Newtrans from "./pages/Newtrans";
import Payoutadmin from "./pages/Payoutadmin";
import Userpayreport from "./userpages/Userpayreport";
import Userpayment from "./userpages/Userpayment";
import Usermemberpassbook from "./userpages/Usermemberpassbook";
import AgentAllpayout from "./agentpages/AgentAllpayout";
import Agentmemberentry from "./agentpages/Agentmemberentry";
import Paymentcollectiondetails from "./pages/Paymentcollectiondetails";
import Agentpay from "./agentpages/Agentpay";
import Allpayoutdetailsadmin from "./pages/Allpayoutdetailsadmin";
import TransactionHistory from "./pages/TransactionHistory";
import Transdate from "./pages/Transdate";
import Autotest from "./pages/Autotest";
import TransactionByUseris from "./pages/TransactionByUseris";
import RespByUseris from "./receiption/RespByUseris";
import RespPaymentcollection from "./receiption/RespPaymentcollection";
import Respgroupusers from "./receiption/Respgroupusers";
import RespProfile from "./receiption/respProfile";
import Resppayout from "./receiption/Resppayout";
import RespBiddingdetails from "./receiption/RespBiddingdetails";
import RespAuctiondetails from "./receiption/RespAuctiondetails";
import CollectingAgentPayin from "./collectAgent/CollectingAgentPayin";
import CollectingAgentProfile from "./collectAgent/CollectingAgentProfile";
import { Loginpage } from "../src/pages/loginpage";
import { Admin } from "../src/pages/admin";
import { Agent } from "../src/pages/agent";
import { User } from "../src/pages/user";
import { Superadmin } from "./pages/superadmin";
import { Receiption } from "./pages/receiption";
import { Collectionagent } from "./pages/collectionagent";
import Grablocation from "./collectAgent/Grablocation";
import Superpro from "./superpages/Superpro";
import CollectionAgentDash from "./collectAgent/CollectionAgentDash";
import Userdash from "./userpages/Userdash";
import SupAdmAucDetails from "./superpages/SupAmdAucDetails";
import SupAdmAuction from "./superpages/SupAdmAuction";
import SuperDash from "./superpages/SuperDash";
import SuperME from "./superpages/SuperME";
import { UserProvider } from "../src/context/usecontext";
import { AgentProvider } from "../src/context/agentcontext";
import { CustomerProvider } from "../src/context/customercontext";
import { SuperadminProvider } from "../src/context/superadmincontext";
import { ReceiptionProvider } from "../src/context/receiptioncontext";
import { CollectionagentProvider } from "./context/collectionagentcontext";
import SupAdmBiddetails from "./superpages/SupAdmBiddetails";
import SupAdmChitInfo from "./superpages/SupAdmChitInfo";
import Detailsofuserpayment from "./collectAgent/Detailsofuserpayment";
import Registrationpage from "./pages/Registrationpage";
import Usermember from "./userpages/Usermember";
import Parentcomponent from "./pages/Parentcomponent";
import Main from "./landingpage/lpages/Main";
import Contactus from "./landingpage/lpages/Contactus";
import Resppayin from "./receiption/Resppayin";
import Numword from "./superpages/Numword";
import Agentpayin from "./agentpages/Agentpayin";
import Agentpaymentcollection from "./agentpages/Agentpaymentcollection";
import { Forgetpassword } from "./pages/Forgetpassword";
import { SupAdmChitEdit } from "./superpages/SupAdmChitEdit";
import Editmemberadmin from "./pages/Editmemberadmin";
import AdminEditprofile from "./pages/AdminEditprofile";
import AgentEditprofile from "./agentpages/AgentEditprofile";
import SuperAdminEditprofile from "./superpages/SuperAdminEditprofile";
import RespEditprofile from "./receiption/RespEditprofile"
import CollectAgentEditprofile from "./collectAgent/CollectAgentEditprofile";
import Supermemberdetails from "./superpages/Supermemberdetails";
import Supermembereditdetails from "./superpages/Supermembereditdetails"
import Agentmembercollectiondetails from "./agentpages/Agentmembercollectiondetails";

function App() {
  return (
    <BrowserRouter>
      <div>
        <div>
          <UserProvider>
            <AgentProvider>
              <CustomerProvider>
                <SuperadminProvider>
                  <ReceiptionProvider>
                    <CollectionagentProvider>
                      <Routes>
                        <Route path="/" element={<Main/>}></Route>
                        <Route path="/contact" element={<Contactus/>}></Route>
                        <Route path="/for" element={<Forgetpassword/>}></Route>


                        <Route path="/land" element={<Parentcomponent/>}></Route>
                       
                        <Route path="/autotest" element={<Autotest />}></Route>
                        <Route path="/admin" element={<Admin />}></Route>
                        <Route path="/agent" element={<Agent />}></Route>
                        <Route
                          path="/superadmin"
                          element={<Superadmin />}
                        ></Route>
                        <Route path="/dash" element={<Dashboard />} />
                        <Route path="/chitgroup" element={<Chitgroup />} />
                        <Route path="/memberentry" element={<MemberEntry />} />
                        <Route
                          path="/agent/membertry"
                          element={<Agentmemberentry />}
                        ></Route>
                        <Route path="/dashboard/new" element={<Dashboard />} />
                        <Route
                          path="/auction/:groupId/:groupName/:chitAmount/:commission/:noOfUsers"
                          element={<Auction />}
                        />
                        <Route
                          path="/agent/auction/:groupId/:groupName/:chitAmount/:commission/:noOfUsers"
                          element={<AgentAuction />}
                        />
                        <Route
                          path="/information/:groupId"
                          element={<Information />}
                        />
                        <Route
                          path="/memberpassbook"
                          element={<Memberpassbook />}
                        />
                        <Route
                          path="/paymentreport"
                          element={<Paymentreport />}
                        />
                        <Route
                          path="/createcourse"
                          element={<CreateCourse />}
                        />
                        <Route
                          path="/biding/:auctionId/:group_id"
                          element={<Biding />}
                        />
                        <Route
                          path="/agent/biding/:auctionId/:group_id"
                          element={<Agentbiding />}
                        />
                        <Route path="/newtrans" element={<Newtrans />}></Route>
                        <Route
                          path="/userpay/paymentreport"
                          element={<Userpayreport />}
                        ></Route>
                        <Route
                          path="/Allusers/:groupId"
                          element={<Allusers />}
                        />
                        <Route
                          path="/agent/Allusers/:groupId"
                          element={<AgentAllusers />}
                        />
                        <Route
                          path="/addusersintochit/:groupId/:groupName/:noOfUsers"
                          element={<Addexisting />}
                        ></Route>
                        <Route
                          path="/agent/addusersintochit/:groupId/:groupName/:noOfUsers"
                          element={<AgentAddexisting />}
                        ></Route>
                        <Route
                          path="/aallpaymentcollectiondetails/:groupName"
                          element={<Agentmembercollectiondetails />}
                        ></Route>
                        <Route
                          path="/userpayout/:bidingId"
                          element={<Userpayment />}
                        ></Route>
                        <Route
                          path="/user/dashboard"
                          element={<Userdash />}
                        ></Route>
                        <Route
                          path="/auctiondetails"
                          element={<Autiondata />}
                        ></Route>
                        {/* <Route path="/Payout/:bidingId" element={<Payout/>}/> */}
                        <Route
                          path="/payout/:bidingId"
                          element={<Payoutadmin />}
                        ></Route>
                        <Route
                          path="/aallpaymentsout/:groupId"
                          element={<AgentAllpayout />}
                        ></Route>
                        <Route path="/Profile" element={<Profile />} />
                        <Route
                          path="/Communication"
                          element={<Communication />}
                        />
                        <Route path="/agent/new" element={<AgnDash />}></Route>
                        <Route
                          path="/agentchits"
                          element={<AgentChitgroup />}
                        ></Route>
                        <Route
                          path="/agentcreatechit"
                          element={<Agentcreategroup />}
                        ></Route>
                        {/* <Route path="/aue" element={<Agentuserentry />}></Route> */}
                        <Route path="/amb" element={<Agentmb />}></Route>
                        {/* <Route path="/apr" element={<Agentpayreport />}></Route> */}
                        <Route
                          path="/agent/payout/:bidingId"
                          element={<Agentpayout />}
                        ></Route>
                        <Route path="/ap" element={<Agentprofile />}></Route>
                        <Route path="/acomm" element={<Agentcomm />}></Route>
                        <Route path="/user" element={<User />}></Route>
                        <Route
                          path="/user/new"
                          element={<Usermemberpassbook />}
                        ></Route>
                        {/* <Route path="/upr" element={<Userpayreport />}></Route>   */}
                        <Route path="/uprofile" element={<Userpro />}></Route>
                        <Route path="/briefinfo" element={<Infodata />}></Route>
                        <Route path="/moreinfo" element={<MoreInfo />}></Route>
                        <Route
                          path="/edit/chit/:groupId"
                          element={<CreateCourse />}
                        ></Route>
                        <Route path="/supermemberdetails" element={<Supermemberdetails/>}/>
                        <Route path="/supermembereditdetails/:UserId" element={<Supermembereditdetails/>}/>
                        <Route
                          path="/update/autiondata/:auctionId"
                          element={<Auction />}
                        ></Route>
                        <Route
                          path="/agent/update/autiondata/:auctionId"
                          element={<AgentAuction />}
                        ></Route>
                        <Route
                          path="/allauctionsbygroupid/:groupId"
                          element={<Allauctions />}
                        ></Route>
                        <Route
                          path="/agent/allauctionsbygroupid/:groupId"
                          element={<AgentAllAuctions />}
                        ></Route>
                        <Route
                          path="/agent/allbidingbygroupid/:groupId"
                          element={<Agentbidingdetails />}
                        ></Route>
                        <Route
                          path="/home/bid"
                          element={<Paymentcollectiondetails />}
                        ></Route>
                        <Route
                          path="/detailbiding"
                          element={<Detailbiding />}
                        ></Route>
                        <Route
                          path="/testsearch"
                          element={<Searchtest />}
                        ></Route>
                        <Route
                          path="/agentchitinfo/:groupId"
                          element={<Agentchitinfo />}
                        ></Route>
                        <Route
                          path="/agent/edit/chit/:groupId"
                          element={<Agentcreategroup />}
                        ></Route>
                        <Route path="/home" element={<Home />}></Route>
                        <Route
                          path="/memberentry/update/:UserId"
                          element={<Editmemberadmin />}
                        ></Route>
                        <Route
                          path="/getdata/agent/paymentcollection"
                          element={<Agentpay />}
                        ></Route>
                        <Route
                          path="/allpayout/details/admin"
                          element={<Allpayoutdetailsadmin />}
                        ></Route>
                        <Route
                          path="/transhistory"
                          element={<TransactionHistory />}
                        ></Route>
                        <Route path="/tdate" element={<Transdate />}></Route>
                        <Route
                          path="/user/pc"
                          element={<TransactionByUseris></TransactionByUseris>}
                        ></Route>
                        <Route
                          path="/superadmin/dash"
                          element={<SuperDash />}
                        ></Route>
                        <Route
                          path="/superadmin/adminentry"
                          element={<SuperME />}
                        ></Route>

                        <Route
                          path="/update/superadminautiondata/:auctionId"
                          element={<SupAdmAuction />}
                        />

                        <Route
                          path="/superadminbidingdetails"
                          element={<SupAdmBiddetails />}
                        />

                        <Route
                          path="/receiptionist"
                          element={<Receiption />}
                        ></Route>

                        {/* <Route
                        path="/receptionist"
                        element={<Respgroupusers />}
                      /> */}
                        <Route
                          path="/receptionist/new"
                          element={<Respgroupusers />}
                        />
                        <Route
                          path="/respauctiondetails"
                          element={<RespAuctiondetails />}
                        />
                        <Route
                          path="/respbiddingdetails"
                          element={<RespBiddingdetails />}
                        />
                        <Route
                          path="/resppaymentcollectiondetails"
                          element={<RespPaymentcollection />}
                        />
                        <Route path="/resppayout" element={<Resppayout />} />
                        <Route
                          path="/respbyuseris"
                          element={<RespByUseris />}
                        />
                        <Route path="/resppayin" element={<Resppayin/>}/>
                        <Route path="/respprofile" element={<RespProfile />} />

                        <Route path="/superprofile" element={<Superpro />} />

                        <Route
                          path="/collectionagent"
                          element={<Collectionagent />}
                        />
                        <Route
                          path="/collectingagentpayin"
                          element={<CollectingAgentPayin />}
                        />
                        <Route
                          path="/collectingagentprofile"
                          element={<CollectingAgentProfile />}
                        />
                        <Route
                          path="/collectingagentdashboard"
                          element={<CollectionAgentDash />}
                        ></Route>
                        <Route
                        path="/collectiondetails"
                        element={<Detailsofuserpayment/>}
                        ></Route>
                        <Route
                          path="/payinmylocation"
                          element={<Grablocation />}
                        />

                        <Route
                          path="/superadmin/auction/details"
                          element={<SupAdmAucDetails />}
                        ></Route>

                        <Route
                          path="/superadminchittable"
                          element={<SupAdmChitInfo />}
                        ></Route>
                        
                        <Route path="/register"
                        element={<Registrationpage/>}></Route>

                        <Route path="/user/registerd/update/:UserId" element={<Usermember/>}></Route>

                        <Route path="*" element={<> not found</>} />

                        <Route path="/numword" element={<Numword/>}/>

                        <Route path="/agentpayin/:bidingId" element={<Agentpayin/>}/>

                        <Route path="/agentpaymentcollection" element={<Agentpaymentcollection/>}/>
                        <Route
                          path="/super/admin/edit/chit/:groupId"
                          element={<SupAdmChitEdit />}
                        ></Route>
                        <Route
                          path="/adminedit/profilememberentry/:UserId"
                          element={<AdminEditprofile />}
                        ></Route>
                        <Route
                          path="/agentedit/profilememberentry/:UserId"
                          element={<AgentEditprofile />}
                        ></Route>
                         <Route
                          path="/superadminedit/profilememberentry/:UserId"
                          element={<SuperAdminEditprofile />}
                        ></Route>
                        <Route
                          path="/respedit/profilememberentry/:UserId"
                          element={<RespEditprofile />}
                        ></Route>
                        <Route
                          path="/collectionagentedit/profilememberentry/:UserId"
                          element={<CollectAgentEditprofile />}
                        ></Route>
                      </Routes>
                    </CollectionagentProvider>
                  </ReceiptionProvider>
                </SuperadminProvider>
              </CustomerProvider>
            </AgentProvider>
          </UserProvider>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
