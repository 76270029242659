import React from "react";
import { NavLink } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { motion } from "framer-motion";
import SideBar from "../components/Sidebar/SideBar";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useEffect, useState, useContext } from "react";
import "./Allauctions.css";
import { UserContext } from "../context/usecontext";
import userimage from "./images/userimage.png"

const Allauctions = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const { groupId } = useParams(); // Extract the groupId from the URL path

  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/auctionsByGroupId/${groupId}`
        ); // Use the groupId from the URL path
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [groupId]);

  return (
    <div className="Admin-allauction-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-allauction-full">
        <div className="Ad-allauction-head">
          <div className="Ad-h">
            <div className="">
              <NavLink className="" to={"/chitgroup"}>
                <FaArrowLeftLong className="Ad-ume" />
              </NavLink>
            </div>
            <div className="Ad-allauction-heading">
              <h2>Auction Details</h2>
            </div>
          </div>
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="">
          <div className="Ad-allauction-table-allusers">
            <table className="Ad-table-style-Gu">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center", margin: "0px" }}>No.</th>
                  {/* <th style={{ textAlign: "center" }}>groupId</th> */}
                  <th className="tahe" style={{ textAlign: "center" }}>Auction Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Amount Due Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Installment Number</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Next Auction Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Bid Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Bider Name</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Group Id</th>
                  <th className="tahe" style={{ textAlign: "center" }}>Group Name</th>
                  <th className="tahe" style={{ textAlign: "center" }}>chit Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>commission</th>
                  <th className="tahe" style={{ textAlign: "center" }}>DateOfCreation</th>

                  <th className="tahe" style={{ textAlign: "center" }}>ACTION</th>
                </tr>
              </thead>
         
              {data.length > 0 ? (
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{item.auctionDate}</td>
                      <td style={{ textAlign: "center" }}>{item.amountdueDate}</td>
                      <td style={{ textAlign: "center" }}>{item.InstallmentNumber}</td>
                      <td style={{ textAlign: "center" }}>{item.nextauctionDate}</td>
                      <td style={{ textAlign: "center" }}>{item.bidAmount}</td>
                      <td style={{ textAlign: "center" }}>{item.bidderName}</td>
                      <td style={{ textAlign: "center" }}>{item.group_id}</td>
                      <td style={{ textAlign: "center" }}>{item.group_name}</td>
                      <td style={{ textAlign: "center" }}>{item.chit_amount}</td>
                      <td style={{ textAlign: "center" }}>{item.commission_k}</td>
                      <td style={{ textAlign: "center" }}>
                        {moment(item.auctionCreationDate).format("DD-MM-YYYY")}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <div className="Ad-allauction-gtf">
                          <Link
                            to={`/biding/${item.auctionId}/${item.group_id}`}
                          >
                            <button className="Ad-allauction-create-course">Biding</button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      Auctions not yet created for this GroupId:{groupId}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Allauctions;
