import React from 'react';
import "./Whyus.css";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
 
const Whyus = () => {
  return (
    <div>
        <div>
            <h4 className='lg-why'>WHY CHOOSE US</h4>
        </div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="About Chit Genius company">
        <Row className="mt-5">
          <Col sm={12} className="d-flex flex-column justify-content-center align-items-center tabs2">
            <Nav variant="pills" className="flex-row">
              {['About Chit Genius company', 'Flexible Investment Options', 'Transparency and trustworthiness', 'Customer Services', 'Cutting-Edge Technology',].map(category => (
                <Nav.Item key={category}>
                  <Nav.Link eventKey={category}>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col>
            <Tab.Content className="oscroll mt-5">
              <Tab.Pane eventKey="About Chit Genius company">
                <div className="screen2-container">
                  <div className="lg-sect-texte">
                    <h3>About Chit Genius</h3>
                    <p className="lg-card_whyus">Our mission is to offer flexible and secure financial solutions 
                    that meet the diverse needs of our customers. Whether you are 
                    planning for a major purchase, funding your business, or saving 
                    for the future, Chit Genius provides a trustworthy platform to 
                    enhance your financial stability. we believe in the power of 
                    community and mutual benefit. </p>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Flexible Investment Options">
                <div className="screen2-cards-container">
                  <div className="lg-sect-texte">
                    <h3>Flexible Investment</h3>
                    <p className="lg-card_whyus">At Chit Genius, we understand that one size does not fit all 
                      when it comes to financial planning. That's why we offer a wide 
                      range of chit fund schemes to suit every budget and goal. Whether 
                      you're looking to save for the future, fund a major purchase, or 
                      achieve a specific financial milestone, we have the perfect solution 
                      for you.
                    </p>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Transparency and trustworthiness">
                <div className="screen2-cards-container">
                  <div className="lg-sect-texte">
                    <h3>Transparency & Trustworthiness</h3>
                    <p className="lg-card_whyus">Transparency is at the core of everything we do at Chit Genius. 
                      We believe in building trust with our clients through clear 
                      communication, honest dealings, and adherence to all regulatory 
                      standards. With us, you can rest assured that your investments 
                      are in safe hands.
                    </p>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Customer Services">
                <div className="screen2-cards-container">
                  <div className="lg-sect-texte">
                    <h3>Customer Services</h3>
                    <p className="lg-card_whyus">When you choose Chit Genius, you're not just joining a financial 
                      institution – you're becoming part of a supportive community of 
                      like-minded individuals. Our clients are our top priority, and 
                      we go above and beyond to ensure their complete satisfaction. 
                      From personalized financial advice to responsive customer support, 
                      we're here to help you every step of the way on your financial journey.
                    </p>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Cutting-Edge Technology">
                <div className="screen2-cards-container">
                  <div className="lg-sect-texte">
                    <h3>Cutting-Edge Technology</h3>
                    <p className="lg-card_whyus">We leverage the latest technological advancements to streamline 
                      the chit fund process for our clients. Our user-friendly digital 
                      platform allows you to manage your investments with ease, providing 
                      real-time updates and insights to help you make informed financial 
                      decisions.
                    </p>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
 
export default Whyus;
 