import React from 'react';
import './Services.css';
import image2 from '../images/CGM.webp';
import image3 from '../images/DP.webp';
import image4 from '../images/image4.png';
import image5 from '../images/TA.png';
import image6 from '../images/image6.png';
 import image7 from '../images/image7.png';
import AOS from 'aos';
 
const texts = ["Digital platform ", "Chit Group Management", "Easy withdrawals", "Transparent Auction", "Dividend Distribution", "High returns"];
 
const ImageGrid = () => {
  AOS.init(); // Initialize AOS again in the component (optional)
 
  return (
    <div>
      <div>
        <h1 className="sg-heading text-center">Our Services</h1>
        <div className="sg-container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mb-5" data-aos="zoom-in">
              <div className="sg-image-container">
                <img src={image2} alt="S1" className="sg-image img-fluid" />
                <div className="sg-image-text">{texts[0]}</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-5" data-aos="zoom-in">
              <div className="sg-image-container">
                <img src={image3} alt="S2" className="sg-image img-fluid" />
                <div className="sg-image-textcg">{texts[1]}</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-5" data-aos="zoom-in">
              <div className="sg-image-container">
                <img src={image4} alt="S3" className="sg-image img-fluid" />
                <div className="sg-image-text">{texts[2]}</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-5" data-aos="zoom-in">
              <div className="sg-image-container">
                <img src={image5} alt="S4" className="sg-image img-fluid" />
                <div className="sg-image-text">{texts[3]}</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-5" data-aos="zoom-in">
              <div className="sg-image-container">
                <img src={image6} alt="S5" className="sg-image img-fluid" />
                <div className="sg-image-text">{texts[4]}</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-5" data-aos="zoom-in">
              <div className="sg-image-container">
                <img src={image7} alt="S6" className="sg-image img-fluid" />
                <div className="sg-image-text">{texts[5]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default ImageGrid;
 