import React, { useEffect, useState, useContext } from "react";
import Superside from "../superadmin/Superside";
import { Link } from "react-router-dom";
import axios from "axios";
import { SuperadminContext } from "../context/superadmincontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import "./Supermembereditdetails.css";
import userimage from "./images/userimage.png"

const Supermemberdetails = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const { superadminname } = useContext(SuperadminContext);
  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  // const commonusergetid = user.UserId;

   const loadData = async () => {
    const response = await axios.get("https://api.chitgenius.com/api/get");
    setData(response.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  

  const deleteMember = (UserId) => {
    if (
      window.confirm("Are you sure that you wanted to delete that member ?")
    ) {
      axios.delete(`https://api.chitgenius.com/api/remove/${UserId}`);
      alert.success("Deleted Successfully");
      setTimeout(() => 500);
    }
  };

  return (
    <div style={{ display: "flex" }}>
    <div>
      <Superside />
    </div>
    <div className="Super-Member">
      <div>
        <div className="Super-Member-courses-head">
          <div className="super-dash-heading">
            <h2>Member Details</h2>
          </div>

          <div className="Super-Member-profile-set">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                style={{
                  height: "50px",
                  borderRadius: "30px",
                  width: "50px",
                }}
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ margin: "10px 0px" }}></hr>

      <div className="Super-Bidde-searchcontainer">
        <input
          type="search"
          placeholder="Search by Name"
          className="Bidde-search"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </div>

      <div className="Super-Member-table-auction">
        <div className="">
          <table className="Super-Member-table-style">
            <thead>
              <tr>
                <th className="tahe" style={{ textAlign: "center" }}>No</th>
                <th className="tahe" style={{ textAlign: "center" }}>Name</th>
                <th className="tahe" style={{ textAlign: "center" }}>Password</th>
                <th className="tahe" style={{ textAlign: "center" }}>Date-Of-Birth</th>
                <th className="tahe" style={{ textAlign: "center" }}>Mobile Number</th>
                <th className="tahe" style={{ textAlign: "center" }}>Email</th>
                <th className="tahe" style={{ textAlign: "center" }}>Gender</th>
                <th className="tahe" style={{ textAlign: "center" }}>Occupation</th>
                <th className="tahe" style={{ textAlign: "center" }}>Role</th>
                <th className="tahe" style={{ textAlign: "center" }}>Adhaar Number</th>
                <th className="tahe" style={{ textAlign: "center" }}>Upload Adhaar Card</th>
                <th className="tahe" style={{ textAlign: "center" }}>Pan Number</th>
                <th className="tahe" style={{ textAlign: "center" }}>Upload Pan Card</th>
                <th className="tahe" style={{ textAlign: "center" }}>Upload Photo</th>
                <th className="tahe" style={{ textAlign: "center" }}>Address</th>
                <th className="tahe" style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {data
                .filter(
                  (data) =>
                    data.UserName.toLowerCase().includes(
                      search.toLowerCase()
                    ) ||
                    data.email.toLowerCase().includes(search.toLowerCase()) ||
                    data.mobileNo.includes(search)
                )
                .map((item, index) => {
                  return (
                    <tr key={item.UserId}>
                      <th scope="row">{index + 1}</th>
                      <td style={{ textAlign: "center" }}>{item.UserName}</td>
                      <td style={{ textAlign: "center" }}>
                        {item.userpassword}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.UserDob}</td>
                      <td style={{ textAlign: "center" }}>{item.mobileNo}</td>
                      <td style={{ textAlign: "center" }}>{item.email}</td>
                      <td style={{ textAlign: "center" }}>{item.gender}</td>
                      <td style={{ textAlign: "center" }}>
                        {item.occupation}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.role}</td>
                      <td style={{ textAlign: "center" }}>{item.adhaarNo}</td>
                      <td style={{ textAlign: "center" }}>
                        <img
                          src={`https://api.chitgenius.com/uploads/${item.uploadadhaarcard}`}
                          alt=""
                          style={{ height: "50px" }}
                          className="imt"
                        ></img>
                      </td>
                      <td style={{ textAlign: "center" }}>{item.panNo}</td>
                      <td style={{ textAlign: "center" }}>
                        <img
                          src={`https://api.chitgenius.com/uploads/${item.uploadpancard}`}
                          alt=""
                          style={{ height: "50px" }}
                          className="imt"
                        ></img>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <img
                          src={`https://api.chitgenius.com/uploads/${item.uploadPhoto}`}
                          alt=""
                          style={{ height: "50px" }}
                          className="imtg-1"
                        ></img>
                      </td>
                      <td style={{ textAlign: "center" }}>{item.Address}</td>
                      <td>
                        
                        <Link to={`/supermembereditdetails/${item.UserId}`}>
                          <button className="home-edit">Edit</button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  );
};

export default Supermemberdetails;
