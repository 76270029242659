import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Autotest = () => {
  const [mobileNo, setmobileNo] = useState('');
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (mobileNo) {
      fetchUserData();
    }
  }, [mobileNo]);

  const handleChange = (e) => {
    setmobileNo(e.target.value);
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`https://api.chitgenius.com/api/get/auto/${mobileNo}`);
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter User ID"
        value={mobileNo}
        onChange={handleChange}
      />
      <div>
        <input type="text" value={userData.UserName || ''} readOnly />
        <input type="text" value={userData.email || ''} readOnly />
        {/* Add more input fields for other user details */}
      </div>
    </div>
  );
};

export default Autotest;
