import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import SideBar from "../components/Sidebar/SideBar";
import "./moreinfo.css";
import { UserContext } from "../context/usecontext";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie"; 
import userimage from "./images/userimage.png"

//  TANUJAS <CODE>
//  </CODE>
const MoreInfo = () => {
  const usernamedashboard = localStorage.getItem("username");
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/moreinfo") {
        navigate("/moreinfo");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
      return;
    }

    setUsersname(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  return (
    <div className="Ad-moreinfo-main" style={{ display: "flex" }}>
      <div>
        <SideBar></SideBar>
      </div>
      <div className="Ad-moreinfo-full">
        <div className="Ad-moreinfo-head">
          <div className="Ad-moreinfo-heading">
            <h2>Information</h2>
          </div>

          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                style={{ height: "50px", borderRadius: "30px", width: "50px" }}
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
        <hr></hr>
        <div>
          <div className="Ad-boxes">
            <div className="Ad-bnd">
              <Link to="/briefinfo" className="Ad-po">
                <p className="Ad-kok">Groups-users</p>
              </Link>
            </div>
            <div className="Ad-bnd">
              <Link to="/auctiondetails" className="Ad-po">
                <p className="Ad-kok">Auction details</p>
              </Link>
            </div>
            <div className="Ad-bnd">
              <Link to="/detailbiding" className="Ad-po">
                <p className="Ad-kok">Biding Details</p>
              </Link>
            </div>
          </div>
          <div className="Ad-boxes">
            <div className="Ad-bnd">
              <Link to="/home" className="Ad-po">
                <p className="Ad-kok">MembersDetails</p>
              </Link>
            </div>
            <div className="Ad-bnd">
              <Link to="/home/bid" className="Ad-po">
                <p className="Ad-kok">Payment Collection</p>
              </Link>
            </div>
            <div className="Ad-bnd">
              <Link to="/allpayout/details/admin" className="Ad-po">
                <p className="Ad-kok">Payout Details</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <SideBar />
      </div>
      <div className="info-head">

      <div>
        <Link to="/briefinfo">
          <button>Groups-users</button>
        </Link>
      </div>
      </div> */}
    </div>
  );
};

export default MoreInfo;
