import React from "react";
import { NavLink } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useEffect, useState, useContext } from "react";
import AgentSide from "../agentcompo/AgentSide";
import "./Agentcollectiondetails.css";
import { useNavigate } from "react-router-dom";
import { AgentContext } from "../context/agentcontext";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import userimage from "./images/userimage.png"
 
const Agentmembercollectiondetails = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [search, setSearch] = useState("");
 
  const [data, setData] = useState([]);
  const { groupName } = useParams(); // Extract the groupId from the URL path
  const { paymentId } = useParams();
  const history = useNavigate();
  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});
 
  const dltbid = async (paymentcollect_Id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const res = await axios.delete(
          `https://api.chitgenius.com/api/delete/pid/${paymentcollect_Id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.status === 201) {
          toast.success("Successfully deleted!");
          // navigate("/moreinfo"); // Redirect after successful deletion
          fetchData();
 
 
        } else {
          console.log("Error deleting data");
        }
      } catch (error) {
        console.error("There was an error deleting the data", error);
        toast.error("There was an error deleting the data.");
      }
    }
  };
 
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/agent/ap/api/agentidrelated/paymentcollection/${groupName}`
        ); // Use the groupId from the URL path
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
useEffect(() => {
    fetchData();
  }, [groupName]);
 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);
 
  return (
    <div style={{ display: "flex" }}>
      <div>
        <AgentSide />
      </div>
      <div className="C-ttd">
        <div className="courses1-head">
          <div className="h">
            <div className="">
              <NavLink className="" to={"/agentchits"}>
                <FaArrowLeftLong className="Agent-Arrow" />
              </NavLink>
            </div>
            <div className="dash-heading">
              <h2>Paycollection details</h2>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" ,marginRight:"30px" }}>
          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
        </div>
 
        <hr></hr>
        <div className="">
        <div className="super-Bidde-searchContainer">
          <input
            type="search"
            placeholder="Search by Name"
            className="Bidde-search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
        <div className="All-table-auction">
            <div className="">
              <table className="All-table-style">
                <thead>
                  <tr>
                    <th className="tahe" style={{ textAlign: "center" }}>No.</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_userId</th>
 
                   {/*<th className="tahe" style={{ textAlign: "center" }}>payment_username</th>*/}
                    <th className="tahe" style={{ textAlign: "center" }}>payment_idgroup</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_namegroup</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_month</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_insNum</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_tap</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_hap</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_Mode</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_mobilenum</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_Date</th>
                    <th className="tahe" style={{ textAlign: "center" }}>payment_Balance</th>
                    {/* <th style={{ textAlign: "center" }}>payment_bidingId</th> */}
                    <th className="tahe" style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data
                    .filter((data) => data.payment_mobilenum.includes(search))
 
                    .map((item, index) => {
                      return (
                        <tr>
                          <td  style={{ textAlign: "center" }}>{index + 1}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_userId}</td>
                          {/* <td  style={{ textAlign: "center" }}>{item.payment_username}</td> */}
                          <td  style={{ textAlign: "center" }}>{item.payment_idgroup}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_namegroup}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_month}</td>
 
                          <td  style={{ textAlign: "center" }}>{item.payment_insNum}</td>
 
                          <td  style={{ textAlign: "center" }}>{item.payment_tap}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_hap}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_Mode}</td>
                          <td  style={{ textAlign: "center" }}>{item.payment_mobilenum}</td>
 
                          <td  style={{ textAlign: "center" }}>
                            {moment(item.payment_Date).format("DD-MM-YYYY")}
                          </td>
                          <td  style={{ textAlign: "center" }}>{item.payment_Balance}</td>
                          {/* <td>{item.payment_bidingId}</td> */}
                          <td  style={{ textAlign: "center" }}>
                            <button
                              onClick={() => dltbid(item.paymentcollect_Id)}
                              className="Ad-button-infodata"
                              style={{marginLeft:'10px'}}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
       
              </table>
            </div>
          </div>
        </div>
       <ToastContainer/>
      </div>
    </div>
  );
};
 
export default Agentmembercollectiondetails;
 