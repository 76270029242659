import React, { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import loggo from "../images/chigenius-logo.png";
import { BsArrowReturnRight } from "react-icons/bs";
import "./Navbar.css";
 
function Navbar() {
  const navRef = useRef();
 
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth" // This smooth scrolls to the top
    });
  };
 
  const closeNavbar = () => {
    navRef.current.classList.remove("responsive_nav");
  };
 
  const toggleNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
 
  return (
    <header>
      <div>
        <Link to="/" className="lg-linked-text">
          <div className="lg-logo-10">
            <img src={loggo} className="lg-yaa" alt="Logo" />
            {/* <div>
              <h4 className="lg-text">WiseInvest Chit Funds</h4>
            </div> */}
          </div>
        </Link>
      </div>
      <nav ref={navRef}>
        <div className="tiger">
          <ul>
            <p>
              <a href="/" className="" onClick={handleClick}>
                HOME
              </a>
            </p>
            <p>
              <a href="#About" className="" onClick={closeNavbar}>
                ABOUT US
              </a>
            </p>
            <p>
              <a href="#Services" className="" onClick={closeNavbar}>
                OUR SERVICES
              </a>
            </p>
            <p>
              <a href="#whyus" className="" onClick={closeNavbar}>
                WHY CHOOSE US
              </a>
            </p>
            <p>
              <a href="#Contact" className="" onClick={closeNavbar}>
                CONTACT US
              </a>
            </p>
          </ul>
        </div>
        <div className="lg-sign-btn">
          <div className="lg-signin-login">
            <FaRegUser className="lg-icons" />
            <NavLink to="/land" className="lg-signin-login-1">
            <button className="lg-sign-in"> Login <BsArrowReturnRight className="lg-icon" /></button>
            
            </NavLink>
          </div>
        </div>
        <button className="lg-nav-btn nav-close-btn" onClick={toggleNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="lg-nav-btn" onClick={toggleNavbar}>
        <FaBars />
      </button>
    </header>
  );
}
 
export default Navbar;
 