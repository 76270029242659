import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { FaArrowLeftLong } from "react-icons/fa6";
import SideBar from "../components/Sidebar/SideBar";
import "./Infodata.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { UserContext } from "../context/usecontext";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import userimage from "./images/userimage.png"

const Infodata = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});

  const { userGroupId } = useParams();
  // const history=useNavigate();

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/briefinfo") {
        navigate("/briefinfo");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
      return;
    }

    setUsersname(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  const dltgroupuser = async (userGroupId) => {
    console.log(userGroupId);
    const res = await axios.delete(
      `https://api.chitgenius.com/api/delete/groupusers/${userGroupId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.status == 201) {
      // console.log("success")
      // getAssessmentData()
      //  setShow(true)
      navigate("/chitgroup");
    } else {
      console.log("error");
    }
  };


  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const commonuserid = user.UserId;

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/chit/data/get/allgroupuser/particularadmin/${commonuserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [commonuserid]);

  return (
    <div className="Ad-infodata-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="">
        <div className="Ad-infodata-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className=""
        >
           <div className="Ad-infodata-head">
            <div className="Ad-h">
              <div className="">
                <NavLink className="" to={"/moreinfo"}>
                  <FaArrowLeftLong className="Ad-ume" />
                </NavLink>
              </div>
              <div className="Ad-infodata-heading">
                <h2>Group users</h2>
              </div>
            </div>
            <div className="image">
              <div>
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
              </div>
            </div>
          </div>
          <hr />
          <div className="Ad-GU-searchContainer">
            <input
              type="search"
              placeholder="   Search by userName, date, groupid, groupname"
              className="Ad-GU-quizSearch"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
          <div className="">
            <div className="Ad-table-groupusers">
              <table className="Ad-table-style-Gu">
                <thead>
                  <tr>
                    <th className="tahe" style={{ textAlign: "center" }}>No.</th>
                    <th className="tahe" style={{ textAlign: "center" }}>groupId</th>
                    <th className="tahe" style={{ textAlign: "center" }}>groupName</th>
                    <th className="tahe" style={{ textAlign: "center" }}>user_Name</th>
                    <th className="tahe" style={{ textAlign: "center" }}>user_Id</th>
                    <th className="tahe" style={{ textAlign: "center" }}>DateOfCreation</th>
                    {/* <th className="tahe" style={{ textAlign: "center" }}>ACTION</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data
                    .filter(
                      (data) =>
                        data.user_Name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        data.userAddedDate.includes(search) ||
                        data.group_Id.toString() === search.trim() ||
                        data.group_Name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                    )

                    .map((item, index) => {
                      return (
                        <tr>
                          <td style={{ textAlign: "center" }}>{index + 1}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.group_Id}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.group_Name}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.user_Name}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.user_Id}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {moment(item.userAddedDate).format("DD-MM-YYYY")}
                          </td>
                          {/* <td style={{ textAlign: "center" }}>
                            <button
                              onClick={() => dltgroupuser(item.userGroupId)}
                              className="Ad-button-infodata" style={{ marginLeft:"10px" }}
                            >
                              Delete
                            </button>
                          </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </motion.div>

         
        </div>
      </div>
    </div>
  );
};

export default Infodata;
