import React, { useState, useEffect, useContext } from "react";
import CollectingAgentSide from "./CollectingAgentSide";
import "./collectingagentpayin.css";
import Select from "react-select";
import Swal from "sweetalert2";
import Grablocation from "./Grablocation";
import axios from "axios";
import { FaLocationDot } from "react-icons/fa6";
import { CollectionagentContext } from "../context/collectionagentcontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import userimage from "./images/userimage.png"
import numWords from "num-words"; // Library to convert numbers to words
 
const initialState = {
  mobileNo: "",
  groupName: "",
  installno: "",
  totalAmount1: "",
  totalpaid: "",
  balance: "",
  collectedby: "",
  paid_location: "",
};
 
const CollectingAgentPayin = () => {
  const usernamedashboard = localStorage.getItem("username");
 
  const [state, setState] = useState(initialState);
  const [duration, setDuration] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState("");
  const [userdata, setUserData] = useState({});
  const [mobileNo, setmobileNo] = useState([]);
  const [groupNameOptions, setGroupNameOptions] = useState([]); // Options for group name dropdown
  const [selectedGroupName, setSelectedGroupName] = useState(""); //
  const [installno, setInstallNo] = useState("");
  const [installnoOptions, setinstallnoOptions] = useState([]); // Options for group name dropdown
  const [groupname, setGroupname] = useState([]);
  const [totalAmount1, setTotalAmount1] = useState("");
  const [totalpaid, setTotalpaid] = useState("");
  const [totalAmountOptions, settotalAmountOptions] = useState([]);
  const { collectionagentname } = useContext(CollectionagentContext);
  const [user, setuser] = useState({});
  const [totalBalanceAmt, setTotalBalanceAmt] = useState("");
  const [collectedby, setCollectedBy] = useState(initialState);
  const [balanceAmount, setBalanceAmount] = useState("");
  const [customData, setCustomData] = useState(initialState);
  const [address, setAddress] = useState("");
  const [paymentMonth, setPaymentMonth] = useState("");
  const [paymentPayThrough, setPaymentPayThrough] = useState("");
  const [formErrors, setFormErrors] = useState({});
 
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");
 
  const handleTotalpaidChange = (e) => {
    const paid = Number(e.target.value); // Convert to number
    if (!isNaN(paid) && e.target.value !== "") {
      const newBalance = totalBalanceAmt - paid;
      if (newBalance >= 0) {
        setTotalpaid(paid);
        setBalanceAmount(newBalance);
      } else {
        alert("Amount should not be greater than 'Balance to pay'!! please enter valid amount.");
        setTotalpaid("");
        setBalanceAmount(totalBalanceAmt);
      }
    } else {
      setTotalpaid("");
      setBalanceAmount(totalBalanceAmt);
    }
  };
 
  // Convert number to words
  const numberToWords = (num) => {
    return num !== "" ? numWords(Math.abs(num)) : "";
  };
 
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      console.log(latitude, longitude);
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => setAddress(data.address))
        .catch((error) => console.error("Error fetching location:", error));
    });
  }, []);
 
  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;
 
    if (token) {
      if (location.pathname !== "/collectingagentpayin") {
        navigate("/collectingagentpayin");
      }
      return;
    }
 
    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/collectionagent") {
        navigate("/");
      }
      return;
    }
 
    setUsername(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);
 
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };

  useEffect(() => {

    fetchnameData();
  }, [usernamedashboard]);
 
  const handleSuccessClick = () => {
    Swal.fire({
      title: "Payment made Successfully!",
      text: "Your action has been completed.",
      icon: "Thank You",
    });
    setState(initialState);
    setDuration("");
    setTransactions([]);
    setGroupName("");
    setData([]);
    setError(null);
    setUserId("");
    setUserData({});
    setmobileNo([]);
    setGroupNameOptions([]);
    setSelectedGroupName("");
    setInstallNo("");
    setinstallnoOptions([]);
    setGroupname([]);
    setTotalAmount1("");
    setTotalpaid("");
    settotalAmountOptions([]);
    setTotalBalanceAmt("");
    setuser({});
    // setBalance("");
    setCollectedBy("");
    setBalanceAmount("");
    setCustomData(initialState);
    setAddress("");
    setPaymentMonth("");
    setPaymentPayThrough("");
    setFormErrors({});
    fetchnameData();
    loadUserData();
  };
 
  
   
  const validateForm = () => {
    const errors = {};
    if (!mobileNo) errors.mobileNo = "Mobile number is required.";
    if (!selectedGroupName) errors.groupName = "Group name is required.";
    if (!installno) errors.installno = "Install number is required.";
    if (!totalpaid) errors.totalpaid = "Total paid amount is required.";
    if (!paymentMonth) errors.paymentMonth = "Please select a month.";
    if (!paymentPayThrough) errors.paymentPayThrough = "Please select a payment method.";
    // if (paymentMonth !== getCurrentMonth()) {
    //   errors.paymentMonth = "Please select the current month.";
    // }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
 
  function getCurrentMonth() {
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const currentMonth = new Date().getMonth();
    return monthNames[currentMonth];
  }
 
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!validateForm()) return;
    if (!validateForm()) {
      Swal.fire({
        title: "Please fill all the required fields!",
        text: "You have missed filling out some required fields.",
        icon: "error",
      });
      return;
    }
    if (paymentMonth !== getCurrentMonth()) {
      Swal.fire({
        title: "Please select the current month!",
        text: "You have selected a month other than the current month.",
        icon: "error",
      });
      return;
    }
    if (mobileNo) {
      axios
        .post("https://api.chitgenius.com/paymentcollection/post/collectingagent", {
          mobileNo: mobileNo,
          installno: installno,
          groupName: selectedGroupName,
          totalAmount: totalAmount1,
          totalpaid: totalpaid,
          balance: balanceAmount,
          collectedby: user.UserId,
          paid_location: addressParagraph,
          paymentMonth: paymentMonth,
          paymentPayThrough: paymentPayThrough,
        })
        .then(() => {
          handleSuccessClick();
          // setData(initialState);
          setCustomData(initialState);
        })
        .catch((err) => console.error(err.response.data));
    }
  };
 
  const loadUserData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/api/getall/usernames"
    );
    setData(response.data);
  };
 
  useEffect(() => {
    loadUserData();
  }, []); // Empty dependency array to ensure useEffect runs only once
 
  useEffect(() => {
    if (mobileNo) {
      fetchUserData();
    }
  }, [mobileNo]);
 
  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `https://api.chitgenius.com/api/user/memberpassbook/${mobileNo}`
      );
      // setGroupName(response.data.group_Name);
 
      const options = response.data.map((group) => ({
        label: group,
        value: group,
      }));
      // Update dropdown options with group names
      setGroupNameOptions(options);
    } catch (error) {
      console.error("Error fetching user names:", error);
      setGroupNameOptions([]); // Reset dropdown options if an error occurs
    }
    // } catch (error) {
    //   console.error("Error fetching user data:", error);
    //   setGroupName(""); // Reset groupName if error occurs
    // }
  };
 
  const fetchGroupData = async () => {
    try {
      const response = await axios.get(
        `https://api.chitgenius.com/api/user/memberpassbook1/${selectedGroupName}`
      );
      // setGroupName(response.data.group_Name);
 
      const options = response.data.map((group) => ({
        label: group,
        value: group,
      }));
      // Update dropdown options with group names
      setinstallnoOptions(options);
    } catch (error) {
      console.error("Error fetching group names:", error);
      setinstallnoOptions([]); // Reset dropdown options if an error occurs
    }
  };
 
  useEffect(() => {
    if (selectedGroupName) {
      fetchGroupData();
    }
  }, [selectedGroupName]);
 
  useEffect(() => {
    if (installno) {
      fetchTotalAmt();
    }
  }, [installno]);
 
  
 
  const fetchTotalAmt = async () => {
    try {
      const response = await axios.post(
        `https://api.chitgenius.com/api/user/memberpassbook2/mix`,
        {
          groupName: selectedGroupName,
          mobileNo: mobileNo,
          installno: installno,
          payoutsub: totalAmount1,
        }
      );
      setTotalAmount1(response.data);
      fetchTotalBalanceAmt(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      setTotalAmount1("");
    }
  };
 
  const fetchTotalBalanceAmt = async (currentTotalAmount) => {
    try {
      const response = await axios.post(
        `https://api.chitgenius.com/api/paymentcollection/balancepayment`,
        {
          namegroup: selectedGroupName,
          mobileNumber: mobileNo,
          insNum: installno,
        }
      );
      var balAmtToPay =
        (isNaN(currentTotalAmount) ? 0 : currentTotalAmount) -
        (isNaN(response.data) ? 0 : response.data);
      var balAmt = isNaN(balAmtToPay) ? 0 : balAmtToPay;
      setTotalBalanceAmt(balAmtToPay);
      setBalanceAmount(balAmt);
      setError(null);
    } catch (error) {
      console.error(error);
      setTotalBalanceAmt("");
      setBalanceAmount("");
    }
  };
 
  const addressParagraph = `${address.road}, ${address.neighbourhood}, ${address.state}, ${address.city}, ${address.state_district}, ${address.postcode}, ${address.country}`;
 
  return (
    <div className="CA-payin-main" style={{ display: "flex" }}>
      <div>
        <CollectingAgentSide />
      </div>
      <div className="CA-payin-full" >
        <div className="CA-payin-head">
          <div className="CA-payin-heading">
            <h2>Payment Collection</h2>
            {/* <h1>Welcome, {collectionagentname}</h1> */}
          </div>
 
          <div>
            <div className="image">
              <div>
                <img
                src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                  alt=""
                  // style={{ height: "50px", borderRadius: "30px", width: "50px" }}
                  className="images"
                ></img>
              </div>
              <div>
                <p style={{ margin: "0px" }}>{user.UserName}</p>
                <p style={{ margin: "0px" }}>({user.role})</p>
              </div>
            </div>
          </div>
        </div>
 
        <hr></hr>
        <div className="Ad-payin-9">
          <p className="Ad-cpayin-10">
            <FaLocationDot />
          </p>
          <input type="text" className="Ad-cpayin-9" value={addressParagraph} />
          {/* <Grablocation/> */}
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="" style={{ width: "100vh", marginTop: "12px" }}>
              <div className="CA-cpayin-1">
                <div className="CA-cpayin-2">
                  <label>
                    <strong>Mobile No</strong>
                  </label>
                  <Select
                    value={{ label: mobileNo, value: mobileNo }}
                    onChange={(selectedOption) =>
                      setmobileNo(selectedOption.value)
                    }
                    options={data.map((opts) => ({
                      label: opts.mobileNo,
                      value: opts.mobileNo,
                    }))}
                    className="CA-cpayin-3"
                    isSearchable={true}
                  />
                </div>
 
                <div className="CA-cpayin-2">
                  <label>
                    <strong>Group Id</strong>
                  </label>
                  <Select
                    value={{
                      label: selectedGroupName,
                      value: selectedGroupName,
                    }}
                    onChange={(selectedOption) =>
                      setSelectedGroupName(selectedOption.value)
                    }
                    options={groupNameOptions}
                    name="groupName"
                    className="CA-cpayin-3"
                    isSearchable={true}
                  />
                </div>
 
                <div>
                  <div className="CA-cpayin-2">
                    <label>
                      <strong>Install No</strong>
                    </label>
                    <Select
                      value={{ label: installno, value: installno }}
                      onChange={(selectedOption) =>
                        setInstallNo(selectedOption.value)
                      }
                      options={installnoOptions}
                      className="CA-cpayin-3"
                      isSearchable={true}
                    />
                  </div>
 
                  <div className="CA-cpay">
                    <div className="CA-cpayin-4">
                      <label className="CA-cpay-1">
                        <strong>Total Amount :</strong>
                      </label>
                      <input
                        type="text"
                        name="totalAmount"
                        className="CA-cpayin-11"
                        value={totalAmount1}
                        disabled
                        // value={paymentData.payment_tap}
                      />
                      <div className="CA-num">
                        <p className="CA-numwords">
                          <strong>In words:</strong>
                        </p>
                        <p className="CA-numwords-1">
                          {numberToWords(totalAmount1)}
                        </p>
                      </div>
                    </div>
 
                    <div className="CA-cpayin-4">
                      <label className="CA-cpay-1">
                        <strong>Balance to pay :</strong>
                      </label>
 
                      <input
                        type="text"
                        name="balanceamounttopay"
                        className="CA-cpayin-5"
                        value={totalBalanceAmt}
                        readOnly
                      />
                      <div className="CA-num">
                        <p className="CA-numwords">
                          <strong>In words:</strong>
                        </p>
                        <p className="CA-numwords-1">
                          {numberToWords(totalBalanceAmt)}
                        </p>
                      </div>
                    </div>
 
                    <div className="CA-cpayin-4">
                      <label className="CA-cpay-1">
                        <strong>Total paid :</strong>
                      </label>
                      <input
                        type="text"
                        name="totalpaid"
                        className="CA-cpayin-6"
                        value={totalpaid}
                        onChange={handleTotalpaidChange}
                        // onChange={(e) => setTotalpaid(e.target.value)}
                      />
                      <div className="CA-num">
                        <p className="CA-numwords">
                          <strong>In words:</strong>
                        </p>
                        <p className="CA-numwords-1">{numberToWords(totalpaid)}</p>
                      </div>
                    </div>
                    <div className="CA-cpayin-4">
                      <label className="CA-cpay-1">
                        <strong>Balance :</strong>
                      </label>
                      <input
                        type="text"
                        name="balance"
                        className="CA-cpayin-7"
                        value={balanceAmount}
                      />
                      <div className="CA-num">
                        <p className="CA-numwords">
                          <strong>In words:</strong>
                        </p>
                        <p className="CA-numwords-1">
                          {numberToWords(balanceAmount)}
                        </p>
                      </div>
                    </div>
                    <div className="CA-cpayin-4">
                      <label className="CA-cpay-1">
                        <strong>Month :</strong>
                      </label>
                      <select
                        name="dropdown"
                        className="CA-cpayin-12"
                        value={paymentMonth}
                        onChange={(event) => {
                          setPaymentMonth(event.target.value);
                        }}
                      >
                        <option value="" disabled select>
                          Select Month
                        </option>
                        <option value="Jan">January</option>
                        <option value="Feb">February</option>
                        <option value="Mar">March</option>
                        <option value="Apr">April</option>
                        <option value="May">May</option>
                        <option value="Jun">June</option>
                        <option value="Jul">July</option>
                        <option value="Aug">August</option>
                        <option value="Sep">September</option>
                        <option value="Oct">October</option>
                        <option value="Nov">November</option>
                        <option value="Dec">December</option>
                      </select>
                    </div>
 
                    <div className="CA-cpayin-4">
                      <label className="CA-cpay-1">
                        <strong>Pay through :</strong>
                      </label>
                      <select
                        name="dropdown"
                        className="CA-cpayin-13"
                        value={paymentPayThrough}
                        onChange={(event) => {
                          setPaymentPayThrough(event.target.value);
                        }}
                      >
                        <option value="" disabled select>
                          Pay Through
                        </option>
                        <option value="phone pay">Phone pay</option>
                        <option value="google pay">Google pay</option>
                        <option value="cash">Cash</option>
                      </select>
                    </div>
                    <div className="CA-cpayin-4">
                      <label className="CA-cpay-1">
                        <strong>Collected By :</strong>
                      </label>
                      <input
                        type="text"
                        name="collectedby"
                        className="CA-cpayin-8"
                        value={user.UserId}
                        disabled
                      />
                    </div>
                  </div>
 
                 
                  <div>
                    <button type="submit" className="CA-button-20">
                      Make Payment
                    </button>
                  </div>
                </div>
              </div>
              
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
 
export default CollectingAgentPayin;