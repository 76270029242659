import React, { useState, useEffect , useContext } from "react";
import moment from "moment";
import axios from "axios";
// import SideBar from "../components/Sidebar/SideBar";
import Respside from "./Respside"
import { NavLink, Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { ReceiptionContext } from "../context/receiptioncontext";
import {useNavigate,useLocation} from "react-router-dom"
import Cookies from 'js-cookie';
import '../receiption/resppaymentcollection.css'
import userimage from "./images/userimage.png"

 
const RespPaymentcollection = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
 
  const [search, setSearch] = useState("");
  const { receiptionname } = useContext(ReceiptionContext);
  const [user, setuser] = useState({});



  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
      const token = Cookies.get('token');
      const { state } = location;

      if (token) {
         
          if (location.pathname !== '/resppaymentcollectiondetails') {
              navigate('/resppaymentcollectiondetails');
          }
          return;
      }

      if ( !state || !state.username || !state.role) {
        if (location.pathname !== '/receiptionist') {
          navigate('/');
        }
        return;
      }
     
          setUsername(state.username);
          setRole(state.role);
          console.log(state.username, state.role);
         
    }, [location, navigate]);



  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard])


 
  const loadData = async () => {
    const response = await axios.get(
      "https://api.chitgenius.com/chit/paymentcollectiondata/get/receiption"
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, []);
 
  const dltbid = async (paymentcollect_Id) => {
    console.log(paymentcollect_Id);
    const res = await axios.delete(
      `https://api.chitgenius.com/api/delete/pid/${paymentcollect_Id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.status == 201) {
      // console.log("success")
      // getAssessmentData()
      //  setShow(true)
      navigate("/moreinfo");
    } else {
      console.log("error");
    }
  };
  return (
    <div className="Resp-paymentcollection-main" style={{ display: "flex" }}>
      <div>
        <Respside />
      </div>
      <div className="Resp-paymentcollection-full">
        <div className="Resp-paymentcollection-head">
         
          <div className="Resp-paymentcollection-heading">
            <h2>Payment collection-Details</h2>
          </div>
          <div className="image">
              <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                className="images"
              ></img>
              </div>
              <div>
              <p style={{margin:'0px'}}>{user.UserName}</p>
              <p style={{margin:'0px'}}>({user.role})</p>
              </div>
              </div>
        </div>
        <hr />
 
        <div className="Resp-searchContainer">
          <input
            type="search"
            placeholder="Search by Name"
            className="Resp-quizSearch"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
 
        {/* <div className="searchContainer">
          <input
            type="search"
            placeholder="Search by Name"
            className="quizSearch"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
        .filter(
                    (data) =>
                      data.payment_username
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      //data.id_group === parseInt(search)
                      data.payment_mobilenum.toString() === search.trim() ||
                      data.payment_idgroup === parseInt(search)
                  )
       
        */}
 
        <div className="">
          <div className="Resp-table-auctiondata">
            <table className="Resp-table-style-Gu">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center" }}>No.</th>
                  {/* <th className="tahe" style={{ textAlign: "center" }}>payment_userId</th> */}
 
                  {/* <th className="tahe" style={{ textAlign: "center" }}>payment_username</th> */}
                  {/* <th className="tahe" style={{ textAlign: "center" }}>payment_idgroup</th> */}
                  <th className="tahe" style={{ textAlign: "center" }}>payment_namegroup</th>
                  <th className="tahe" style={{ textAlign: "center" }}>payment_month</th>
                  <th className="tahe" style={{ textAlign: "center" }}>payment_insNum</th>
                  <th className="tahe" style={{ textAlign: "center" }}>payment_tap</th>
                  <th className="tahe" style={{ textAlign: "center" }}>payment_hap</th>
                  <th className="tahe" style={{ textAlign: "center" }}>payment_Mode</th>
                  <th className="tahe" style={{ textAlign: "center" }}>payment_mobilenum</th>
                  <th className="tahe" style={{ textAlign: "center" }}>payment_Date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>payment_Balance</th>
                  {/* <th style={{ textAlign: "center" }}>payment_bidingId</th> */}
                  {/* <th style={{ textAlign: "center" }}>Action</th> */}
                </tr>
              </thead>
              <tbody>
               
                {data.filter(
                    (data) =>
                      
                        data.payment_mobilenum.includes(search)
 
                       
                        )
                 
                  .map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        {/* <td>{item.payment_userId}</td> */}
                        {/* <td>{item.payment_username}</td> */}
                        {/* <td>{item.payment_idgroup}</td> */}
                        <td>{item.payment_namegroup}</td>
                        <td>{item.payment_month}</td>
 
                        <td>{item.payment_insNum}</td>
 
                        <td>{item.payment_tap}</td>
                        <td>{item.payment_hap}</td>
                        <td>{item.payment_Mode}</td>
                        <td>{item.payment_mobilenum}</td>
 
                        <td>
                          {moment(item.payment_Date).format("DD-MM-YYYY")}
                        </td>
                        <td>{item.payment_Balance}</td>
                        {/* <td>{item.payment_bidingId}</td> */}
 
                        {/* <td>
                          <button
                            onClick={() => dltbid(item.paymentcollect_Id)}
                            className="ch"
                          >
                            Delete
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default RespPaymentcollection;