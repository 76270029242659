import React,{ useEffect, useState } from 'react'
import './grablocation.css';
 
function Grablocation() {
  const [add, setAdd] = useState('')
  const [bound, setBound] = useState('')
  // https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}
 
  useEffect (() => {
    navigator.geolocation.getCurrentPosition(pos => {
      const {latitude, longitude} = pos.coords;
      console.log(latitude,longitude)
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url).then(res => res.json()).then(data => setAdd(data.address))
    })
  }, [])
 
  // console.log(add,"sfsfh")
 
  return (
    <div>
      <p className='cpayin-9'>{add.road},{add.neighbourhood},{add.state},{add.city},{add.state_district},{add.postcode},{add.country}. </p>
    </div>
  )
}
 
export default Grablocation