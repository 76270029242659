import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { motion } from "framer-motion";
import './userpro.css';
import Userside from "../usercompo/Userside";
import { CustomerContext } from "../context/customercontext";
import { Link } from 'react-router-dom';
import {useNavigate,useLocation} from "react-router-dom"
import Cookies from 'js-cookie'
import userimage from "./images/userimage.png"
 
 
const Userpro = () => {
  const usernamedashboard = localStorage.getItem("username");
  const { customername } = useContext(CustomerContext);
  const [user, setuser] = useState({});


  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
      const token = Cookies.get('token');
      const { state } = location;

      if (token) {
         
          if (location.pathname !== '/uprofile') {
              navigate('/uprofile');
          }
          return;
      }

      if ( !state || !state.username || !state.role) {
        if (location.pathname !== '/user') {
          navigate('/');
        }
        return;
      }
     
          setUsername(state.username);
          setRole(state.role);
          console.log(state.username, state.role);
         
    }, [location, navigate]);

 
  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  // const image = document.querySelector("img"),
  // input = document.querySelector("input");
  // input.addEventListener("change", () => {
  //   image.src = URL.createObjectURL(input.files[0]);
  // });

  
 
 
 
  return (
    <div className='user-profile' style={{ display: 'flex' }}>
      <div>
        <Userside />
      </div>
      <div className='user-profile-content'>
      <div className="user-profile-info">
        <div className='infoshead'>
          <div className='user-profile-heading'>
            <h2>Profile</h2>
          </div>
        </div>
        <hr />
        <div>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
            className="courses1"
          >
            <div className='AD-TEXT'>
              <p>
                Complete Your Profile Here <Link to={`/user/registerd/update/${user.UserId}`} className='Ad-edit'>Edit</Link>
              </p>
            </div>
            <div className="user-profile-avatar-container">
              <div className='user-profile-avatar-container'>
                <img
                  src={user.uploadPhoto ? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                  alt="User Avatar"
                  className="user-profile-avatar"
                />
              </div>

              <div className="user-profile-main">
                <div className="user-profile-column">
                  <div className="user-profile-details">
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>User Id</strong>
                      </label>
                      <p className="user-profile-input">{user.UserId}</p>
                    </div>
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>User Name</strong>
                      </label>
                      <p className="user-profile-input">{user.UserName}</p>
                    </div>
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>User Password</strong>
                      </label>
                      <p className="user-profile-input">{user.userpassword}</p>
                    </div>
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>Date Of Birth</strong>
                      </label>
                      <p className="user-profile-input">{user.UserDob}</p>
                    </div>
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>Mobile No</strong>
                      </label>
                      <p className="user-profile-input">{user.mobileNo}</p>
                    </div>
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>Email</strong>
                      </label>
                      <p className="user-profile-input">{user.email}</p>
                    </div>
                  </div>
                </div>
                <div className="user-profile-column">
                  <div className="user-profile-details">
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>Gender</strong>
                      </label>
                      <p className="user-profile-input">{user.gender}</p>
                    </div>
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>Occupation</strong>
                      </label>
                      <p className="user-profile-input">{user.occupation}</p>
                    </div>
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>Role</strong>
                      </label>
                      <p className="user-profile-input">{user.role}</p>
                    </div>
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>Adhaar No</strong>
                      </label>
                      <p className="user-profile-input">{user.adhaarNo}</p>
                    </div>
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>Pan No</strong>
                      </label>
                      <p className="user-profile-input">{user.panNo}</p>
                    </div>
                    <div className="user-profile-row">
                      <label className="user-profile-label">
                        <strong>Address</strong>
                      </label>
                      <p className="user-profile-input">{user.Address}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      </div>
    </div>
  );
}
 
export default Userpro;