import React from "react";
import { motion } from "framer-motion";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useState } from "react";
import { useEffect, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
// import "./userpayment.css";
import Grablocation from "../collectAgent/Grablocation";
import { FaLocationDot } from "react-icons/fa6";
//import {toast} from "react-toastify";
import { AgentContext } from "../context/agentcontext";
import AgentSide from "../agentcompo/AgentSide";
// import SideBar from "../components/Sidebar/SideBar";
import Select from "react-select";
import userimage from "./images/userimage.png";
// import { UserContext } from "../context/usecontext";
import { CollectionagentContext } from "../context/collectionagentcontext";

const initialState = {
  payment_userId: "",
  payment_username: "",
  payment_mobilenum: "",
  payment_month: "",
  payment_Mode: "",
  amountToPay: "",
  payment_Balance: "",
  payment_collectedby: "",
  paid_location: "",
};

const Agentpayin = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [totalAmount1, setTotalAmount1] = useState(null);

  const [error, setError] = useState(null);
  const [data, setData] = useState(initialState);
  const [customData, setCustomData] = useState(initialState);
  const { bidingId } = useParams();
  const [mobileNo, setmobileNo] = useState([]);
  const [userIdOptions, setUserIdOptions] = useState([]);
  const [userdata, setUserData] = useState([initialState]);
  const [address, setAddress] = useState("");
  const { collectionagentname } = useContext(CollectionagentContext);

  const [balanceAmtToPay, setBalanceAmtToPay] = useState("");
  const [balanceAmount, setBalanceAmount] = useState("");

  const { agentname } = useContext(AgentContext);
  const [user, setuser] = useState({});
  const [currentMonth, setCurrentMonth] = useState("");

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const handleSuccessClick = () => {
    Swal.fire({
      title: "Payment made Successfully!",
      text: `Your action has been completed.
      Total Payment = ${data.payoutsub};
      Amount you paid = ${customData.amountToPay}\n
      Balance = ${data.payoutsub - customData.amountToPay}`,
      icon: "Success",
    }).then(() => {
      // setData(initialState);
   
       window.location.reload();
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/bid/getbidid/${bidingId}`
        );
        setData({ ...response.data[0] });
        // Fetch bid winner and installment number here
        // Example API endpoint, replace it with your actual endpoint
        setData({
          ...response.data[0],
        });
      } catch (error) {
        console.error("Error fetching biding data", error);
      }
    };
    fetchData();
  }, [bidingId]);

  useEffect(() => {
    const currentDate = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    setCurrentMonth(monthNames[currentDate.getMonth()]);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !customData.payment_month ||
      !customData.payment_Mode ||
      !customData.amountToPay ||
      !customData.payment_mobilenum
    ) {
      Swal.fire({
        title: "Missing Information",
        text: "Please fill in all the required fields(month,paymode,amount,mobilenum).",
        icon: "warning",
      });
      return;
    }

    if (customData.payment_month !== currentMonth) {
      Swal.fire({
        title: "Invalid Month",
        text: `Please provide a valid month(current month)).`,
        icon: "error",
      });
      return;
    }

    if (bidingId) {
      axios
        .post("https://api.chitgenius.com/api/paymentcollection/post", {
          payment_userId: customData.payment_userId,
          payment_username: userIdOptions.payment_username,
          payment_idgroup: data.id_group,
          payment_namegroup: data.name_grp,
          payment_month: customData.payment_month,
          payment_insNum: data.install_numb,
          // payment_tap: data.payoutsub,
          payment_tap: totalAmount1,
          payment_mobilenum: customData.payment_mobilenum,
          payment_hap: customData.amountToPay,
          payment_Mode: customData.payment_Mode,
          payment_Balance: customData.payment_Balance,
          payment_status: null,
          payment_collectedby: user.UserId,
          paid_location: addressParagraph,
        })
        .then(() => {
          handleSuccessClick();
          setData(initialState);
        })
        .catch((err) => console.error(err.response.data));
      // alert.success("Saved Sussessfully")
    }
    // }
  };

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${collectionagentname}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [collectionagentname]);

  const loadData = async () => {
    if (!data.name_grp) {
      return;
    }
    try {
      const response = await axios.get(
        `https://api.chitgenius.com/api/getall/payin/usernames/${data.name_grp}`
      );
      console.log(response.data);
      // debugger;
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };
  useEffect(() => {
    loadData();
  }, [data.name_grp]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      console.log(latitude, longitude);
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => setAddress(data.address))
        .catch((error) => console.error("Error fetching location:", error));
    });
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `https://api.chitgenius.com/api/getall/payin/${mobileNo}`
      );
      // setGroupName(response.data.group_Name);

      const options = response.data.map((group) => ({
        label: group,
        value: group,
      }));
      // Update dropdown options with group names
      setUserIdOptions(options);
    } catch (error) {
      console.error("Error fetching user names:", error);
      setUserIdOptions([]); // Reset dropdown options if an error occurs
    }
    // } catch (error) {
    //   console.error("Error fetching user data:", error);
    //   setGroupName(""); // Reset groupName if error occurs
    // }
  };

  console.log("dataparticular", userIdOptions);
  console.log("dummy");

  useEffect(() => {
    if (customData.payment_mobilenum) {
      fetchTotalnewAmt(customData.payment_mobilenum);
    }
  }, [customData.payment_mobilenum]);

  const fetchTotalnewAmt = async (mobileNumber) => {
    try {
      const response = await axios.post(
        `https://api.chitgenius.com/api/user/memberpassbook2/mix`,
        {
          groupName: data.name_grp,
          mobileNo: mobileNumber,
          installno: data.install_numb,
          // payoutsub: totalAmount1,
        }
      );
      setTotalAmount1(response.data);
      // fetchTotalBalanceAmt(response.data);
      setError(null);
    } catch (error) {
      console.error(error);
      // setTotalAmount1("");
    }
  };

  useEffect(() => {
    if (customData.payment_mobilenum && totalAmount1 !== null) {
      fetchUserData();
      fetchBalanceAmtToPay(customData.payment_mobilenum);
    }
  }, [totalAmount1, customData.payment_mobilenum]);

  const fetchBalanceAmtToPay = async (mobileNumber) => {
    try {
      const response = await axios.post(
        `https://api.chitgenius.com/api/paymentcollection/balancepayment`,
        {
          namegroup: data.name_grp,
          mobileNumber: mobileNumber,
          insNum: data.install_numb,
        }
      );
      setBalanceAmtToPay(totalAmount1 - response.data);
      setBalanceAmount(totalAmount1 - response.data - customData.amountToPay);
      // setError(null);
    } catch (error) {
      console.error(error);
      // setTotalBalanceAmt("");
      setBalanceAmount("");
    }
  };

  const addressParagraph = `${address.road}, ${address.neighbourhood}, ${address.state}, ${address.city}, ${address.state_district}, ${address.postcode}, ${address.country}`;

  return (
    <div className="Admin-Payin-main" style={{ display: "flex" }}>
      <div>
        <AgentSide />
      </div>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4 }}
        className="Ad-payin-full "
      >
        <div className="Ad-payin-head">
          <div className="Ad-h">
            <div>
              <NavLink
                style={{ color: "black" }}
                to={"/agentpaymentcollection"}
              >
                <FaArrowLeftLong className="Ad-ume" />
              </NavLink>
            </div>
            <div className="Ad-payin-heading">
              <h2>Pay In</h2>
            </div>
          </div>
          <div className="image">
            <div>
              <img
                src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                // style={{ height: "50px", borderRadius: "30px", width: "50px" }}
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>
        <hr style={{ margin: "10px 0px" }}></hr>
        <div className="Ad-payin-9">
          <p className="Ad-cpayin-10">
            <FaLocationDot />
          </p>
          <input type="text" className="Ad-cpayin-9" value={addressParagraph} />
        </div>
        <form
          className="Ad-info-container"
          style={{ width: "75%", marginTop: "25px" }}
          onSubmit={handleSubmit}
        >
          <div>
            <div className="Ad-payin-hk">
              <div className="Ad-payin-display-44">
                <label>
                  <strong>User Id :</strong>
                </label>
                <input
                  type="text"
                  value={customData.payment_userId}
                  className="Ad-payin-input-44"
                />
              </div>

              <div className="Ad-payin-display-44">
                <label>
                  <strong>User Name :</strong>
                </label>
                <input
                  type="text"
                  value={userIdOptions.payment_username}
                  className="Ad-payin-input-44"
                />
              </div>

              <div className="Ad-payin-display-44">
                <label>
                  <strong>Group Id :</strong>
                </label>
                <input
                  type="text"
                  name="payment_idgroup"
                  className="Ad-payin-input-44"
                  value={data.id_group}
                  disabled
                />
              </div>
            </div>

            <div className="Ad-payin-hk">
              <div className="Ad-payin-display-44">
                <label>
                  <strong>Group Name :</strong>
                </label>
                <input
                  type="text"
                  name="payment_namegroup"
                  className="Ad-payin-input-44"
                  value={data.name_grp}
                  disabled
                />
              </div>

              <div className="Ad-payin-display-44">
                <label>
                  <strong>Month :</strong>
                </label>
                <select
                  name="dropdown"
                  value={customData.payment_month}
                  className="Ad-payin-input-44"
                  onChange={(event) => {
                    setCustomData({
                      ...customData,
                      payment_month: event.target.value,
                    });
                  }}
                >
                  <option value="" disabled select>
                    Select Month
                  </option>
                  <option value="Jan">January</option>
                  <option value="Feb">February</option>
                  <option value="Mar">March</option>
                  <option value="Apr">April</option>
                  <option value="May">May</option>
                  <option value="Jun">June</option>
                  <option value="Jul">July</option>
                  <option value="Aug">August</option>
                  <option value="Sep">September</option>
                  <option value="Oct">October</option>
                  <option value="Nov">November</option>
                  <option value="Dec">December</option>
                </select>
              </div>

              <div className="Ad-payin-display-44">
                <label>
                  <strong>Installment Number :</strong>
                </label>
                <input
                  type="text"
                  name="payment_insNum"
                  className="Ad-payin-input-44"
                  value={data.install_numb}
                  placeholder="install_numb"
                  disabled
                />
              </div>
            </div>

            <div className="Ad-payin-hk">
              <div className="Ad-payin-display-44">
                <label>
                  <strong>Mobile Number :</strong>
                </label>

                <Select
                  name="dropdown"
                  className="Ad-payin-input-44"
                  // value={customData.payment_mobilenum}
                  value={{
                    label: customData.payment_mobilenum,
                    value: customData.payment_mobilenum,
                  }}
                  onChange={(selectedOption) => {
                    setCustomData({
                      ...customData,
                      payment_mobilenum: selectedOption.value,
                      payment_userId: userdata
                        .filter(
                          (user) => user.mobileNo === selectedOption.value
                        )
                        .map((filteruser) => filteruser.userId),
                    });
                    setUserIdOptions({
                      ...userIdOptions,
                      payment_username: userdata
                        .filter(
                          (user) => user.mobileNo === selectedOption.value
                        )
                        .map((filteruser) => filteruser.userName),
                    });
                    fetchBalanceAmtToPay(selectedOption.value);
                  }}
                  options={userdata.map((opts) => ({
                    label: opts.mobileNo,
                    value: opts.mobileNo,
                  }))}
                  isSearchable={true}
                  placeholder="Select"
                />
              </div>

              <div className="Ad-payin-display-44">
                <label>
                  <strong>Pay Through :</strong>
                </label>
                <select
                  name="dropdown"
                  value={customData.payment_Mode}
                  className="Ad-payin-input-44"
                  onChange={(event) => {
                    setCustomData({
                      ...customData,
                      payment_Mode: event.target.value,
                    });
                  }}
                >
                  <option value="" disabled select>
                    Pay Through
                  </option>
                  <option value="phone pay">Phone pay</option>
                  <option value="google pay">Google pay</option>
                  <option value="cash">Cash</option>
                </select>
              </div>

              <div className="Ad-payin-display-44">
                <label>
                  <strong>Total Amount :</strong>
                </label>
                <input
                  type="text"
                  name="hap"
                  className="Ad-payin-input-44"
                  // value={data.payoutsub}
                  value={totalAmount1}
                  disabled
                />
              </div>
            </div>

            <div className="Ad-payin-hk">
              <div className="Ad-payin-display-44">
                <label>
                  <strong>Enter Amount To Pay :</strong>
                </label>
                <input
                  type="text"
                  name="payamount"
                  className="Ad-payin-input-44"
                  value={customData.amountToPay}
                  onChange={(event) => {
                    var amountToPay = Number(event.target.value);
                    var bal = balanceAmtToPay - amountToPay;
                    if (bal >= 0) {
                      setBalanceAmount(bal);
                      setCustomData({
                        ...customData,
                        amountToPay: amountToPay,
                        payment_Balance: bal,
                      });
                    } else {
                      alert(
                        "Amount should not be greater than 'Balance to pay'!! please enter valid amount."
                      );
                    }
                  }}
                  placeholder="Enter Amount:"
                />
              </div>

              <div className="Ad-payin-display-44">
                <label>
                  <strong>Balance to pay:</strong>
                </label>

                <input
                  type="text"
                  name="balanceamounttopay"
                  className="Ad-payin-input-44"
                  value={balanceAmtToPay}
                  readOnly
                />
              </div>

              <div className="Ad-payin-display-44">
                <label>
                  <strong>Balance :</strong>
                </label>
                <input
                  type="text"
                  name="balance"
                  className="Ad-payin-input-44"
                  value={balanceAmount}
                />
              </div>
            </div>

            <div className="Ad-payin-display-44">
              <label>
                <strong>Collected By :</strong>
              </label>
              <input
                type="text"
                name="payment_collectedby"
                className="Ad-payin-input-44"
                value={user.UserId}
                disabled
              />
            </div>
            <button
              type="Submit"
              onSubmit={handleSubmit}
              className="Ad-payin-button-99"
              value={bidingId}
            >
              Make Payment
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default Agentpayin;
