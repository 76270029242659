import React, { useEffect,useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useState } from 'react';
import AgnDash from '../agentpages/AgnDash';
import { AgentContext } from '../context/agentcontext';

export const Agent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
//   const [username, setUsername] = useState("");
const {agentname, setAgentname}=useContext(AgentContext);


  useEffect(() => {
    const token = Cookies.get('token');
    const { state } = location;

    if (!token || !state || !state.username || !state.role) {
      navigate('/');
    } else {
      setAgentname(state.username);
    //   setRole(state.role);
      console.log(state.username, state.role); // Log username and role
    }
  }, [location, navigate]);

  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
  };

  return (
    <div>
      {/* <h2>Welcome to the Parent Page!</h2> */}
      {/* <p>Username: {agentname}</p> */}
      {/* <p>Role: {role}</p> */}
      {/* <button onClick={handleLogout}>Logout</button> */}
      <AgnDash/>
    </div>
  );
};
