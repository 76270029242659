import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import SideBar from "../components/Sidebar/SideBar";
import { motion } from "framer-motion";
import { NavLink, Link, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import moment from "moment";
import { UserContext } from "../context/usecontext";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../pages/allpayoutdetailsadmin.css';
import userimage from "./images/userimage.png"

const Allpayoutdetailsadmin = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  const { paymentId } = useParams();
  const [search, setSearch] = useState("");
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/allpayout/details/admin") {
        navigate("/allpayout/details/admin");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
      return;
    }

    setUsersname(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const commonpaymentoutusergetid = user.UserId;
  console.log(commonpaymentoutusergetid);

  const loadUserData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/payout/admin/data/get/${commonpaymentoutusergetid}`
    );
    setData(response.data);
  };

  useEffect(() => {
    loadUserData();
  }, [commonpaymentoutusergetid]);

  

  const dltpaymentId = async (paymentId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const res = await axios.delete(
          `https://api.chitgenius.com/api/delete/payoutdataid/${paymentId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.status === 201) {
          toast.success("Successfully deleted!");
          loadUserData(); // Refresh data
        } else {
          console.log("Error deleting data");
        }
      } catch (error) {
        console.error("There was an error deleting the payout data", error);
        toast.error("There was an error deleting the data.");
      }
    }
  };
  
  return (
    <div className="Ad-payoutdetailsadmin-main" style={{ display: "flex", overflow: "hidden" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-payoutdetailsadmin-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4 }}
          className=""
        >
          <div>
            <div>
              <div className="Ad-payoutdetailsadmin-head">
                <div className="Ad-h">
                  <div>
                    <NavLink
                      style={{
                        color: "black",
                      }}
                      to={"/moreinfo"}
                    >
                      <FaArrowLeftLong className="Ad-ume" />
                    </NavLink>
                  </div>
                  <div className="Ad-payoutdetailsadmin-heading">
                    <h2>Paymentout Details</h2>
                  </div>
                </div>

                <div className="image">
                  <div>
                    <img
                    src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                      // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                      alt=""
                      style={{
                        height: "50px",
                        borderRadius: "30px",
                        width: "50px",
                      }}
                      className="images"
                    ></img>
                  </div>
                  <div>
                    <p style={{ margin: "0px" }}>{user.UserName}</p>
                    <p style={{ margin: "0px" }}>({user.role})</p>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ margin: "10px 0px" }}></hr>
            <div className="Ad-GU-searchContainer">
              <input
                type="search"
                placeholder="Ad-GU-Search by Name"
                className="Ad-GU-quizSearch"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>
            <div className="">
              <div className="Ad-table-auctiondata">
                <table className="Ad-tables-style-Gu">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center", margin: "0px" }}>
                        No.
                      </th>
                      {/* <th style={{ textAlign: "center" }}>groupId</th> */}
                      <th className="tahe" style={{ textAlign: "center" }}>GroupId</th>
                      <th className="tahe" style={{ textAlign: "center" }}>GroupName</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Month</th>
                      <th className="tahe" style={{ textAlign: "center" }}>InstallmentNumber</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Paytowinner</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Toal Amount</th>
                      <th className="tahe" style={{ textAlign: "center" }}>paid Amount</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Payment mode</th>
                      <th className="tahe" style={{ textAlign: "center" }}>Balance</th>
                      {/* <th style={{ textAlign: "center" }}>commission</th> */}
                      <th className="tahe" style={{ textAlign: "center" }}>Payment Date</th>

                      <th className="tahe" style={{ textAlign: "center" }}>ACTION</th>
                    </tr>
                  </thead>

                  {data.length > 0 ? (
                    <tbody>
                      {data
                        .filter(
                          (data) =>
                            data.bidwinner
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            data.idgroup.toString() === search.trim()
                        )
                        .map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.idgroup}</td>
                            <td>{item.namegroup}</td>
                            <td>{item.month}</td>
                            <td>{item.insNum}</td>
                            <td>{item.bidwinner}</td>
                            <td>{item.tap}</td>
                            <td>{item.hap}</td>
                            <td>{item.paymentMode}</td>
                            <td>{item.Balance}</td>
                            {/* <td>{item.commission_k}</td> */}
                            <td>
                              {moment(item.paymentDate).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              <div className="gtf">
                                <div></div>
                                <div>
                                  <button
                                    onClick={() => dltpaymentId(item.paymentId)}
                                    className="ch"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="13" style={{ textAlign: "center" }}>
                          pay out data ia not available
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Allpayoutdetailsadmin;
