import React, { useState } from "react";
import Navbar from "./Navbar";
import passportimage from "../images/passportimage.jpg";
import freeeducation from "../images/freeeducation.jpg";
import homeimage from "../images/homeimage.jpg";
import chitmarrige1 from "../images/chitmarrige1.jpeg";
import chitlogoo from "../images/PLM.jpg";
import "./Main.css"; // Import CSS file
import Aos from "aos";
import "aos/dist/aos.css";
import Abt from "./Abt";
import Services from "./Services";
import Whyus from "./Whyus";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Contactus from "./Contactus";

const Main = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [passportimage, homeimage, chitmarrige1, freeeducation];

  // Array of texts corresponding to each image
  const texts = [
    "Embark on a Savings Adventure with Our Chit Genius",
    // "Making plans with Chit Genius makes life easier",
    "A happy family outside a beautiful home",
    // "Safety,Security,Saving",
    "Plan your big day without financial worry!",
    "Join now and take the first step towards a brighter future.",
  ];

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (currentImageIndex - 1 + images.length) % images.length
    );
  };

  const carouselStyle = {
    backgroundImage: `linear-gradient(rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0.5)), url(${images[currentImageIndex]})`,
  };

  // Define CSS classes for each text style
  const textClasses = [
    "lg-custom-text-wiseinvest",
    "lg-custom-text-house",
    "lg-custom-text-chitmarrige1",
    "lg-custom-text-books",
  ];

  // Define CSS classes for each "Join Now" button
  const buttonClasses = [
    "join-now-btn-1",
    "join-now-btn-2",
    "join-now-btn-3",
    "join-now-btn-4",
  ];

  return (
    <div className="lg-main-container">
      <Navbar />
      <div className="lg-carousel" style={carouselStyle}>
        <button className="prev" onClick={prevImage}>
          &#10094;
        </button>
        <button className="next" onClick={nextImage}>
          &#10095;
        </button>
        <div className="lg-text-10">
          <h6 className={`lg-custom-text ${textClasses[currentImageIndex]}`}>
            {texts[currentImageIndex]}
          </h6>{" "}
          <Link to="/land">
            <button className={`lg-know ${buttonClasses[currentImageIndex]}`}>
              Join Now
            </button>
          </Link>
        </div>
      </div>

      <div className="lg-flex">
        <div className="lg-row">
          <div className="lg-col-md-12">
            <div className="lg-custom-content">
              <h4 className="no-1">About Chit Genius Company Mission</h4>
              <div className="lg-custom-content-2">
                <h6 className="lg-custom-textt">
                  Our mission is to offer flexible and secure financial
                  solutions that meet the diverse needs of our customers.
                  Whether you are planning for a major purchase, funding your
                  business, or saving for the future, Chit Genius provides a
                  trustworthy platform to enhance your financial stability. we
                  believe in the power of community and mutual benefit. Our chit
                  funds not only help individuals grow their savings but also
                  foster a spirit of mutual help and financial cooperation among
                  community members.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src={chitlogoo} className="lg-chit-logoo" />
        </div>
      </div>

      <div id="About" className="lg-about">
        <Abt />
      </div>
      <div id="whyus" className="lg-why-section">
        <Whyus />
      </div>
      <div id="Services" className="lg-ourservices">
        <Services />
      </div>
      <div id="Contact" className="lg-contact-us">
        <Contactus/>
      </div>
      <div className="lg-last-border">
        <Footer />
      </div>
    </div>
  );
};

export default Main;
