import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import SideBar from "../components/Sidebar/SideBar";
import moment from "moment";
// import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { UserContext } from "../context/usecontext";
import { useNavigate, useLocation } from "react-router-dom";
import '../pages/detailbiding.css';
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import userimage from "./images/userimage.png"

const Detailbiding = () => {
  const usernamedashboard = localStorage.getItem("username");
  const [data, setData] = useState([]);
  // const history = useNavigate();
  const [search, setSearch] = useState("");
  const { username } = useContext(UserContext);
  const [user, setuser] = useState({});
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message



  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [usersname, setUsersname] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (token) {
      if (location.pathname !== "/detailbiding") {
        navigate("/detailbiding");
      }
      return;
    }

    if (!state || !state.username || !state.role) {
      if (location.pathname !== "/admin") {
        navigate("/");
      }
      return;
    }

    setUsersname(state.username);
    setRole(state.role);
    console.log(state.username, state.role);
  }, [location, navigate]);

  
  const dltbid = async (bidingId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        const res = await axios.delete(
          `https://api.chitgenius.com/api/delete/bid/${bidingId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.status === 201) {
          toast.success("Successfully deleted!");
          loadData();
          // navigate("/moreinfo");
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    }
  };

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await axios.get(
          `https://api.chitgenius.com/api/getmemberdetails/afterlogin/${usernamedashboard}`
        );
        setuser({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchnameData();
  }, [usernamedashboard]);

  const commonbidinguserid = user.UserId;

  const loadData = async () => {
    const response = await axios.get(
      `https://api.chitgenius.com/api/data/biding/${commonbidinguserid}`
    );
    setData(response.data);
  };
  useEffect(() => {
    loadData();
  }, [commonbidinguserid]);

  return (
    <div className="Ad-detailbiding-main" style={{ display: "flex" }}>
      <div>
        <SideBar />
      </div>
      <div className="Ad-detailbidind-full">
        <div className="Ad-detailbiding-head">
          <div className="Ad-detailbiding-h">
            <div className="">
              <NavLink className="" to={"/moreinfo"}>
                <FaArrowLeftLong className="Ad-detailbiding-ume" />
              </NavLink>
            </div>
            <div className="">
              <div className="Ad-detailbiding-heading">
                <h2>Biding Details</h2>
              </div>
            </div>
          </div>

          <div className="image">
            <div>
              <img
              src={user.uploadPhoto? `https://api.chitgenius.com/uploads/${user.uploadPhoto}` : userimage}
                // src={`https://api.chitgenius.com/uploads/${user.uploadPhoto}`}
                alt=""
                style={{
                  height: "50px",
                  borderRadius: "30px",
                  width: "50px",
                }}
                className="images"
              ></img>
            </div>
            <div>
              <p style={{ margin: "0px" }}>{user.UserName}</p>
              <p style={{ margin: "0px" }}>({user.role})</p>
            </div>
          </div>
        </div>

        <hr />

        <div className="Ad-GU-searchContainer">
          <input
            type="search"
            placeholder="       Search by bidinwimmer,auctionid, groupid"
            className="Ad-GU-quizSearch"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>

        <div className="">
          <div className="Ad-table-auctiondata">
            <table className="Ad-table-style-Gu">
              <thead>
                <tr>
                  <th className="tahe" style={{ textAlign: "center" }}>No.</th>
                  <th className="tahe" style={{ textAlign: "center" }}>groupId</th>
                  <th className="tahe" style={{ textAlign: "center" }}>chit Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>No.of users</th>
                  <th className="tahe" style={{ textAlign: "center" }}>installment Number</th>
                  <th className="tahe" style={{ textAlign: "center" }}>commission</th>
                  <th className="tahe" style={{ textAlign: "center" }}>bid Amount</th>
                  <th className="tahe" style={{ textAlign: "center" }}>bid Winner</th>
                  <th className="tahe" style={{ textAlign: "center" }}>
                    collecting from subcriber
                  </th>
                  <th className="tahe" style={{ textAlign: "center" }}>pay to Winner</th>
                  <th className="tahe" style={{ textAlign: "center" }}>AuctionId</th>
                  <th className="tahe" style={{ textAlign: "center" }}>date</th>
                  <th className="tahe" style={{ textAlign: "center" }}>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .filter(
                    (data) =>
                      data.bid_winner
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      //data.id_group === parseInt(search)
                      data.id_group.toString() === search.trim() ||
                      data.id_auction === parseInt(search)
                  )
                  .map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.id_group}</td>
                        <td>{item.amount_chit}</td>
                        <td>{item.user_num}</td>
                        <td>{item.install_numb}</td>
                        <td>{item.comision}</td>

                        <td>{item.amount_bid}</td>

                        <td>{item.bid_winner}</td>

                        <td>{item.payoutsub}</td>
                        <td>{item.payinsub}</td>
                        <td>{item.id_auction}</td>

                        <td>{moment(item.date).format("DD-MM-YYYY")}</td>
                        <td>
                          <button
                            onClick={() => dltbid(item.bidingId)}
                            className="Ad-detailbiding-ch" style={{ marginLeft:"10px" }}
                          >
                            Delete
                          </button>
                          <Link to={`/Payout/${item.bidingId}`}>
                            <button className="Ad-button-payout" style={{ backgroundColor:"#212188", marginLeft:"10px"}}>Payout</button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {errorMessage && (
          <div className="popup">;
            <div className="popup-inner">
              <h3 className="popupheading">Warning!</h3>
              <p className="popupmessage">{errorMessage}</p>
              <button className="popup-close" onClick={() => setErrorMessage("")}>Close</button>
           </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Detailbiding;
